import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import "./style.scss";
import methodModel from "../../../methods/methods";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { Tooltip } from "antd";
import ReadMore from "../../../components/common/ReadMore";
const UserDetails = (p) => {
  const history = useHistory();
  const { id, userId } = useParams();
  const [Permission, SetPermission] = useState({});
  const [notes, setNote] = useState("");
  const [data, setData] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const param1 = searchParams.get("role");
  const [board, setBoard] = useState("");
 
  const getDetail = (did) => {
    loader(true);
    ApiClient.get(`user/detail`, { id: id }).then((res) => {
      if (res.success) {
        const newdata = res.data;
        setData(res?.data);
        if (res?.data?.role == "staff") {
          ApiClient.get(`permission/user-base?user_id=${id}`).then((res) => {
            SetPermission(res?.data);
          });
        }
      }
      loader(false);
    });
  };

  const back = () => {
    history.goBack();
  };

  const edit = (id) => {
    let url = `/users1/edit/${id}`;
    if (role) url = `/users1/${role}/edit/${id}`;
    history.push(url);
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  const [EducationData, setEducationData] = useState([]);
  const GetEducation = () => {
    loader(true);
    ApiClient.get(`educations?userId=${id}`).then((res) => {
      if (res.success) {
        setEducationData(res.data);
      }
    });
  };
  const [EmployeementData, setEmoloyeementData] = useState([]);
  const GetEmployementData = () => {
    ApiClient.get(`employments?userId=${id}`).then((res) => {
      if (res.success) {
        setEmoloyeementData(res.data);
      }
    });
  };

  const [images, setimages] = useState([]);
  const [AccecptID, setAcceptID] = useState("");
  const [ProtofolioError, setProtofolioError] = useState(false);
  const [selectedBoards, setSelectedBoards] = useState([]);

  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);
  const [boards, setBoards] = useState([]);
  const [enableNote, setEnableNote] = useState(false);

  const getboards = () => {
    ApiClient.get("boards", { status: "active" }).then((res) => {
      if (res.success) {
        setBoards(res?.data?.data);
      }
    });
  };

  const handleNotes = () => {
    setEnableNote(true);
  };

  useEffect(() => {
    getboards();
  }, []);

  const RejecetCarrier = (id) => {
    loader(true);
    ApiClient.put("carrier-request", { id: id, status: "rejected" }).then(
      (res) => {
        if (res.success) {
          toast.success(res.message);
          getDetail({ search: "", page: 1 });
        }
        loader(false);
      }
    );
  };
  return (
    <Layout>
      <div className="pprofile1 edit-page-common">
        <div className="row">
          <div className="col-lg-12">
            <div className=" ">
              <div className="title-head d-flex justify-content-between align-items-center ">
                <h3 className="viewUsers mb-0 user-back">
                  {" "}
                  <a onClick={back}>
                    {" "}
                    <i
                      className="fa fa-arrow-left me-2"
                      title="Back"
                      aria-hidden="true"
                    ></i>
                    {data?.isInvited?'Carrier':'Staff'} Details
                  </a>
                </h3>

                {/* <div className="d-flex edit_bg_gap">
                  <div className="edit-bg">
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        history.push(`/user/edit/${data?.id}`);
                      }}
                      class="material-icons edit"
                      title="Edit"
                    >
                      edit
                    </i>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
              <div className="col-md-8 b-3"></div>
              <div className="col-lg-12">
                <div className=" white-bg-main mb-4">
                  <div className=" white-head mb-3">
                    <h5 className="profilelist">
                      {methodModel.capitalizeFirstLetter(data?.role)}{" "}
                      information
                    </h5>
                  </div>

                  <div className="row">
                    <div className="imagesRow col-md-3">
                      <div className="imagethumbWrapper w-100">
                        <img
                          src={methodModel.userImg(data && data.image)}
                          className="uploadimage  d-block"
                        />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">Name :</label>
                          <div className="profiledetailscls">
                            {data &&
                              methodModel.capitalizeFirstLetter(data.fullName)}
                          </div>
                        </div>

                        {data?.position && (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Position :
                            </label>
                            <div className="profiledetailscls">
                              {data.position == "accounting_manager" ? (
                                "Accounting Manager"
                              ) : (
                                <>
                                  {data?.position == "owner" ? (
                                    "Owner"
                                  ) : (
                                    <>
                                      {data?.position == "manager" ? (
                                        "Manager"
                                      ) : (
                                        <>
                                          {data?.position == "disptacher"
                                            ? "Dispatcher"
                                            : ""}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        )}
                        {/* {
                            data?.role=='carrier'&&
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">Last Login</label>
                            <div className="profiledetailscls">
                              {moment(data?.last_login).endOf("minute").fromNow()}
                            </div>
                          </div>
                                } */}
                        {/* {data?.role == "carrier" && (
                                <div className="col-md-6 mb-3 view-flex">
                                  <label className="profileheddingcls">
                                    Latest Login IP :
                                  </label>
                                  <div className="profiledetailscls">
                                    {data?.ip_address}
                                  </div>
                                </div>
                              )} */}
                        {/* {data?.role == "carrier" && (
                                <div className="col-md-6 mb-3 view-flex">
                                  <label className="profileheddingcls">
                                    Identification Number :
                                  </label>
                                  <div className="profiledetailscls">
                                    {data && data?.identification_number}
                                  </div>
                                </div>
                              )} */}
                        {data?.dialCode && data?.mobileNo && (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Mobile No :
                            </label>
                            <div className="profiledetailscls">
                              {/* {data?.dialCode?.split("+")?.length < 1
                                ? null
                                : "+"} */}
                              {" "}
                              {data && data?.dialCode} {data?.mobileNo || "--"}
                            </div>
                          </div>
                        )}
                        {data?.telephoneExt && data?.telephoneNo && (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Telephone :
                            </label>
                            <div className="profiledetailscls">
                              {/* {data?.telephoneExt?.split("+")?.length < 1
                                ? null
                                : "+"} */}
                              {" "}
                              {data && data?.telephoneExt}{" "}
                              {data?.telephoneNo || "--"}
                            </div>
                          </div>
                        )}
                        {data &&
                          data.role &&
                          data.role._id == "6540cfa35a7513892be61415" ? (
                          <div className="col-md-6 mb-3 view-flex">
                            <label>Merchant Fee (%)</label>
                            <div className="profiledetailscls">
                              {data && data.merchant_fee}
                            </div>
                          </div>
                        ) : null}
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">Email :</label>
                          <div className="profiledetailscls">
                            {data && data.email}
                          </div>
                        </div>
                        {data?.company_name && (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Company Name :
                            </label>
                            <div className="profiledetailscls">
                              {methodModel.capitalizeFirstLetter(
                                data?.company_name
                              ) || "--"}
                            </div>
                          </div>
                        )}
                        {data?.fax_number && (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Fax Number :
                            </label>
                            <div className="profiledetailscls">
                              {methodModel.capitalizeFirstLetter(
                                data?.fax_number
                              ) || "--"}
                            </div>
                          </div>
                        )}
                        {data?.tax_number && (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Tax Number :
                            </label>
                            <div className="profiledetailscls">
                              {methodModel.capitalizeFirstLetter(
                                data?.tax_number
                              ) || "--"}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className=" white-bg-main mb-4">
                  <div className=" white-head mb-3">
                    <h5 className="profilelist">Address</h5>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">Address :</label>
                      <div className="profiledetailscls  read-more-new">
                        {/* {methodModel.capitalizeFirstLetter(
                                data?.address
                              ) || "--"} */}

                        <ReadMore
                          content={methodModel.capitalizeFirstLetter(
                            data?.address
                          )}
                          length={20}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">City :</label>
                      <div className="profiledetailscls">
                        {methodModel.capitalizeFirstLetter(data?.city) || "--"}
                      </div>
                    </div>
                    <div className="col-md-6  mb-3 view-flex">
                      <label className="profileheddingcls">State :</label>
                      <div className="profiledetailscls">
                        {methodModel.capitalizeFirstLetter(data?.state) || "--"}
                      </div>
                    </div>
                    <div className="col-md-6  mb-3 view-flex">
                      <label className="profileheddingcls">Country :</label>
                      <div className="profiledetailscls">
                        {methodModel.capitalizeFirstLetter(data?.country) ||
                          "--"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className=" white-bg-main mb-4">
                  <div className=" white-head mb-3">
                    <h5 className="profilelist">Truck Detail</h5>
                  </div>
                  <div className="row">
                    {data?.boardDetails?.length?<>
                      <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">
                        Selected Board :
                      </label>
                      <div className="d-flex gap-2">
                        {data?.boardDetails?.map((itm) => {
                          return (
                            <>
                              <span className="badge badge-primary">
                                {itm.name}
                              </span>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    </>:<></>}
                   
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">
                        Trailer Type :
                      </label>
                      <div className="d-flex gap-2">
                        {data?.trailer_type?.map((itm) => {
                          return (
                            <>
                              <span className="badge badge-primary">{itm}</span>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* {data?.role == "carrier" && (
                      <div className="col-lg-12">
                        <div className=" white-bg-main mb-4">
                          <div className=" white-head mb-3">
                            <h5 className="profilelist">Truck Details</h5>
                          </div>
                          <div className="row">
                            {data?.truck_number && (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Truck Number
                                </label>
                                <div className="profiledetailscls">
                                  {data?.truck_number || "--"}
                                </div>
                              </div>
                            )}

                            {data?.mc_description && (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">DOT</label>
                                <div className="profiledetailscls">
                                  {data?.mc_description || "--"}
                                </div>
                              </div>
                            )}
                            {data?.trailers_number && (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Total Trailer
                                </label>
                                <div className="profiledetailscls">
                                  {data?.trailers_number || "--"}
                                </div>
                              </div>
                            )}
                            {data?.trailer_type && (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Trailer Type
                                </label>
                                {data?.trailer_type?.map((itm) => {
                                  return (
                                    <div className="profiledetailscls  ml-2">
                                      {itm == "dry_van" ? "Dry Van" : "Reefer"}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            {data?.team_number && (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Team Number
                                </label>
                                <div className="profiledetailscls">
                                  {methodModel.capitalizeFirstLetter(
                                    data?.team_number
                                  ) || "--"}
                                </div>
                              </div>
                            )}
                            {data?.board_id && (
                              <div className="col-md-12 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Board Name
                                </label>
                                {data?.boardDetails.map((board) => {
                                  return (
                                    <>
                                      <div className="profiledetailscls ml-2">
                                        {methodModel.capitalizeFirstLetter(
                                          board?.name
                                        ) || "--"}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )} */}
            </div>
      </div>
    </Layout>
  );
};

export default UserDetails;
