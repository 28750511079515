import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import "firebase/messaging";
import firebase from "firebase/app";
export const firebaseConfig = {
  apiKey: "AIzaSyBBYQ2hDewivp5vuai2fFgGlrIdK9X-c6Y",
  authDomain: "one-brokerage-notify.firebaseapp.com",
  projectId: "one-brokerage-notify",
  storageBucket: "one-brokerage-notify.appspot.com",
  messagingSenderId: "430314110199",
  appId: "1:430314110199:web:3f957de87d42a40e1ae7f2",
  measurementId: "G-MTFK0ZNRFN",
};

export const firebaseApp = initializeApp(firebaseConfig);
