import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
// import "./style.scss";
import { ToastsStore } from "react-toasts";
import loader from "../../methods/loader";
import userTableModel from "../../models/table.model";
import Html from "./Html";
import { userType } from "../../models/type.model";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import environment from "../../environment";
import { toast } from "react-toastify";
import addressModel from "../../models/address.model";

const Bid = (p) => {
  const user = useSelector((state) => state.user);
  const [ShowDeleteModal, setShowAcceptModal] = useState("none");
  const [TAB, settab] = useState("All");

  const [ShowActiveModal, setShowActiveModal] = useState("none");
  const { role } = useParams();
  const [Awarded, setAwarded] = useState([]);
  const [tabs, setTabs] = useState("All");
  const searchState = useSelector((state) => state.search);
  const [filters, setFilter] = useState({
    page: 1,
    count: 10,
    status: "",
    search: "",
    sorder: "",
    role: role || "",
    isDeleted: false,
  });
  const [alldata, setAllData] = useState([]);
  const [tab, setTab] = useState("list");
  const [total, setTotal] = useState(0);
  const [Loading, setLoading] = useState("d-none");
  const [ShowRejectModal, setShowRejectModal] = useState("none");
  const [loaging, setLoader] = useState(true);
  const [tableCols, setTableCols] = useState([]);
  const [accepted, setAccepted] = useState([]);
  const [rejected, setRejected] = useState([]);
  const history = useHistory();

  const url = window.location.pathname;
  const CheckParamsPath = () => {
    switch (url) {
      case "/bids":
        setTabs("Bids");
        return { key: "Loads", status: "" };
        break;
      case "/bids/accepted-bid":
        setTabs("Accepted Bids");
        return { key: "Accepted Bids", status: "accepted" };
        break;
      case "/bids/pending-bid":
        setTabs("Pending Bids");
        return { key: "Pending Bids", status: "pending" };
        break;
      case "/bids/rejected-bid":
        setTabs("Rejected Bids");
        return { key: "Rejected Bids", status: "rejected" };
        break;
      case "/bids/awarded-bid":
        setTabs("Awarded Bids");
        return { key: "Awarded Bids", status: "awarded" };
        break;
      // case "/intransit-bids":
      //   setTabs("in_transit");
      //   settab("in_transit");
      //   return { key: "in Transit Bids", status: "in_transit" };
      //   break;
      // default:
      //   setTabs("Active");
      //   settab("Active");
      //   return { key: "All Bids", status: "" };
    }
  };

  const uTableCols = () => {
    let exp = [];
    if (tableCols) exp = tableCols;
    let value = [];
    userTableModel.list.map((itm) => {
      if (itm != exp.find((it) => it.key == itm.key)) {
        value.push(itm);
      }
    });
    return value;
  };

  const addCol = (itm) => {
    setTableCols([...tableCols, itm]);
  };

  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }
    setFilter({
      ...filters,
      origin_location_street: e.value,
      origin_location_country: address.country || "",
      origin_location_city: address.city || "",
      origin_location_state: address.state || "",
      origin_location_postal_code: address.zipcode || "",
      // lat: `${address.lat}` || '',
      // lng: `${address.lng}` || ''
    });
    getData({ origin_location_city: address.city });
  };
  const removeCol = (index) => {
    let exp = tableCols;
    exp.splice(index, 1);
    setTableCols([...exp]);
  };

  const getData = (p = {}) => {
    setLoader(true);
    let filter = {
      ...filters,
      ...p,
      carrier_id: user?.id,
      status: CheckParamsPath()?.status,
    };
    let url = `bids`;

    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        setAllData(res?.data?.data);
        setTotal(res?.data?.total);
      }
      setLoader(false);
    });
  };

  const ChangeFilter = (e) => {
    setFilter(e);
    getData(e);
  };

  const completeBid = () => {
    loader(true);
    let filter = { ...filters, ...p, carrier_id: user?.id, status: "accepted" };
    let url = `bids`;
    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        setAccepted(res?.data?.data);
        setTotal(res?.data?.total);
      }
      loader(false);
    });
  };
  const GetAwarded = () => {
    loader(true);
    let filter = { ...filters, ...p, carrier_id: user?.id, status: "awarded" };
    let url = `bids`;
    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        setAccepted(res?.data?.data);
        setTotal(res?.data?.total);
      }
      loader(false);
    });
  };

  const pageCompleteBidChange = (e) => {
    setFilter({ ...filters, page: e });
    completeBid({ page: e });
  };

  const rejectBid = () => {
    loader(true);
    let filter = { ...filters, ...p, carrier_id: user?.id, status: "rejected" };
    let url = `bids`;
    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        setRejected(res?.data?.data);
        setTotal(res?.data?.total);
      }
      loader(false);
    });
  };

  const pageRejectBidChange = (e) => {
    setFilter({ ...filters, page: e });
    completeBid({ page: e });
  };

  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filters, search: searchState.data, role });
      getData({ search: searchState.data, role, page: 1 });
      // rejectBid()
      // completeBid()
    }
  }, [searchState, url]);

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };

  const openModal = (itm) => {
    let extra = new Date().getTime();
    setform({ ...itm, extra, role });
    document.getElementById("openuserModal").click();
  };

  const ChangeRole = (e) => {
    setFilter({ ...filters, role: e, page: 1 });
    getData({ role: e, page: 1 });
  };
  const ChangeStatus = (e) => {
    setFilter({ ...filters, status: e, page: 1 });
    getData({ status: e, page: 1 });
  };

  const ChangeDocumentStatus = (e) => {
    setFilter({ ...filters, isVerifiedDocument: e, page: 1 });
    getData({ isVerifiedDocument: e, page: 1 });
  };
  const exportCsv = () => {
    loader(true);
    ApiClient.get("user/csv").then((res) => {
      if (res.success) {
        let url = res.path;
        let downloadAnchor = document.getElementById("downloadJS");
        downloadAnchor.href = url;
        downloadAnchor.click();
      }
      loader(false);
    });
  };

  const colClick = (col, itm) => {
    if (col.key == "healthClinicId") {
    }
  };

  const statusChange = (itm) => {
    let modal = "loadmanagement";
    let status = "active";
    if (itm.status == "active") status = "deactive";

    // if(window.confirm(`Do you want to ${status=='active'?'Activate':'Deactivate'} this user`)){
    loader(true);
    ApiClient.put(
      `change/status?model=loadmanagement&id=${itm?.id}&status=${status}`
    ).then((res) => {
      if (res.success) {
        getData();
        toast.success(
          ` Bid ${status == "active" ? "Enabled" : "Disabled"} Successfully`
        );
        setShowActiveModal("none");
      }
      loader(false);
    });
    // }
  };

  const blockunblock = (itm) => {
    if (
      window.confirm(
        `Do you want to ${!itm.isBlock ? "Block" : "Un-block"} this user`
      )
    ) {
      loader(true);
      ApiClient.put(`edit-profile`, {
        id: itm.id,
        isBlock: itm.isBlock ? false : true,
      }).then((res) => {
        if (res.success) {
          getData();
        }
        loader(false);
      });
    }
  };
  const deleteLoad = (id) => {
    if (window.confirm("Do you want to delete this ?")) {
      loader(true);
      ApiClient.delete(`delete?model=loadmanagement&id=${id}`).then((res) => {
        if (res.success) {
          toast.success(res?.message);
          getData();
        }
        loader(false);
      });
    }
  };
  const view = (id) => {
    history.push("bids/view/" + id);
  };

  const edit = (id) => {
    let url = `/loads/edit/${id}`;
    if (role) url = `/users1/${role}/edit/${id}`;
    history.push(url);
  };

  const add = () => {
    let url = `/loads/add`;
    if (role) url = `/users1/${role}/add`;
    history.push(url);
  };

  const tabChange = (tab) => {
    setTab(tab);
  };

  const exportfun = async () => {
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${environment.api}api/export/user`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Users.xlsx`;
    link.click();
  };

  const reset = () => {
    ChangeStatus("");
    setFilter({ ...filters, status: "" });
    getData({ ...filters, status: "" });
  };

  const filter = (p = {}) => {
    setFilter({ ...filters, ...p });
    getData({ ...p, page: filters?.page });
  };

  const sorting = (key, i) => {
   
    filter({ ...filters, sortBy: key, sorder: i });
    {
      tab == "All" ? getData({ sortBy: key, sorder: i }) : "";
    }
    // pageCompleteBidChange({ sortBy: key, sorder: i })
  };

  const UpdateTransitStatus = ({ stop_id, load_id }) => {
    loader(true);
    ApiClient.put("load-status", { stop_id, load_id, status: "pickedup" }).then(
      (res) => {
        if (res.success) {
          toast.success(res?.message);
          loader(false);
        }
        loader(false);
      }
    );
  };
  return (
    <>
      <Html
        user={user}
        colClick={colClick}
        exportfun={exportfun}
        tabChange={tabChange}
        tab={tab}
        reset={reset}
        add={add}
        view={view}
        edit={edit}
        role={role}
        ChangeRole={ChangeRole}
        ChangeStatus={ChangeStatus}
        openModal={openModal}
        pageChange={pageChange}
        addCol={addCol}
        exportCsv={exportCsv}
        uTableCols={uTableCols}
        removeCol={removeCol}
        filters={filters}
        UpdateTransitStatus={UpdateTransitStatus}
        ChangeDocumentStatus={ChangeDocumentStatus}
        setShowActiveModal={setShowActiveModal}
        ShowActiveModal={ShowActiveModal}
        tableCols={tableCols}
        loaging={loaging}
        alldata={alldata}
        ShowDeleteModal={ShowDeleteModal}
        setShowAcceptModal={setShowAcceptModal}
        total={total}
        statusChange={statusChange}
        blockunblock={blockunblock}
        setFilter={setFilter}
        sorting={sorting}
        completeBid={completeBid}
        addressResult={addressResult}
        GetAwarded={GetAwarded}
        Awarded={Awarded}
        getData={getData}
        ChangeFilter={ChangeFilter}
        deleteLoad={deleteLoad}
        accepted={accepted}
        rejected={rejected}
        setLoading={setLoading}
        Loading={Loading}
        rejectBid={rejectBid}
        ShowRejectModal={ShowRejectModal}
        setShowRejectModal={setShowRejectModal}
        TAB={tabs}
        settab={settab}
        pageCompleteBidChange={pageCompleteBidChange}
        pageRejectBidChange={pageRejectBidChange}
        url={url}
      />
    </>
  );
};

export default Bid;
