import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import rolesModel from "../../models/roles.model";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import environment from "../../environment";
import { useHistory } from "react-router-dom";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import ApiClient from "../../methods/api/apiClient";

const Html = ({
  view,
  addressResult,
  edit,
  user,
  ChangeFilter,
  deleteLoad,
  reset,
  sorting,
  add,
  colClick,
  tab,
  tabChange,
  ChangeRole,
  ChangeStatus,
  openModal,
  statusChange,
  pageChange,
  addCol,
  deleteItem,
  exportCsv,
  uTableCols,
  removeCol,
  filters,
  ChangeDocumentStatus,
  tableCols,
  setFilter,
  blockunblock,
  loaging,
  getData,
  data,
  exportfun,
  roles,
  role,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  total = { total },
}) => {
  const Navigate = useHistory();
  const [Min_rate, setMin_rate] = useState("");
  const [Max_rate, setMax_rate] = useState("");
  const [DeleteId, setDeleteId] = useState("");
  const latestSliderValue = React.useRef([0, 0]);

  const debouncedHandleSliderChange = debounce((newValues) => {
    const [min, max] = newValues;
    setMin_rate(min);
    setMax_rate(max);
    // console.log("Filter changed. Calling GetAllprojects...");
    getData({ min_rate: min, max_rate: max });
  }, 500);

  const handleSliderChange = (newValues) => {
    if (
      JSON.stringify(newValues) === JSON.stringify(latestSliderValue.current)
    ) {
      return;
    }
    latestSliderValue.current = newValues;
    debouncedHandleSliderChange(newValues);
  };

  const Delete = () => {
    deleteItem(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };

  const setPriceFilter = () => {
    setFilter({ ...filters, min_rate: Min_rate, max_rate: Max_rate });
    getData({ min_rate: Min_rate, max_rate: Max_rate });
  };

  useEffect(() => {
    setMin_rate(0);
    setMax_rate(4000);
  }, []);

  const Permission = JSON.parse(localStorage.getItem("permission"));
  const Role = [
    {
      key: "staff",
      name: "Staff",
    },
    {
      key: "carrier",
      name: "Carrier",
    },
  ];
  let ListingData = [];
  if (user?.role == "staff") {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }
  return (
    <Layout>
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={StatusCh}
        status={StatusData.status}
      />
      <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap ">
        <h3 className="hedding">Trucks</h3>
        <div className="d-flex align-items-center flex-wrap">

          <article className="d-flex filterFlex phView gap-2 flex-wrap mt-1">

            <form
              onSubmit={(e) => {
                e.preventDefault();
                getData({ filters });
              }}
            >
              <input
                type="text"
                value={filters?.search}
                className="form-control "
                placeholder="Search here"
                onChange={(e) => {
                  getData({ ...filters, search: e.target.value });
                  setFilter({ ...filters, search: e.target.value });
                }}
              />
            </form>
            <div className="d-flex gap-2 flex-wrap">
              {Permission?.carrier_add || user?.role == "admin" ? (
                <>
                  <button className="btn btn-primary" onClick={(e) => add()}>
                    Add {role ? rolesModel.name(role) : "Load"}
                  </button>
                </>
              ) : (
                <></>
              )}

              {filters.status || filters.role ? (
                <>
                  <button className="btn btn-primary " onClick={(e) => reset()}>
                    Reset
                  </button>
                </>
              ) : (
                <></>
              )}
              <button className="btn btn-primary " onClick={(e) => add()}>
                <i class="fa fa-plus me-2" aria-hidden="true"></i>
                Add
              </button>
            </div>
          </article>
        </div>
      </div>

      <>
        <div className={`${total>1?"table-responsive":""} respTable table_section`}>
          <table class="table ">
            <thead className="table_head">
              <tr className="heading_row">
                <th scope="col" className="table_data pointer">
                  Truck Number{" "}
                </th>
                <th scope="col" className="table_data pointer">
                  VIN Number{" "}
                </th>
                <th
                  scope="col"
                  className="table_data pointer"
                  onClick={(e) =>
                    filters?.sortBy == "createdAt asc"
                      ? sorting("createdAt desc", "desc")
                      : sorting("createdAt asc", "asc")
                  }
                >
                  Date Created {filters?.sortBy === "createdAt asc" ? "↑" : "↓"}
                </th>

                {/* <th scope="col" className="table_data ">
                    Status
                  </th> */}
              </tr>
            </thead>
            <tbody>
              {!loaging &&
                ListingData &&
                ListingData.map((itm, i) => {
                  return (
                    <tr className="data_row">
                      <td
                        className="table_dats"

                      >
                        {itm.truck_number}
                      </td>
                      <td className="table_dats">{itm.vin_number}</td>
                      <td className="table_dats">
                        {datepipeModel.date(itm.createdAt) || "--"}
                      </td>
                      {/* <td className="table_dats">
                          {" "}
                          <div className={` ${itm.status}`}>
                            {itm.status}

                            <span className="">
                              {itm.status == "deactive" ? (
                                <Switch
                                  onChange={(e) => {
                                    if (
                                      Permission?.carrier_edit ||
                                      user?.role == "admin"
                                    ) {
                                      setStatusData(itm);
                                      setShowActiveModal("block");
                                    } else {
                                      toast.warn(
                                        "You do not have valid permission"
                                      );
                                    }
                                  }}
                                  checked={false}
                                />
                              ) : (
                                <Switch
                                  onChange={(e) => {
                                    if (
                                      Permission?.carrier_edit ||
                                      user?.role == "admin"
                                    ) {
                                      setStatusData(itm);
                                      setShowActiveModal("block");
                                    } else {
                                      toast.warn(
                                        "You do not have valid permission"
                                      );
                                    }
                                  }}
                                  checked={true}
                                />
                              )}
                            </span>
                          </div>
                        </td> */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>

      {(!loaging && total == 0) || data?.length < 1 ? (
        <div className=" no-data">
          <img src="assets/img/no-data.png" />
          No Data
        </div>
      ) : (
        <></>
      )}

      {!loaging && total > 0 ? (
        <div className="paginationWrapper main-pagination">
          <div className="d-flex align-items-center">
            <div className="me-2 user-name-color">Show</div>
            {total > 0 && (
              <div className="dropdown addDropdown chnagesname ">
                <select
                  className="form-control"
                  value={filters?.count}
                  onChange={(e) => {
                    setFilter({ ...filters, count: e.target.value });
                    getData({ ...filters, count: e.target.value });
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="30">30</option>
                </select>
              </div>
            )}{" "}
            <div className="ms-2 user-name-color">from {total} Trucks</div>
          </div>

          <Pagination
            currentPage={filters.page}
            totalSize={total}
            itemClassPrev="back_page"
            itemClassNext="next_page"
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
