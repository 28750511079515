import React, { useEffect, useState } from "react";
// import "./style.scss";
//import Header from "../../components/global/header"
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ApiClient from "../../../methods/api/apiClient";
// import WebsiteHeader from "../../components/global/WebsiteHeader";

const ContactUs = () => {
  const websitedetails = useSelector((state) => state.website);
  const [cms, setcms] = useState();
  const history = useHistory();

  useEffect(() => {
    // getCMS()
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const getCMS = () => {
    ApiClient.get(`cms/listing`).then((res) => {
      if (res.success) {
        setcms(res.data);
      }
    });
  };

  return (
    <>
      {/* Footer Section */}
      <footer class="footer_section section-padding">
        <div class="container">
          <div class="row ">
            <div class="col-12 col-sm-12 col-md-12  col-lg-8">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-5 col-lg-5">
                  <div class="footer_space">
                    <img
                      src="/assets/img/footer_logo.png"
                      class="img-fluid"
                      alt="logo"
                    />
                    <p class="footer_details">
                      Your trusted partner in transportation, delivering results
                      that move businesses forward.
                    </p>
                    <ul class="footer_iconlist">
                      <li>
                        <a href="#">
                          <div class="footer_img ">
                            <img
                              src="/assets/img/youtube.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="/assets/img/twitter.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="/assets/img/insta.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="/assets/img/facebook.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-7">
                  <div class="row">
                    <div class="col-12 col-sm-5 col-md-5 col-lg-5">
                      <div class="footer_space">
                        <h5 class="footer_heading">Quick Links</h5>

                        <ul class="footer_items">
                          <li>
                            <a href="#"> About Us</a>
                          </li>
                          {/* <li>
                              <a href="#"> Service</a>
                            </li>
                            <li>
                              <a href="#"> How to Apply</a>
                            </li>
                            <li>
                              <a href="#"> Testimonial</a>
                            </li> */}
                        </ul>
                      </div>
                    </div>
                    <div class=" col-12 col-sm-7 col-md-7 col-lg-7">
                      <div class="footer_space">
                        <h5 class="footer_heading">Contact Us</h5>

                        <ul class="footer_items">
                          <li>
                            <div class="footer_list">
                              <img
                                src="/assets/img/message.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">hello@website.com</p>
                            </div>
                          </li>
                          <li>
                            <div class="footer_list">
                              <img
                                src="/assets/img/map.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">
                                Riverside Building, County Hall, London SE1 7PB,
                                United Kingdom
                              </p>
                            </div>
                          </li>
                          <li>
                            <div class="footer_list">
                              <img
                                src="/assets/img/call.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">+15055221111</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-4">
              <div class="footer_space">
                <h5 class="footer_heading">Newsletter</h5>
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control enter_mail"
                    placeholder="Enter your email"
                  />
                  <button class="subscribe_btn">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ContactUs;
