import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import "./style.scss";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import methodModel from "../../../methods/methods";
import rolesModel from "../../../models/roles.model";
import environment from "../../../environment";
import parse from "html-react-parser";
import moment from "moment";

import { toast } from "react-toastify";

const TruckDetails = (p) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { id, userId } = useParams();
  const [Permission, SetPermission] = useState({});
  const [data, setData] = useState();
  const getDetail = (did) => {
    loader(true);
    ApiClient.get(`load`, { id: did }).then((res) => {
      if (res.success) {
        const newdata = res.data;
        setData(res.data);
      }
      loader(false);
    });
  };

  const back = () => {
    history.goBack();
  };

  const edit = (id) => {
    let url = `/users1/edit/${id}`;
    if (role) url = `/users1/${role}/edit/${id}`;
    history.push(url);
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  const [EducationData, setEducationData] = useState([]);
  const GetEducation = () => {
    loader(true);
    ApiClient.get(`educations?userId=${id}`).then((res) => {
      if (res.success) {
        setEducationData(res.data);
      }
    });
  };
  const [EmployeementData, setEmoloyeementData] = useState([]);
  const GetEmployementData = () => {
    ApiClient.get(`employments?userId=${id}`).then((res) => {
      if (res.success) {
        setEmoloyeementData(res.data);
      }
    });
  };
  // useEffect(()=>{
  // GetEducation();
  // GetEmployementData();
  // },[])

  // const HandleAccpetReject=(e,status,role)=>{
  //     e.preventDefault();
  //     loader(true);
  //     const payload={isVerifiedDocument:status,id:id}
  //     ApiClient.put(`edit/profile`,payload).then(res=>{
  //         if(res.success){
  //           // toast.success(`${role} `)
  //             getDetail(id);
  //         }
  //     })
  //   }

  const [images, setimages] = useState([]);

  const [ProtofolioError, setProtofolioError] = useState(false);

  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);

  const imageResult = (e) => {
    if (e.target.files.length > 0) {
      setDocumentUploadLoading(true);
      const files = e.target.files;
      const newdata = new FormData();
      let newarray = [];
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        ApiClient.multiImageUpload("single/documents?modelName=document", {
          data: file,
        }).then((res) => {
          if (res.success) {
            const path = res?.data?.imagePath;
            setResume(path);
            setform({ ...form, resumeDocument: path });
            setDocumentUploadLoading(false);
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;
      }
      setProtofolioError(false);
      setdocuments(newarray);
      setimages(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };
  const getPermission = () => {
    ApiClient.get(`permission/user-base?user_id=${id}`).then((res) => {
      if (res.success) {
        SetPermission({
          carrier_add: res?.data?.carrier_add,
          carrier_complete_access: res?.data?.carrier_complete_access,
          carrier_delete: res?.data?.carrier_delete,
          carrier_edit: res?.data?.carrier_edit,
          carrier_get: res?.data?.carrier_get,
        });

        // setform({...form,permissions:res?.data})
      }
    });
  };

  //  For Deleting the Document
  // const HanldDocumentDelete = (e) => {
  //   setResume("")
  //   setform({...form,resumeDocument:""})
  // };
  //   const handleSubmit=(e,type="")=>{
  // e.preventDefault();
  // let payload=form;
  // if(type=="delete"){payload={resume:"",resumeDocument:"",id:form.id}}
  // loader(true);
  // ApiClient.put(`edit/profile`,payload).then(res=>{
  //     if(res.success){
  //         getDetail(form.id);
  //         toast.success(`Resume ${type=="delete"?"Deleted": "Updated"} Successfully`)
  //         document.getElementById("closeresume").click();
  //         loader(false);
  //     }
  // })
  //   }

  //    This is Method for Rejection
  // const handlerejectionSubmit=(e)=>{
  //     e.preventDefault();
  //     loader(true);
  //     const payload={isVerifiedDocument:"rejected",reason:RejectionReason,id:id}
  //     ApiClient.put(`edit/profile`,payload).then(res=>{
  //         if(res.success){
  //             document.getElementById("closerejection").click()
  //             toast.success(` ${data?.role&&data?.role?.name} Rejected Successfully`)
  //             getDetail(id);
  //         }
  //     })

  // }

  // function validateYouTubeUrl(url)
  // {
  //     // var url = $('#youTubeUrl').val();
  //         if (url != undefined || url != '') {
  //             var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
  //             var match = url.match(regExp);
  //             if (match && match[2].length == 11) {
  //               return 'https://www.youtube.com/embed/' + match[2]
  //             }
  //             else {
  //               return ""
  //                 // Do anything for not being valid
  //             }
  //         }
  //         else{
  //           return false
  //         }
  // }

  // const HandleCommissionSubmit=(e)=>{
  //   e.preventDefault();
  //   loader(true);
  //   const payload={id:id,commissionPercentage:Comission}
  //   ApiClient.put(`edit/profile`,payload).then(res=>{
  //     if(res.success){
  //       toast.success("Profile updated successfully")
  //     }
  //     loader(false)
  //   })
  // }
  return (
    <Layout>
      <div className="pprofile1">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-right title-head">
              <div className="d-flex justify-content-between align-items-center ">
                <h3 className="ViewUser mb-0 user-back">
                  {" "}
                  <a to="/users1" onClick={back}>
                    {" "}
                    <i
                      className="fa fa-arrow-left ml-1 "
                      title="Back"
                      aria-hidden="true"
                    ></i>
                  </a>
                  Load Detail
                </h3>
                <div className="edit-bg">
                  {/* {data?.role&&data.role.id=="64e83a928599356bddc2fa00"&&(data.isVerifiedDocument=="accepted"||data.isVerifiedDocument=="rejected")?<p className={ `text-capitalize btn mr-5 mb-0 btn-${data.isVerifiedDocument=="accepted"?"success":"danger"}`}>{data.isVerifiedDocument}</p>:null}
                {/* This is for Recruiter */}
                  {/* {data?.role&&data.role.id=="652393ee64451a10065b76eb"&&(data.isVerifiedDocument=="accepted"||data.isVerifiedDocument=="rejected")?<p className={ `text-capitalize btn mr-5 mb-0 btn-${data.isVerifiedDocument=="accepted"?"success":"danger"}`}>{data.isVerifiedDocument}</p>:null} */}
                  <i
                    style={{ cursor: "pointer" }}
                    onClick={(e) => history.push(`/loads/edit/${id}`)}
                    class="material-icons edit"
                    title="Edit"
                  >
                    edit
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="common-padding">
              <div className="row">
                {/* <div className="col-md-8 mb-3 mx-auto">

                  <div className="imagethumbWrapper w-100">
                    <img
                      src={methodModel.userImg(data && data.image)}
                      className="uploadimage mx-auto d-block"
                    />
                  </div>
                </div> */}
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">Customer Name</label>
                  <div className="profiledetailscls">
                    {data &&
                      methodModel.capitalizeFirstLetter(data.customer_name)}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">Customer ID</label>
                  <div className="profiledetailscls">{data?.customer_id}</div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">Load ID</label>
                  <div className="profiledetailscls">{data?.load_id}</div>
                </div>

                {/* <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Country</label>
                            <div className='profiledetailscls'>{data?.country}</div>
                        </div>  */}
                {/* <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">State</label>
                            <div className='profiledetailscls'>{data?.state}</div>
                        </div>  */}
                {/* <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">City</label>
                            <div className='profiledetailscls'>{data?.city}</div>
                        </div>  */}
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">Start Date</label>
                  <div className="profiledetailscls">
                    {moment(data?.load_start_date).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">End Date</label>
                  <div className="profiledetailscls">
                    {moment(data?.load_end_date).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">
                    Total Distance in km
                  </label>
                  <div className="profiledetailscls">
                    {data?.total_distance}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">
                    Total Weight in kg
                  </label>
                  <div className="profiledetailscls">{data?.total_weight}</div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">Total Volume</label>
                  <div className="profiledetailscls">{data?.total_volume}</div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">Total Pieces</label>
                  <div className="profiledetailscls">{data?.total_pieces}</div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">Total Pallets</label>
                  <div className="profiledetailscls">{data?.total_pallets}</div>
                </div>

                {/* <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Buisness Name</label>
                            <div className='profiledetailscls'>{data?.companyName}</div>
                        </div>  */}
                <div className="col-md-12 mb-3">
                  <label className="profileheddingcls">Street Address</label>
                  <div className="profiledetailscls">
                    {methodModel.capitalizeFirstLetter(
                      data?.origin_location_street
                    ) || "--"}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">City</label>
                  <div className="profiledetailscls">
                    {data && data.origin_location_city}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">State</label>
                  <div className="profiledetailscls">
                    {methodModel.capitalizeFirstLetter(
                      data?.origin_location_state
                    ) || "--"}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">Postal Code</label>
                  <div className="profiledetailscls">
                    {methodModel.capitalizeFirstLetter(
                      data?.origin_location_postal_code
                    ) || "--"}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">Country</label>
                  <div className="profiledetailscls">
                    {methodModel.capitalizeFirstLetter(
                      data?.origin_location_country
                    ) || "--"}
                  </div>
                </div>

                <div className="col-md-12 mb-3">
                  <label className="profileheddingcls">
                    Destination Street Address
                  </label>
                  <div className="profiledetailscls">
                    {methodModel.capitalizeFirstLetter(
                      data?.destination_location_street
                    ) || "--"}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">City</label>
                  <div className="profiledetailscls">
                    {data && data.destination_location_city}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">State</label>
                  <div className="profiledetailscls">
                    {methodModel.capitalizeFirstLetter(
                      data?.destination_location_state
                    ) || "--"}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">Postal Code</label>
                  <div className="profiledetailscls">
                    {methodModel.capitalizeFirstLetter(
                      data?.destination_location_postal_code
                    ) || "--"}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls">Country</label>
                  <div className="profiledetailscls">
                    {methodModel.capitalizeFirstLetter(
                      data?.destination_location_country
                    ) || "--"}
                  </div>
                </div>

                {/* {data&&data.role&&data.role.id=="652393ee64451a10065b76eb"?
                                                 <div className="col-md-6 mb-3">
                                                 <label className="profileheddingcls">Commission</label>
                                                 <div className='profiledetailscls'>{data?.commissionPercentage}%</div>
                                             </div>  :null} */}

                {data?.role == "staff" && (
                  <div className="col-md-12 mb-3">
                    <label className="profileheddingcls">Permissions</label>
                    {Permission && (
                      <table className="table custom-table border">
                        <thead>
                          <tr>
                            <th scope="col"> User Add</th>
                            <th scope="col"> User Edit</th>
                            <th scope="col"> User Read</th>
                            <th scope="col">User Delete</th>
                            {/* <th scope="col"> User Complete Access </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span class="material-icons rights-ic ">
                                {Permission?.carrier_add ? "done" : "clear"}
                              </span>
                            </td>
                            <td>
                              <span class="material-icons rights-ic ">
                                {Permission?.carrier_edit ? "done" : "clear"}
                              </span>
                            </td>
                            <td>
                              <span class="material-icons rights-ic ">
                                {Permission?.carrier_get ? "done" : "clear"}
                              </span>
                            </td>
                            <td>
                              <span class="material-icons rights-ic ">
                                {Permission?.carrier_delete ? "done" : "clear"}
                              </span>
                            </td>
                            {/* <td>
                        <span class="material-icons rights-ic ">
                            {Permission?.carrier_complete_access
                              ? "done"
                              : "clear"}
                          </span>
                        </td> */}
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {/* {Permission && (
                      <ul className="permission-view">
                        <li className="items-names">
                          <span class="material-icons rights-ic ">
                            {Permission?.carrier_add ? "done" : "clear"}
                          </span>
                          <p> User Add</p>
                        </li>
                        <li className="items-names">
                          <span class="material-icons rights-ic ">
                            {Permission?.carrier_edit ? "done" : "clear"}
                          </span>
                          <p> User Edit</p>
                        </li>
                        <li className="items-names">
                          <span class="material-icons rights-ic ">
                            {Permission?.carrier_get ? "done" : "clear"}
                          </span>
                          <p>User Read</p>
                        </li>
                        <li className="items-names">
                          <span class="material-icons rights-ic ">
                            {Permission?.carrier_delete ? "done" : "clear"}
                          </span>
                          <p> User Delete</p>
                        </li>
                        <li className="items-names">
                          <span class="material-icons rights-ic ">
                            {Permission?.carrier_complete_access
                              ? "done"
                              : "clear"}
                          </span>
                          <p> User Complete Access </p>
                        </li>
                      </ul>
                    )} */}
                  </div>
                )}
                {/*  Here we need to show the Employeer data */}
                {/* {data?.role&&data?.role?.id=="64e5cdfc5bb9a041a39046d5"?<>
                        <div className="col-md-12 mb-3">
                            <label className="profileheddingcls">Company Details</label>
                            <hr/>
                            <div className=''> 
                            <div className="imagethumbWrapper">
                            <img  className='uploadimage' src={`${ !data?.companyImage?"/assets/img/noimage.jpg" :environment.api+"images/users/"+data?.companyImage}`}/>
                            </div>
                                    <div className='row'>
                                      <div className='col-md-6'>
                                        <label className="profileheddingcls">Name </label>
                            {data.companyname==""?null:<div className='profiledetailscls'>{data?.companyName}</div>}</div>
                                        <div className='col-md-6'>
                                        <label className="profileheddingcls">Website</label>
                            {data?.companyWebsite==""?null: <div className='profiledetailscls'>{data.companyWebsite}</div>}</div>

                            <div className='col-md-6'>
                                        <label className="profileheddingcls">StartDate</label>
                            {data.companyStartDate==""?null:<div className='profiledetailscls'>{moment(data?.companyStartDate).format("DD MMM YYYY")}</div>}</div>                                        <div className='col-md-6'>
                                        
                            <div className='col-md-6'>
                                        <label className="profileheddingcls">Owner</label>
                            {data?.contactName==""?null:<div className='profiledetailscls'>{data?.contactName}</div>}</div>

                                        </div>
                                        <div className='col-md-6'>
                                        <label className="profileheddingcls">Email</label>
                            {data.contactEmail==""?null:<div className='profiledetailscls'>{data?.contactEmail}</div>}</div>
                            <div className='col-md-6'>
                                        <label className="profileheddingcls">MobileNumber</label>
                            { data.companyMobileNo==""?<div className='profiledetailscls'>{data?.companyMobileNo}</div>:null}</div>
                            <div className='col-md-12'>
                                        <label className="profileheddingcls">Description</label>
                            {data.companyDescription==""?null:<div className='profiledetailscls'>{data.companyDescription}</div>}</div>
                                        </div> 
                            </div>
                        </div>  
                        
                        </>:null}
                        {/*  Here We need to show the all things thats the Virtual Assitance Submit in Form  */}
                {/* {data?.role&&data.role.id=="64e83a928599356bddc2fa00"?<> */}

                {/* <div className='col-md-12'>
                        <label className="profileheddingcls">Documents</label><hr/>
                            <div className='profiledetailscls'>{data?.document&&data.document.map((item,index)=>(
                                <img style={{cursor:"pointer"}} src='https://cdn1.iconfinder.com/data/icons/human-resources-33/100/hr-06-512.png' onClick={e=>window.open(`${environment.api}images/document/${item}`)}  width={40} height={40} />
                            ))}</div>
                        </div>  */}

                {/*                        
                        
                     
                    {data.isVerifiedDocument!="accepted"&&data.isVerifiedDocument!="rejected"?    <div className="col-md-6 mb-3 mt-4">
                        <button type="button" className="btn btn-primary " onClick={e=>HandleAccpetReject(e,"accepted")} >Accept</button>
                        <button type="button" className="btn btn-secondary ml-2" data-toggle="modal" data-target="#openrejectmodal">Reject</button>
                        </div>:null} */}
                {/* </>:null}  */}
                {/* This is for Recruiter */}
                {/* {data?.role&&data.role.id=="652393ee64451a10065b76eb"&&data.isVerifiedDocument!="accepted"&&data.isVerifiedDocument!="rejected"?    <div className="col-md-6 mb-3 mt-4">
                        <button type="button" className="btn btn-primary " onClick={e=>HandleAccpetReject(e,"accepted","Recruiter")} >Accept</button>
                        <button type="button" className="btn btn-secondary ml-2" data-toggle="modal" data-target="#openrejectmodal">Reject</button>
                        </div>:null} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TruckDetails;
