import CanvasJSReact from "../assets/canvasjs.react";
const list = [
  "#3c559b",
  "#3c559b",
  "#3c559b",
  "#3c559b",
  "#3c559b",
  "#3c559b",
  "#3c559b",
];

const colorsModel = { list };
export default colorsModel;
