import React, { useState, useEffect } from 'react';
import ApiClient from '../../methods/api/apiClient';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
import addressModel from '../../models/address.model';
import { toast } from 'react-toastify';
import { login_success } from '../../actions/user';
import loader from '../../methods/loader';
import methodModel from '../../methods/methods';
import Layout2 from '../../components/global/Layout2';
import datepipeModel from '../../models/datepipemodel';
import AllChart from '../../components/common/AllChart';
import DateRangePicker from '../../components/common/DateRangePicker';

export default function Dashboard() {
  // Get the query string from the URL
  const queryString = window.location.search;
  // Create a new URLSearchParams object with the query string
  const searchParams = new URLSearchParams(queryString);

  // Get the value of a specific parameter, for example 'q'
  const id = searchParams.get('id');
  const user = useSelector((state) => state.user);
  const websitedetails = useSelector((state) => state.website);
  const [form, setform] = useState({});
  const [google, setgoogle] = useState(false);
  const [data, setData] = useState([]);
  const [total, settotal] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  let current = datepipeModel.datetostring(new Date());
  let startDate = `${current.split("-")[0]}-${current.split("-")[1]}-01`;

  let month2 = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );

  let endDate = datepipeModel.datetostring(month2);
  const [filters, setFilter] = useState({
    startDate: startDate,
    endDate: endDate,
    compare: '',
    compareStart: '',
    compareEnd: '',
    type: 'monthly',
  });
  
  const [datafaq, setdatafaq] = useState([]);

  const Getdata = () => {
    loader(true);
    ApiClient.get('faq/all').then((res) => {
      if (res.success) {
        setdatafaq(res?.data?.data);
        loader(false);
      }
    });
  };

  useEffect(() => {
    Getdata();
  }, []);

  const GetLoads = () => {
    setgoogle(true);
    ApiClient.get('loads', { count: 6 }).then((res) => {
      if (res.success) {
        setTimeout(() => {
          setgoogle(false);
        }, 1000);
        setData(res?.data?.data);
        settotal(res?.data?.total);
        // ExtractState(res?.data?.data);
      }
    });
  };

  const LetSubscribe = (e) => {
    loader(true);
    e.preventDefault();
    ApiClient.post('addNewsLetter', form).then((res) => {
      if (res.success) {
        loader(false);
        setform({ email: '' });

        toast.success(res?.message);
      }
      loader(false);
    });
  };

  useEffect(() => {
    localStorage.removeItem('Step1');
    localStorage.removeItem('Step2');
    GetLoads();
  }, []);

  const DestinationAddress = async (e) => {
    
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }

    setFilter({
      ...filters,
      address: e.value || '',
      city: address.city || '',
      state: address.state || '',
      country: address.country || '',
      pincode: address.zipcode || '',
      lat: `${address.lat}` || '',
      lng: `${address.lng}` || '',
    });
  };

  useEffect(() => {
    if (id) {
      loader(true);
      localStorage.clear();
      ApiClient.post('user/auto-login', { id: id }).then((response) => {
        if (response.success) {
          dispatch(login_success(response.data));
          localStorage.setItem('token', response.data.access_token);
          toast.success(response.message);
          const newdata = response.data;
          ApiClient.get('trucks').then((res) => {
            if (res?.data?.data?.length == 0) {
              history.push('/trucks/add');
            } else {
              history.push('/profile');
            }
          });
        }
        loader(true);
      });
    }
    localStorage.removeItem('Step1');
    localStorage.removeItem('Step2');
  }, []);

  const HandleSubmit = () => {
    // if (filters?.keyword && filters?.city) {
    // history.push(
    //   `/jobs?keyword=${filters?.keyword}&lat=${filters?.lat}&lng=${filters?.lng}`
    // );
    // } else {
      if(user?.loggedIn){
        if (filters?.keyword) 
          {
            history.push(`/jobs?search=${filters?.keyword}`);
          }
          else
          {
            history.push(`/jobs`);
          }
       
      }
      else
      {
        history.push(`/login`);
      }
     
    // if (filters?.city) {
    //   history.push(`/jobs?city=${filters?.city}`);
    // }
    // if (filters?.city && filters?.keyword) {
    //   history.push(
    //     `/jobs?search=${filters?.keyword}${
    //       filters?.city ? `&city=${filters?.city}` : ""
    //     }`
    //   );
    // }
    // toast.error("Please Fill Location and Search");
    // }
  };

  // const ExtractState = (loadData) => {
  //   loadData?.map((itm) => {
  //     let city = itm?.destination_address?.split(',')[0].split(' ')[1];
  //     let state = itm?.destination_address?.split(', ')[1].split(' ')[0];
 
  //   });
  //   // return {
  //   //   city,
  //   //   state,
  //   // };
  // };

  const Navigate = useHistory();
  //   const user = useSelector((state) => state.user);
  const [AverageCost, setAverageCost] = useState([]);
  const [Filtername, setFIltername] = useState('');

  const [TruckPerformace, SetTruckPerformace] = useState([]);
  const [DriverPerformace, SetDriverPerformace] = useState([]);
  const [Board, setBoards] = useState([]);
  //   const [data, setdata] = useState([]);
  const getBaords = () => {
    ApiClient.get('boards?status=active').then((res) => {
      if (res.success) {
        setData(res?.data?.data);
 
        const filteredBoards = res?.data?.data.filter((item) =>
          user?.board_id?.includes(item.id)
        );
        setBoards(filteredBoards);
      }
    });
  };

  const ChangeStatus = (p) => {
    getData(p);
  };

  const [bidChart, setBidChart] = useState([]);
  const [loadChart, setLoadChart] = useState([]);
  const [cartData, setChartData] = useState({
    totalBids: 0,
    totalLoads: 0,
  });

  const [bidData, setBidData] = useState();

  const getData = (p = {}) => {
    getAnalytic(p);
    getBids(p);
  };

  const getAnalytic = (p = {}) => {
    let f = { ...filters, ...p, carrier_id: user.id };
    loader(true);
    ApiClient.get('analytics', f).then((res) => {
      if (res.success) {
        let totalBids = 0;

        let data = res.BidsCounts.response.map((itm) => {
          let month = `${datepipeModel.monthfind(itm.month - 1)}`;
          itm.date = `${month} ${itm.year}`;
          if (f.type == 'daily') itm.date = `${itm.day} ${month}`;
          totalBids += itm.totalBidsCounts;
          return itm;
        });

        let totalLoads = 0;
        let ldata = res.LoadsCounts.response.map((itm) => {
          let month = `${datepipeModel.monthfind(itm.month - 1)}`;
          itm.date = `${month} ${itm.year}`;
          if (f.type == 'daily') itm.date = `${itm.day} ${month}`;
          totalLoads += itm.totalBidsCounts;
          return itm;
        });

        setChartData({
          totalBids: totalBids,
          totalLoads: totalLoads,
        });
        setBidChart(data);
        setLoadChart(ldata);
      }
      loader(false);
    });
  };

  const getBids = (p = {}) => {
    let f = { ...filters, ...p };
    ApiClient.get('carrier/bid', f).then((res) => {
      if (res.success) {
        setBidData(res);
      }
    });
  };

  const filter = (p = {}) => {
    setFilter({ ...filters, ...p });
    getData(p);
  };

  const onRangeChange = (e) => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
      compare: e.compare,
      compareStart: e.compareStart,
      compareEnd: e.compareEnd,
      type: e.type,
    };
    if (
      datepipeModel.datetostring(e.startDate) ==
      datepipeModel.datetostring(e.endDate)
    ) {
      setFilter({ ...filters, ...f });
    } else {
      filter({ ...f });
    }
  };

  const GetAverage = [
    ['Origin Address', 'Total', 'Avg Cost ($)'],
    ...((AverageCost?.length > 0 &&
      AverageCost?.map((item) => {
        
        return [
          `${item?.address}`,
          item?.totalLoad,
          Math.round(item?.avg_cost),
        ];
      })) || [
      ['Element', 'Density', { role: 'style' }],
      ['Copper', 8.94, '#b87333'], // RGB value
      ['Silver', 10.49, 'silver'], // English color name
      ['Gold', 19.3, 'gold'],
      ['Platinum', 21.45, 'color: #e5e4e2'], // CSS-style declaration
    ]),
  ];
  const GetAverageCost = () => {
    let array = [];
    ApiClient.get('total-load/board').then((res) => {
   
      if (res.success) {
        // setFrequency(res?.data);
        let croped = res?.data?.splice(0, 8);
        croped?.map((itm) => {
          array.push({
            totalLoad: itm?.count,
            address: itm?.origin_address,
            avg_cost: itm?.avg_cost,
          });
        });
      }

      setAverageCost(array);
    });
  };

  const TruckPerformaceArray = [
    ['Origin Address', 'Total', 'Avg Cost ($)'],
    ...((TruckPerformace?.length > 0 &&
      TruckPerformace?.map((item) => {
      
        return [
          `${item?.address}`,
          item?.totalLoad,
          Math.round(item?.avg_cost),
        ];
      })) || [
      ['Element', 'Density', { role: 'style' }],
      ['Copper', 8.94, '#b87333'], // RGB value
      ['Silver', 10.49, 'silver'], // English color name
      ['Gold', 19.3, 'gold'],
      ['Platinum', 21.45, 'color: #e5e4e2'], // CSS-style declaration
    ]),
  ];
  const GetTruckPerformace = () => {
    let array = [];
    ApiClient.get('admin/dashboard/lane-frequency/cost-analysis').then(
      (res) => {
     
        if (res.success) {
          // setFrequency(res?.data);
          let croped = res?.data?.splice(0, 8);
          croped?.map((itm) => {
            array.push({
              totalLoad: itm?.count,
              address: itm?.origin_address,
              avg_cost: itm?.avg_cost,
            });
          });
        }

        SetTruckPerformace(array);
      }
    );
  };

  const DriverPerformaceArray = [
    ['Origin Address', 'Total', 'Avg Cost ($)'],
    ...((DriverPerformace?.length > 0 &&
      DriverPerformace?.map((item) => {
       
        return [
          `${item?.address}`,
          item?.totalLoad,
          Math.round(item?.avg_cost),
        ];
      })) || [
      ['Element', 'Density', { role: 'style' }],
      ['Copper', 8.94, '#b87333'], // RGB value
      ['Silver', 10.49, 'silver'], // English color name
      ['Gold', 19.3, 'gold'],
      ['Platinum', 21.45, 'color: #e5e4e2'], // CSS-style declaration
    ]),
  ];
  const GetDriverPerformace = () => {
    let array = [];
    ApiClient.get('admin/dashboard/lane-frequency/cost-analysis').then(
      (res) => {

        if (res.success) {
          // setFrequency(res?.data);
          let croped = res?.data?.splice(0, 8);
          croped?.map((itm) => {
            array.push({
              totalLoad: itm?.count,
              address: itm?.origin_address,
              avg_cost: itm?.avg_cost,
            });
          });
        }

        SetTruckPerformace(array);
      }
    );
  };
  useEffect(() => {
    if (user.id) {
      getData();
      GetAverageCost();
      getBaords();
    }
  }, [user]);

  return (
    <>
      <Layout2 title="Dashboard" title2="Dashboard">
        <div className="mainareas">
      
          <div className="front-img">
            <img src="assets/img/banners-img.png" />
          </div>
          <div className="load-search">
            <h2>Looking for a load</h2>
            <div className="home-searchbar">
             
              {/* <div class="input-group ">
                <span class="input-group-text" id="basic-addon1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                  >
                    <path
                      d="M10.9206 17.8712C15.007 17.8712 18.3197 14.5585 18.3197 10.4721C18.3197 6.3857 15.007 3.073 10.9206 3.073C6.8342 3.073 3.5215 6.3857 3.5215 10.4721C3.5215 14.5585 6.8342 17.8712 10.9206 17.8712Z"
                      stroke="#3F559E"
                      stroke-width="1.38733"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.1694 19.7208L16.1462 15.6975"
                      stroke="#3F559E"
                      stroke-width="1.38733"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <input
                  type="search"
                  class="keysearch"
                  placeholder="Job title, Keyword..."
                  aria-label="Username"
                  disabled
                  // value={filters?.keyword}
                  aria-describedby="basic-addon1"
                  onChange={(e) => {
                    setFilter({ ...filters, keyword: e.target.value });
                  }}
                />
              </div> */}
              <div className="row">
                <div className="col-lg-7">
                <label>Enter Location</label>
                  <div className="position-relative select-load-width">
                    <div class="input-group ">
                      <span class="input-group-text" id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_19_13)">
                            <path
                              d="M19.5375 9.54715C19.5375 16.0214 11.2135 21.5707 11.2135 21.5707C11.2135 21.5707 2.88953 16.0214 2.88953 9.54715C2.88953 7.33949 3.76652 5.22224 5.32757 3.66119C6.88863 2.10014 9.00587 1.22314 11.2135 1.22314C13.4212 1.22314 15.5384 2.10014 17.0995 3.66119C18.6605 5.22224 19.5375 7.33949 19.5375 9.54715Z"
                              stroke="#3F559E"
                              stroke-width="1.38733"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.2135 12.3218C12.7459 12.3218 13.9882 11.0795 13.9882 9.54713C13.9882 8.01472 12.7459 6.77246 11.2135 6.77246C9.6811 6.77246 8.43884 8.01472 8.43884 9.54713C8.43884 11.0795 9.6811 12.3218 11.2135 12.3218Z"
                              stroke="#3F559E"
                              stroke-width="1.38733"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_19_13">
                              <rect
                                width="22.1973"
                                height="22.1973"
                                fill="white"
                                transform="translate(0.11496 0.29834)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      {/* {!google && (
                        <GooglePlaceAutoComplete
                          value={filters?.location}
                          key="Map Component"
                          id={'map'}
                          result={DestinationAddress}
                          placeholder="Your Location"
                        />
                      )} */}
                      <input
                        placeholder="Enter Location"
                        value={filters?.keyword}
                        onChange={(e) => {
                          setFilter({ ...filters, keyword: e.target.value });
                        }}
                      />
                    </div>
                    <div className="submit-btns">
                      <button className={`btn dark-btn`}   onClick={HandleSubmit}>
                        Find Job
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="select-load-right">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Select Board</label>
                        <div className="dropdown addDropdown">
                          <button
                            className="btn btn-primary dropdown-toggle "
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {Filtername
                              ? Filtername == ''
                                ? 'All'
                                : methodModel.capitalizeFirstLetter(Filtername)
                              : 'All'}
                          </button>
                          <div
                            className="dropdown-menu shadow bg_hover"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              className={
                                Filtername == ''
                                  ? 'dropdown-item active'
                                  : 'dropdown-item'
                              }
                              onClick={() => {
                                setFIltername('All');

                                ChangeStatus({ board_id: '' });
                              }}
                            >
                              All
                            </a>
                            {Board &&
                              Board?.map((itm) => {
                                return (
                                  <a
                                    className={
                                      filters.board_id == itm?.id
                                        ? 'dropdown-item active'
                                        : 'dropdown-item'
                                    }
                                    onClick={() => {
                                      setFIltername(itm?.name);
                                      ChangeStatus({ board_id: itm?.id });
                                    }}
                                  >
                                    {methodModel.capitalizeFirstLetter(
                                      itm?.name
                                    )}
                                  </a>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-8">
                        <label>Select Date</label>
                        <DateRangePicker
                          value={{
                            startDate: filters.startDate,
                            endDate: filters.endDate,
                            compare: filters.compare,
                            compareStart: filters.compareStart,
                            compareEnd: filters.compareEnd,
                          }}
                          onChange={(e) => onRangeChange(e)}
                          fullWidth={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="cards_sales">
              <div className="d-flex gap-2">
                <div className="ms-auto d-flex align-items-center"></div>
              </div>

              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
                  <div className="">
                    <div
                      className="main_sales"
                      onClick={() => {
                        Navigate.push('/bids/awarded-bid');
                        // localStorage.setItem("BidStatus", "accepted");
                      }}
                    >
                      <div className="das-box">
                        <div className="">
                          <div>
                            <img
                              src="assets/img/awarded_one.svg"
                              className="wallets_img"
                            />
                          </div>
                          <div>
                            <p className="sub_heading">Total Awarded Bids</p>
                          </div>
                        </div>
                        <div className="sales_icons">
                          <h3 className="Amount_sales">
                            {bidData?.awarded_bids}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
                  <div className="">
                    <div
                      className="main_sales"
                      onClick={() => {
                        Navigate.push('/bids/accepted-bid');
                        // localStorage.setItem("BidStatus", "accepted");
                      }}
                    >
                      {' '}
                      <div className="das-box">
                        <div className="">
                          <div>
                            <img
                              src="assets/img/accepted_one.svg"
                              className="wallets_img"
                            />
                          </div>
                          <div>
                            <p className="sub_heading">Total Accepted Bids</p>
                          </div>
                        </div>
                        <div className="sales_icons">
                          <h3 className="Amount_sales">
                            {bidData?.accepted_bids}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3  mt-3">
                  <div className="">
                    <div
                      className="main_sales"
                      onClick={() => {
                        Navigate.push('/bids/rejected-bid');
                        // localStorage.setItem("BidStatus", "rejected");
                      }}
                    >
                      <div className="das-box">
                        <div className="">
                          <div>
                            <img
                              src="assets/img/rejected_one.svg"
                              className="wallets_img"
                            />
                          </div>
                          <div>
                            <p className="sub_heading">Total Rejected Bids</p>
                          </div>
                        </div>
                        <div className="sales_icons">
                          <h3 className="Amount_sales">
                            {bidData?.rejected_bids}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
                  <div className="">
                    <div
                      className="main_sales"
                      onClick={() => {
                        Navigate.push('/bids/pending-bid');
                        // localStorage.setItem("BidStatus", "pending");
                      }}
                    >
                      <div className="das-box">
                        <div className="">
                          <div>
                            <img
                              src="assets/img/pending_one.svg"
                              className="wallets_img"
                            />
                          </div>
                          <div>
                            <p className="sub_heading">Total Pending Bids</p>
                          </div>
                        </div>
                        <div className="sales_icons">
                          <h3 className="Amount_sales">
                            {bidData?.pending_bids}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* graph Design */}

              <div className="row">
                <div className="col-12 col-sm-12 cls">
                  <div className="graph_section">
                    <div className="graph_range mb-3">
                      <div>
                        <h3 className="Amount_sales">Bids</h3>
                      </div>

                      <div>
                        <p
                          className="view_graph"
                          onClick={(e) => Navigate.push(`/bids`)}
                        >
                          View Bids
                          <span className="arrowss">
                            <i class="material-icons">keyboard_arrow_right</i>
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="">
                      <AllChart
                        data={bidChart}
                        legends={[
                          { label: 'Total Bids', key: 'totalBidsCounts' },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-5">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">
                      Performance Metric of Carrier
                    </h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/jobs`)}
                    >
                      View Jobs
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={GetAverage}
                    options={{
                      colors: ["#febf01", "#a5c956"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-5">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">
                      Performance Metric of Trucks
                    </h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/jobs`)}
                    >
                      View Jobs
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                 
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={TruckPerformaceArray}
                    options={{
                      colors: ["#febf01", "#a5c956"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                 
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-5">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">
                      Performance Metric of Drivers
                    </h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/jobs`)}
                    >
                      View Jobs
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                 
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={DriverPerformaceArray}
                    options={{
                      colors: ["#febf01", "#a5c956"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                  
                </div>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </Layout2>
    </>
  );
}
