import React, { useEffect } from "react";
// import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { PersistGate } from "redux-persist/es/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import { ConnectedRouter } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import configureStore from "./config";
import { createBrowserHistory } from "history";
import Login from "./pages/Login";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import "react-pagination-js/dist/styles.css";
import "./scss/main.scss";
import "./scss/responsive.scss";

import SignUp from "./pages/Signup";
import Forgotpassword from "./pages/Forgotpassword";
import Resetpassword from "./pages/Resetpassword";
import Dashboard from "./pages/DashboardPages";
import Dashboard2 from "./pages/Dashboard2";
import { Auth } from "./methods/auth";
import Profile from "./pages/Profile";
import EditProfile from "./components/Profile/Edit";
import ChangePassword from "./components/Profile/ChangePassword";

import Home from "./pages/Home";
export const history = createBrowserHistory();
/************ store configration *********/
import { ToastContainer } from "react-toastify";
import JobSearch from "./pages/JobSearch/JobSearch";
import CommonDelete from "./pages/CommonDeleteActiveModal/CommonDelete";
import JobView from "./pages/JobSearch/JobView";
import JobView2 from "./pages/JobSearch2/JobView"
import Step2 from "./pages/Signup/Step2";
import Step3 from "./pages/Signup/Step3";
import Bids from "./pages/Bids";
import Bids_New from "./pages/Bids_New"
import BidDetails from "./pages/Bids/ViewBids";
import NewBidDetails from "./pages/Bids_New/ViewBids_New"
import AddressStep from "./pages/Signup/AddressStep";
import OTPVerify from "./pages/Forgotpassword/Otpverify";
import AddEditTruck from "./pages/Trucks/AddEditTrucks";
import Trucks from "./pages/Trucks";
import TruckDetails from "./pages/Trucks/ViewTrucks";
import Drivers from "./pages/Drivers";
import AddEditDriver from "./pages/Drivers/AddEditDriver";
import AddEditDriver2 from "./pages/Drivers2/AddEditDriver2";
import DriverDetails from "./pages/Drivers/ViewDriver/Index";
import DriverDetails2 from "./pages/Drivers2/ViewDriver2/Index"
import AboutUs from "./pages/AboutUS/html";
import Contact from "./pages/Contact-Us/Contact";
import AddEditCarrierUsers from "./pages/Users/AddCarrierUser/html";
import Users from "./pages/Users";
import UserDetails from "./pages/Users/ProfileDetails";
import StopsNotes from "./pages/Notes";
import Notification from "./pages/Notifcations";
import ViewNotification from "./pages/Notifcations/ViewNotification";
import OurServices from "./pages/OurServices/html";
import Support from "./pages/Support/html";
import Loads from "./pages/Loads";
import LoadDetails from "./pages/Loads/ViewPage";
import LoadDetails2 from "./pages/Load2/ViewPage"
import InvitedUsers from "./pages/InvitedUsers";
import UserRequest from "./pages/UserRequest";
import Conditions from "./pages/TermsAndConditons/Conditions";
import Load2 from "./pages/Load2";
import Users2 from "./pages/Users2"
import InvitedUsers2 from "./pages/InvitedUsers2"
import UserRequest2 from "./pages/UserRequest2"
import Trucks2 from "./pages/Trucks2"
import Drivers2 from "./pages/Drivers2"
import StopsNotes2 from "./pages/Notes2"
import AddEditCarrierUsers2 from "./pages/Users2/AddCarrierUser2/html"
import UserDetails2 from "./pages/Users2/ProfileDetails2"
import AddEditTruck2 from "./pages/Trucks2/AddEditTrucks2"
import Profile2 from "./pages/Profile2";
import Notification2 from "./pages/Notification2";
import RejectedUsers from "./pages/RejectedUsers";
import RejectedUsers2 from "./pages/RejectedUsers2";
import JobSearch2 from "./pages/JobSearch2/index"
import Carriers from "./pages/Carriers";

const { persistor, store } = configureStore(history);

export default () => {
  const dispatch = useDispatch();

  const Websitedetails = useSelector((state) => state.website);

  return (
    <>
      <PersistGate loading={"loading ..."} persistor={persistor}>
        <ConnectedRouter history={history}>
          <CommonDelete show="none" />
          <Router>
            <Switch>
              <Route
                exact={true}
                store={store}
                path="/login"
                component={Login}
              />
               <Route
                exact={true}
                store={store}
                path="/dashboard"
                component={Dashboard2}
              />
               <Route
                exact={true}
                path="/dashboard2"
                store={store}
                component={Dashboard}
              />
              <Route
                exact={true}
                store={store}
                path="/profile"
                component={Profile2}
              />
              <Route
                exact={true}
                store={store}
                path="/bids"
                component={Bids_New}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/bids/awarded-bid"
                component={Bids_New}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/bids/rejected-bid"
                component={Bids_New}
              />
           
        
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/bids/pending-bid"
                component={Bids_New}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/bids/accepted-bid"
                component={Bids_New}
              />
               {/* <Route
                exact={true}
                store={store}
                path="/loads"
                component={Load2}
              /> */}
              <Route
                exact={true}
                store={store}
                path="/login/:id"
                component={Login}
              />
              <Route
                exact={true}
                store={store}
                path="/jobs1"
                component={JobSearch}
              />
              <Route
                exact={true}
                store={store}
                path="/jobs"
                component={JobSearch2}
              />    
              <Route
                exact={true}
                store={store}
                path="/intransit-loads"
                component={Loads}
              />
                <Route
                exact={true}
                store={store}
                path="/loads/pendingPickup-loads"
                component={Load2}
              />
              <Route
                exact={true}
                store={store}
                path="/loads/intransit"
                component={Load2}
              />
                  <Route
                exact={true}
                store={store}
                path="/loads/delivered"
                component={Load2}
              />
              <Route
                exact={true}
                store={store}
                path="/delivered-loads"
                component={Loads}
              />
              <Route
                exact={true}
                store={store}
                path="/pendingPickup-loads"
                component={Loads}
              />
              <Route
                exact={true}
                store={store}
                path="/loadDetail1/:id"
                component={LoadDetails}
              />
               <Route
                exact={true}
                store={store}
                path="/loadDetail/:id"
                component={LoadDetails2}
              />
              {/* <Route
                exact={true}
                store={store}
                path="/pendingPickup-jobs"
                component={JobSearch}
              />
              <Route
                exact={true}
                store={store}
                path="/intransit-jobs"
                component={JobSearch}
              /> */}
              <Route
                exact={true}
                store={store}
                path="/jobs1/:id"
                component={JobSearch}
              />
                 <Route
                exact={true}
                store={store}
                path="/jobs/:id"
                component={JobSearch2}
              />
                 <Route
                exact={true}
                store={store}
                path="/jobs/view/:id"
                component={JobView2}
              />
              <Route
                exact={true}
                store={store}
                path="/job1/view/:id"
                component={JobView}
              />
              <Route
                exact={true}
                store={store}
                path="/job/:id"
                component={JobView}
              />
              <Route
                exact={true}
                store={store}
                path="/signup"
                component={SignUp}
              />
              <Route
                exact={true}
                store={store}
                path="/otp-verify"
                component={OTPVerify}
              />
              <Route
                exact={true}
                store={store}
                path="/about-us"
                component={AboutUs}
              />
              <Route
                exact={true}
                store={store}
                path="/contact-us"
                component={Contact}
              />

              <Route
                exact={true}
                store={store}
                path="/trucks1/view/:id"
                component={TruckDetails}
              />
              <Route
                exact={true}
                store={store}
                path="/trucks1"
                component={Trucks}
              />
              <Route
                exact={true}
                store={store}
                path="/trucks"
                component={Trucks2}
              />
              <Route
                exact={true}
                store={store}
                path="/trucks/add"
                component={AddEditTruck2}
              />
              <Route
                exact={true}
                store={store}
                path="/drivers1"
                component={Drivers}
              />
            
              <Route
                exact={true}
                store={store}
                path="/drivers1/add"
                component={AddEditDriver}
              />
              <Route
                exact={true}
                store={store}
                path="/drivers1/edit/:id"
                component={AddEditDriver}
              />
                <Route
                exact={true}
                store={store}
                path="/drivers"
                component={Drivers2}
              />
              <Route
                exact={true}
                store={store}
                path="/drivers/add"
                component={AddEditDriver2}
              />
              <Route
                exact={true}
                store={store}
                path="/drivers/edit/:id"
                component={AddEditDriver2}
              />
              <Route
                exact={true}
                store={store}
                path="/drivers/details/:id"
                component={DriverDetails2}
              />
              <Route
                exact={true}
                store={store}
                path="/drivers1/details/:id"
                component={DriverDetails}
              />

              <Route
                exact={true}
                store={store}
                path="/trucks1/add"
                component={AddEditTruck}
              />
                <Route
                exact={true}
                store={store}
                path="/users"
                component={Users2}
              />
                <Route
                exact={true}
                store={store}
                path="/users/add"
                component={AddEditCarrierUsers2}
              />
              <Route
                exact={true}
                store={store}
                path="/users/edit/:id"
                component={AddEditCarrierUsers2}
              />
                <Route
                exact={true}
                store={store}
                path="/users/detail/:id"
                component={UserDetails2}
              />
              <Route
                exact={true}
                store={store}
                path="/users1"
                component={Users}
              />
             
              <Route
                exact={true}
                store={store}
                path="/user1/detail/:id"
                component={UserDetails}
              />
              <Route
                exact={true}
                store={store}
                path="/user1/edit/:id"
                component={AddEditCarrierUsers}
              />
              <Route
                exact={true}
                store={store}
                path="/user1/details/:id"
                component={Users}
              />

              <Route
                exact={true}
                store={store}
                path="/user1/add"
                component={AddEditCarrierUsers}
              />

              <Route
                exact={true}
                store={store}
                path="/signup-step-2"
                component={Step2}
              />
              <Route
                exact={true}
                store={store}
                path="/signup-step-1"
                component={AddressStep}
              />

              <Route
                exact={true}
                store={store}
                path="/signup-step-3"
                component={Step3}
              />
              <Route
                exact={true}
                store={store}
                path="/forgotpassword"
                component={Forgotpassword}
              />
              <Route
                exact={true}
                store={store}
                path="/resetpassword"
                component={Resetpassword}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/bidDetail/:id"
                component={BidDetails}
              />
                <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/bids/Detail/:id"
                component={NewBidDetails}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/bid"
                component={Bids}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/awarded-bids"
                component={Bids}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/rejected-bids"
                component={Bids}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/invited-users1"
                component={InvitedUsers}
              />
                <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/rejected-users1"
                component={RejectedUsers}
              />
               <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/carriers/rejected-users"
                component={RejectedUsers2}
              />
               <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/carriers/invited-users"
                component={InvitedUsers2}
              />
               <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/carriers"
                component={Carriers}
              />
           
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/user-request1"
                component={UserRequest}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/carriers/user-request"
                component={UserRequest2}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/pending-bids"
                component={Bids}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/accepted-bids"
                component={Bids}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/profile1"
                component={Profile}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/notes1"
                component={StopsNotes}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/notes"
                component={StopsNotes2}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/privacy-polices"
                component={Conditions}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/notifications"
                component={Notification}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/our-services"
                component={OurServices}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/support"
                component={Support}
              />
               <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/notification"
                component={Notification2}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/notifications/:id"
                component={ViewNotification}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/profile1/edit"
                component={EditProfile}
              />
              <Route
                exact={true}
                requireAuth={Auth}
                store={store}
                path="/change"
                component={ChangePassword}
              />
              <Route exact={true} path="/" store={store} component={Home} />
             

              <Route exact path="/">
                <Redirect to="/" />
              </Route>
              <Route exact path="/">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Router>
        </ConnectedRouter>
      </PersistGate>
      <div className="parent_loader">
        <div id="loader" className="loaderDiv d-none">
          {/* <div>
                <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
            </div> */}
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
