import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import { Tooltip } from "antd";

function NotificationList() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState({});

  return (
    <>
      <ul className="ul-inline-set p-0 mb-0 mr-2">
        <li className="ps-3 pe-0 pointer">
          <div className="position-relative">
            <div className="notification-icon">
              <span
                className="icon "
                // onClick={handleGetNotification}
              >
                <Tooltip title="Notification" placement="bottom">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="21"
                    viewBox="0 0 18 21"
                    fill="none"
                  >
                    <path
                      d="M2.10607 15.1061L2.15 15.0621V15V9C2.15 5.96635 4.10645 3.32467 7.0431 2.44367L7.15 2.4116V2.3V2C7.15 0.982843 7.98284 0.15 9 0.15C10.0172 0.15 10.85 0.982843 10.85 2V2.3V2.4116L10.9569 2.44367C13.8935 3.32467 15.85 5.96635 15.85 9V15V15.0621L15.8939 15.1061L17.85 17.0621V17.85H0.15V17.0621L2.10607 15.1061ZM3.85 16V16.15H4H14H14.15V16V9C14.15 6.11716 11.8828 3.85 9 3.85C6.11716 3.85 3.85 6.11716 3.85 9V16ZM10.8439 19.15C10.7669 20.0978 9.96668 20.85 9 20.85C8.03332 20.85 7.23312 20.0978 7.15607 19.15H10.8439Z"
                      fill="black"
                      stroke="white"
                      stroke-width="0.3"
                    />
                  </svg>
                </Tooltip>
              </span>
              {count > 0 && <span className="badge">{count}</span>}
              {showNotifications && (
                <div className="notifications">
                  <div className="d-flex align-items-center justify-content-between notifhead">
                    <p className="m-0">Notification</p>
                    {notificationcount > 0 ? (
                      <button
                        className="btn-sm fs12 btn btn-primary"
                        onClick={handleClearAllNotification}
                      >
                        Clear all
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  {notificationcount > 0 ? (
                    <div>
                      {notificationData?.map((notification, index) => (
                        <>
                          <div
                            className="chat_shoadow"
                            onClick={() => handleStatusUpdate(notification)}
                          >
                            <div className="d-flex align-items-center justify-content-between notifdiv">
                              <h6>{notification?.addedBy_name} </h6>
                              <p>
                                {moment(notification?.createdAt).format("LT")}
                              </p>
                            </div>
                            {/* <p className='msgesg_data'>  {notification?.title === "message" ? <i className='fa fa-comment-o' ></i> : <i className='fa fa-dot-circle-o' ></i>}  {notification?.message}</p> */}

                            <p className="msgesg_data">
                              {notification?.type ===
                                "missed_product_retrieval" && (
                                <i className="fa fa-ban mr-1"></i>
                              )}
                              {notification?.type === "message" && (
                                <i className="fa fa-comment-o mr-1"></i>
                              )}
                              {notification?.type ===
                                "reminder_product_retrieval" && (
                                <i className="fa fa-clock-o mr-1"></i>
                              )}
                              {notification?.type === "orders" && (
                                <i className="fa fa-shopping-cart mr-1"></i>
                              )}
                              {notification?.message}
                            </p>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    "No Notification"
                  )}
                </div>
              )}
            </div>
          </div>
        </li>
      </ul>
    </>
  );
}

export default NotificationList;
