import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login_success } from "../../actions/user";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { Link } from "react-router-dom";
import "./style.scss";
import methodModel from "../../methods/methods";
import { toast } from "react-toastify";
import environment from "../../environment";
import logo from "../../assets/imgpsh_fullsize_anim (1).jpeg";
const OTPVerify = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const Websitedetails = useSelector((state) => state.website);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/dashboard");
    }
  }, []);

  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [username, setUsername] = useState("");
  const [remember, setRemember] = useState(false);
  const [otp, setotp] = useState("");
  const [password, setPassword] = useState("");
  const [Confirmpassword, setConfirmPassword] = useState("");
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 500);

    let r = localStorage.getItem("remember");
    if (r) {
      let data = JSON.parse(r);
      setUsername(data.email);
      setPassword(data.password);
      setRemember(true);
    }

    let message = methodModel.getPrams("message");
  }, []);

  const hendleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (Confirmpassword !== password || !otp || password.length < 8) {
      return;
    }
    const data = {
      code: otp,
      newPassword: password,
    };

    loader(true);

    ApiClient.put("reset/password", data).then((res) => {
      loader(false);
      if (res.success) {
        if (remember) {
          localStorage.setItem("remember", JSON.stringify(data));
        } else {
          localStorage.removeItem("remember");
        }
        toast.success(res.message);
        // localStorage.setItem("token", res.data.access_token);
        // dispatch(login_success(res.data));
        const newdata = res.data;
        history.push("/login");
      }
    });
  };

  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  useEffect(() => {
    if (id) {
      loader(true);
      localStorage.clear();
      ApiClient.post("auto/login", { id: id }).then((response) => {
        if (response.success) {
          // dispatch(login_success(response.data))
          // localStorage.setItem('token', response.data.access_token)
          toast.success(response.message);
          const newdata = response.data;
          history.push("/login");
        }
        loader(false);
      });
    }
  }, []);

  return (
    <>
      <div className="bg_img main_signup">
        {/* <Link className="right_home" to="/">
          <span class="material-icons arrow_right">arrow_back</span> Home
        </Link> */}
        <div className=" center-img">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="right_side">
                  <Link to="/">
                    <div className="logo_image">
                      <img src={logo} className="logo_name mb-2" />
                    </div>
                  </Link>
                  <div className="text-center mb-2">
                    <h3 className="text-cenetr lgtext">New Password</h3>
                  </div>
                  {/* <p className="accopunt text-center">
                Don’t have an account?{" "}
                <Link className="sign_up" to="/signup">
                  {" "}
                  Sign Up
                </Link>
              </p> */}

                  <form className="centerLogin mt-3" onSubmit={hendleSubmit}>
                    <div className="mb-3">
                      <label className="ml-1 mb-2">
                        {" "}
                        Verification Code <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mb-0 bginput changes"
                        placeholder="Verification Code  "
                        value={otp}
                        onChange={(e) => setotp(e.target.value)}
                        // required
                      />
                      {submitted && !otp ? (
                        <div className="invalid-feedback d-block">
                          Verification is required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <label className="ml-1 mb-2">
                      {" "}
                      Password <span className="text-danger">*</span>
                    </label>
                    <div className="mb-3">
                      <div className="inputWrapper">
                        <input
                          type={eyes.password ? "text" : "password"}
                          className="form-control mb-0 bginput changes"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                          // required
                        />
                        <i
                          className={
                            eyes.password ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                          onClick={() =>
                            setEyes({ ...eyes, password: !eyes.password })
                          }
                        ></i>
                        {submitted && password?.length < 8 ? (
                          <div className="invalid-feedback d-block">
                            Password should be atleast of 8 character
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <label className="ml-1 mb-2">
                      {" "}
                      Confirm Password <span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <div className="inputWrapper">
                        <input
                          type={eyes.confirmPassword ? "text" : "password"}
                          className="form-control mb-0 bginput changes"
                          value={Confirmpassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder="Password"
                          // required
                        />
                        <i
                          className={
                            eyes.confirmPassword
                              ? "fa fa-eye"
                              : "fa fa-eye-slash"
                          }
                          onClick={() =>
                            setEyes({
                              ...eyes,
                              confirmPassword: !eyes.confirmPassword,
                            })
                          }
                        ></i>
                        {submitted && Confirmpassword != password ? (
                          <div className="invalid-feedback d-block">
                            Password should be matched
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div className="mt-3">
                      <button
                        onClick={() => setSubmitted(true)}
                        type="submit"
                        className="btn dark-btn my-4 mb-1"
                      >
                        Save Password
                      </button>
                      <div className="forget text-center  ">
                        <Link to="/login" className="">
                          Remember Password? Login
                        </Link>
                      </div>
                    </div>
                    {/* <div className='borderCls mt-5'><span className='or'>or</span></div>
                <div className='text-center d-flex justify-content-center mt-5'>
                <a className='btn btn-outline google_id mr-3 '>
                  <img src='/assets/img/gogle.png' />
                  <span className='google_heading'></span>
                </a>
                <a className='btn btn-outline google_id'>
                  <img src='/assets/img/facebooklogo.png' />
                  <span className='google_heading'></span>
                </a>
                </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OTPVerify;
