import ApiClient from "../../methods/api/apiClient";

const PlatformfeeReducer = (state = { platformFee: 10 }, action) => {
  if (action.type == "Plat") {
    return (state = action.data);
  } else {
    return state;
  }
};

export default PlatformfeeReducer;
