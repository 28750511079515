import React, { useEffect, useState } from 'react';
import Layout2 from '../../components/global/Layout2';
import Pagination from 'react-pagination-js';
// import "./style.scss";
import rolesModel from '../../models/roles.model';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import environment from '../../environment';
import { useHistory } from 'react-router-dom';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Switch from 'react-switch';
import CommonDeleteModal from '../CommonDelete&ActiveModal/CommonDeleteModal';
import CommonActiveModal from '../CommonDelete&ActiveModal/CommonActiveModal';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import ApiClient from '../../methods/api/apiClient';
import { Tooltip } from 'antd';
import { BsThreeDots } from 'react-icons/bs';
const Html = ({
  view,
  addressResult,
  edit,
  user,
  ChangeFilter,
  deleteLoad,
  AcceptUser,
  RejectUser,
  reset,
  sorting,
  add,
  colClick,
  tab,
  tabChange,
  ChangeRole,
  ChangeStatus,
  openModal,
  statusChange,
  pageChange,
  addCol,
  deleteItem,
  exportCsv,
  uTableCols,
  removeCol,
  filters,
  ChangeDocumentStatus,
  tableCols,
  setFilter,
  blockunblock,
  loaging,
  getData,
  setReason,
  Reason,
  data,
  exportfun,
  roles,
  role,
  ShowActiveModal,
  setShowActiveModal,
  submitted,
  setSubmitted,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  total = { total },
}) => {
  const Navigate = useHistory();
  const [Min_rate, setMin_rate] = useState('');
  const [Max_rate, setMax_rate] = useState('');
  const [DeleteId, setDeleteId] = useState('');
  const latestSliderValue = React.useRef([0, 0]);
  // const [Reason, setReason] = useState('');
  const [RejectID, setRejectID] = useState('');
  const debouncedHandleSliderChange = debounce((newValues) => {
    const [min, max] = newValues;
    setMin_rate(min);
    setMax_rate(max);
    // console.log("Filter changed. Calling GetAllprojects...");
    getData({ min_rate: min, max_rate: max });
  }, 500);

  const handleSliderChange = (newValues) => {
    if (
      JSON.stringify(newValues) === JSON.stringify(latestSliderValue.current)
    ) {
      return;
    }
    latestSliderValue.current = newValues;
    debouncedHandleSliderChange(newValues);
  };

  const Delete = () => {
    deleteItem(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };

  const setPriceFilter = () => {
    setFilter({ ...filters, min_rate: Min_rate, max_rate: Max_rate });
    getData({ min_rate: Min_rate, max_rate: Max_rate });
  };

  useEffect(() => {
    setMin_rate(0);
    setMax_rate(4000);
  }, []);

  const Permission = JSON.parse(localStorage.getItem('permission'));
  const Role = [
    {
      key: 'staff',
      name: 'Staff',
    },
    {
      key: 'carrier',
      name: 'Carrier',
    },
  ];
  let ListingData = [];
  if (user?.role == 'staff') {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }
  const Handlefilter = (e) => {
    const value = e.target.value;
    setFilter({ ...filters, [e.target.name]: value });
    getData({ [e.target.name]: value });
  };
  const Reject = (e) => {
    setSubmitted(true);
    if (!Reason)
    return;
    RejectUser(RejectID, Reason);
  };
  return (
    <Layout2 title="Carriers" title2="Pending Carriers">
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={StatusCh}
        status={StatusData.status}
      />
      <div className="mainareas">
        <div className="staff-full">
          <div className="d-flex justify-content-between align-items-center mb-4 wrapflex">
            <div className="bids-top">
              <h5 className="mb-0">
                Results <span>{total}</span>
              </h5>
            </div>
            <div className=" d-flex gap-2 align-items-center">
              <div className="searchParent ">
                <input
                  className="form-control "
                  placeholder="Search..."
                  type="search"
                  value={filters.search}
                  name="search"
                  onChange={(e) => Handlefilter(e)}
                />
                <span class="material-icons">search</span>
              </div>
              <div className="dropdown addDropdown chnagesg ms-0 p-0 borer-c d-flex align-items-center">
                <button
                  className="btn blck-border-btn dropdown-toggle removeBg"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {filters.status
                    ? filters.status == 'deactive'
                      ? 'Inactive'
                      : filters.status
                    : 'All Status'}
                </button>

                <div
                  className="dropdown-menu shadow bg_hover"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    className={
                      filters.status == ''
                        ? 'dropdown-item active'
                        : 'dropdown-item'
                    }
                    onClick={() => ChangeStatus('')}
                  >
                    All Status
                  </a>
                  <a
                    className={
                      filters.status == 'active'
                        ? 'dropdown-item active'
                        : 'dropdown-item'
                    }
                    onClick={() => ChangeStatus('active')}
                  >
                    Active
                  </a>
                  <a
                    className={
                      filters.status == 'Inactive'
                        ? 'dropdown-item active'
                        : 'dropdown-item'
                    }
                    onClick={() => ChangeStatus('deactive')}
                  >
                    Inactive
                  </a>
                </div>
                {filters.status || filters.role ? (
                  <>
                    <button
                      className="btn btn-primary undo  ms-2"
                      onClick={(e) => reset()}
                    >
                      <i class="fa fa-undo me-2" aria-hidden="true"></i>
                      Reset
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <button
            style={{ display: 'none' }}
            type="button"
            class="btn btn-primary"
            id="OpenReasonModel"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal2"
            data-bs-whatever="@mdo"
          >
            Open modal for @mdo
          </button>

          <div
            class="modal fade"
            id="exampleModal2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Reason to Reject
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    Reject();
                  }}
                >
                  <div class="modal-body">
                    <label class="profileheddingcls">
                      {' '}
                      Reason <span className="text-danger">*</span>
                    </label>

                    <div class="mb-3">
                      {/* <label for="message-text" class="col-form-label">Message:</label> */}
                      <textarea
                        value={Reason}
                        onChange={(e) => {
                          setReason(e.target.value);
                        }}
                        class="form-control"
                        id="message-text"
                      ></textarea>
                    </div>
                    {submitted && !Reason ? (
                      <div className="invalid-feedback d-block">
                        Reason is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      id="CloseReasonModel"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <>
            <div>
              {/* <div className="staff-listing carrier-width">
                      <div className="staff-main">
                        <ul className="staff-inner">
                          <li>
                            <h5>User Name</h5>
                            <p>
                              {itm.fullName
                                ? methodModel.capitalizeFirstLetter(
                                    itm?.fullName
                                  )
                                : methodModel.capitalizeFirstLetter(
                                    itm?.firstName
                                  )}
                            </p>
                          </li>
                          <li>
                            <h5>Email</h5>
                            <p>{itm?.email}</p>
                          </li>
                          <li>
                            <h5>Date Created</h5>
                            <p> {datepipeModel.date(itm.createdAt) || '--'}</p>
                          </li>
                          <li>
                            <h5>Status</h5>
                            <p>
                              {' '}
                              <div className={`${itm?.status}`}>
                                <span className="custom-toggle-btn heightbtn">
                                  {itm?.status == 'deactive' ? (
                                    <Switch
                                      onChange={(e) => {
                                        setStatusData(itm);
                                        setShowActiveModal('block');
                                      }}
                                      checked={false}
                                    />
                                  ) : (
                                    <Switch
                                      onChange={(e) => {
                                        setStatusData(itm);
                                        setShowActiveModal('block');
                                      }}
                                      checked={true}
                                    />
                                  )}
                                </span>
                              </div>
                            </p>
                          </li>
                        </ul>
                        <ul className="staff-action">
                          <li>
                            <h5>Actions</h5>

                            <div className="d-flex align-items-center action-btns ">
                              <a
                                class="  me-2"
                                onClick={() => {
                                  Navigate.push(
                                    `/users/detail/${itm.id}?Name=Pending Carriers`
                                  );
                                }}
                              >
                                <span className="">
                                  <i class="fa fa-eye"></i>
                                </span>{' '}
                              </a>

                              <a
                                class="accept-user  me-2"
                                onClick={() => {
                                  AcceptUser(itm?.id);
                                }}
                              >
                                <span className="">
                                  <i color="green" className="fa fa-check"></i>
                                </span>{' '}
                              </a>
                              <a
                                class="reject-user  me-2"
                                onClick={() => {
                                  document
                                    .getElementById('OpenReasonModel')
                                    .click();
                                  setRejectID(itm?.id);
                                }}
                              >
                                <span className="">
                                  <i color="red" className="fa fa-times"></i>
                                </span>{' '}
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div> */}

              <div className="table-responsive table_section job-sect-table new-table">
                <table class="table ">
                  <thead className="table_head">
                    <tr className="heading_row">
                      <th scope="col" className="table_data pointer">
                        User Name
                      </th>
                      <th scope="col" className="table_data pointer">
                        Email
                      </th>
                      <th scope="col" className="table_data pointer">
                        Date Created
                      </th>

                      <th scope="col" className="table_data pointer">
                        Status
                      </th>
                      <th scope="col" className="table_data pointer">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loaging &&
                      ListingData &&
                      ListingData.map((itm, i) => {
                        return (
                          <tr>
                            <td>
                              {itm.fullName
                                ? methodModel.capitalizeFirstLetter(
                                    itm?.fullName
                                  )
                                : methodModel.capitalizeFirstLetter(
                                    itm?.firstName
                                  )}
                            </td>
                            <td>{itm?.email}</td>
                            <td>{datepipeModel.date(itm.createdAt) || '--'}</td>

                            <td>
                              <div className={`${itm?.status}`}>
                                <span className="custom-toggle-btn heightbtn mt-0">
                                  {itm?.status == 'deactive' ? (
                                    <Switch
                                      onChange={(e) => {
                                        setStatusData(itm);
                                        setShowActiveModal('block');
                                      }}
                                      checked={false}
                                    />
                                  ) : (
                                    <Switch
                                      onChange={(e) => {
                                        setStatusData(itm);
                                        setShowActiveModal('block');
                                      }}
                                      checked={true}
                                    />
                                  )}
                                </span>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex align-items-center action-btns ">
                                <a
                                  class="  me-2"
                                  onClick={() => {
                                    Navigate.push(
                                      `/users/detail/${itm.id}?Name=Pending Carriers`
                                    );
                                  }}
                                >
                                  <span className="">
                                    <i class="fa fa-eye"></i>
                                  </span>{' '}
                                </a>

                                <a
                                  class="accept-user  me-2"
                                  onClick={() => {
                                    AcceptUser(itm?.id);
                                  }}
                                >
                                  <span className="">
                                    <i
                                      color="green"
                                      className="fa fa-check"
                                    ></i>
                                  </span>{' '}
                                </a>
                                <a
                                  class="reject-user  me-2"
                                  onClick={() => {
                                    document
                                      .getElementById('OpenReasonModel')
                                      .click();
                                    setRejectID(itm?.id);
                                  }}
                                >
                                  <span className="">
                                    <i color="red" className="fa fa-times"></i>
                                  </span>{' '}
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </>

          {!loaging && total == 0 ? (
            <div className=" no-data">
              <img src="/assets/img/no-data.png" />
              No Data
            </div>
          ) : (
            <></>
          )}

          {!loaging && total > 0 ? (
            <div className="paginationWrapper main-pagination page-new">
              <div className="d-flex align-items-center">
                <div className="me-2 user-name-color">Show</div>
                {total > 0 && (
                  <div className="dropdown addDropdown chnagesname ">
                    <select
                      className="form-control"
                      value={filters?.count}
                      onChange={(e) => {
                        setFilter({ ...filters, count: e.target.value });
                        getData({ ...filters, count: e.target.value });
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="30">30</option>
                    </select>
                    <div
                      className="dropdown-menu shadow bg_hover"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 5 });
                        }}
                      >
                        5
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 10 });
                        }}
                      >
                        10
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 20 });
                        }}
                      >
                        20
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 30 });
                        }}
                      >
                        30
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 40 });
                        }}
                      >
                        40
                      </a>
                    </div>
                  </div>
                )}{' '}
                <div className="ms-2 user-name-color">
                  from {total} Pending Carriers
                </div>
              </div>

              <div className="">
                <Pagination
                  currentPage={filters.page}
                  totalSize={total}
                  sizePerPage={filters.count}
                  changeCurrentPage={pageChange}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          {loaging ? (
            <div className="text-center py-4">
              <img src="/assets/img/loader.gif" className="pageLoader" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Layout2>
  );
};

export default Html;
