import React, { useEffect, useRef, useState } from "react";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import Html from "./html";
import "./style.scss";

const ImageUpload = ({
  idd,
  model,
  result,
  value,
  multiple,
  required,
  err,
  style,
  type='doc'
}) => {
  const inputElement = useRef();
  const [img, setImg] = useState(multiple ? [] : "");
  const [loader1, setLoader1] = useState(false);
  const uploadImage = async (e, id) => {
    let files = e.target.files;
    let images = [];
    if (img) images = img;
    setLoader1(true);
    loader(true)
    const res = await ApiClient.multiUpload(
      "upload/document/multiple",
      files,{modelName:'users'}
    );
    if (res?.data?.imagePath?.length) {
      let image = res.data?.imagePath;
      if (!multiple) {
        setImg(image[0]);
        result({ event: "value", value: image[0], id: id });
      } else {
        images=[...images,...image]
        result({ event: "value", value: images, id: id });
      }
      //  e.target.setValue('')
    }
    document.getElementById(idd).value=""
    setLoader1(false);
    loader(false)
  };

  const remove = (index) => {
  
    if (index == undefined) {
      setImg("");
    }
    let updatedImages = [...img];
    updatedImages.splice(index, 1);

    setImg(updatedImages);

    result({ event: "remove", value: multiple?updatedImages:'', id: index });
  };

  useEffect(() => {
    setImg(multiple ? value || [] : value || "");
  }, [value, multiple]);

  return (
    <>
      <Html
        idd={idd}
        style={style}
        multiple={multiple}
        inputElement={inputElement}
        uploadImage={uploadImage}
        img={img}
        model={model}
        required={required}
        loader1={loader1}
        // loader={loader}
        type={type}
        err={err}
        remove={remove}
      />
    </>
  );
};
export default ImageUpload;
