import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login_success } from "../../actions/user";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { Link } from "react-router-dom";
import "./style.scss";
import methodModel from "../../methods/methods";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import axios from "axios";
import environment from "../../environment";
import { requestForToken } from "../../firebase/configuration";

const Login = () => {
  const history = useHistory();
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const Websitedetails = useSelector((state) => state.website);
  const dispatch = useDispatch();
  const [IP, setIP] = useState({});
  const [DeviceToken, setDeviceToken] = useState("");
  const [username, setUsername] = useState("");
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState("");
  let [Alert, setAlert] = useState(false);
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });

  const getIP = () => {
    axios.get("https://api.ipify.org/?format=json").then((res) => {
    
      setIP(res?.data);
    });
  };

  const requestPermission = async () => {
    await Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          requestForToken().then((token) => {
            setDeviceToken(token);
          });
        } else if (permission == "denied") {
          requestForToken().then((token) => {
            setDeviceToken(token);
          });

          // alert("You denied Notification permission.");
        }
      })
      .catch((error) => {
        console.error("Error while requesting notification permission:", error);
      });
  };

  useEffect(() => {
    getIP();
  }, []);

  useEffect(() => {
    requestPermission();
    let r = localStorage.getItem("remember");
    if (r) {
      let data = JSON.parse(r);
      setUsername(data.email);
      setPassword(data.password);
      setRemember(true);
    }

    let message = methodModel.getPrams("message");
  }, []);

  const hendleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: username,
      ip_address: IP?.ip,
      password,
    };

    loader(true);
    axios
      .post(`${environment.api}user/signin`, {
        email: username,
        ip_address: IP?.ip,
        password: password,
        device_token: DeviceToken,
      })
      .then((res1) => {

        if (res1?.data?.success) {
          if (remember) {
            localStorage.setItem("remember", JSON.stringify(data));
          } else {
            localStorage.removeItem("remember");
          }
          localStorage.setItem("token", res1?.data?.data?.access_token);
          dispatch(login_success(res1?.data?.data));
          const newdata = res1.data?.data;
          if(newdata?.request_status=="rejected")
          {
            history.push("/profile");
          }
          else
          {
            ApiClient.get("trucks").then((res) => {      
              if (res?.data?.data?.length == 0) {
                localStorage.setItem("newuser", true)
                history.push("/trucks/add");
              } else {
                history.push("/dashboard");
              }
            });
           
          }
          // toast.success(res1.message);
      
        
        }
        loader(false);
      })
      .catch((err) => {
     
        if (
          err?.response?.data?.error?.message ==
          "You have not verified your account. Please verify"
        ) {
          Swal.fire({
            icon: "Warn",
            imageUrl: "assets/img/blue-tick.png",
            title: "Great !",
            text: "Your account is Under Verification Process. Once, it's verified, you can log in.",
            customClass: {
              modal: "my-custom-modal-class",
            },
          });
        } else {
          toast.error(err?.response?.data?.error?.message);
        }
        loader(false);
      });
    // ApiClient.post("user/signin", data).then((res) => {
    //   loader(false);
    //   if (res.success) {
    //     if (remember) {
    //       localStorage.setItem("remember", JSON.stringify(data));
    //     } else {
    //       localStorage.removeItem("remember");
    //     }
    //     toast.success(res.message);
    //     localStorage.setItem("token", res.data.access_token);
    //     dispatch(login_success(res.data));
    //     const newdata = res.data;
    //     history.push("/dashboard");
    //   } else {

    //     if (res?.error?.message == 'You have not verified your account. Please verify') {
    //       // setAlert(true)
    //       // Swal.fire({
    //       //   icon: "error",
    //       //   title: "Oops...",
    //       //   text: "Your account is Under Verification Process. Once, it get verified, you can log in",
    //       //   // footer: '<a href="#">Why do I have this issue?</a>'
    //       // });
    //     }
    //   }
    // });
  };

  useEffect(() => {
    if (id) {
      loader(true);
      localStorage.clear();
      ApiClient.post("user/auto-login", { id: id }).then((response) => {
        if (response.success) {
          dispatch(login_success(response.data));
          localStorage.setItem("token", response.data.access_token);
          toast.success(response.message);
          const newdata = response.data;
          ApiClient.get("trucks").then((res) => {
            if (res?.data?.data?.length == 0) {
              history.push("/trucks/add");
            } else {
              history.push("/profile");
            }
          });
        }
        loader(true);
      });
    }
    localStorage.removeItem("Step1");
    localStorage.removeItem("Step2");
  }, []);

  return (
    <>
      <div className="bg_img main_signup">
        {/* <Link className="" to="/">
          <a class="navbar-brand logo-width" href="#">
            <img src="assets/img/logo.png" />
          </a>
        </Link> */}
        <div className=" center-img">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="right_side">
                  <Link to="/">
                    <div className="logo_image mb-3">
                      <img src="assets/img/logo-m.png" className="logo_name" />
                    </div>
                  </Link>
                  <div className="text-center mb-2">
                    <h3 className="text-cenetr lgtext">Sign in</h3>
                  </div>
                  <p className="accopunt text-center p-0 mb-3">
                    Don’t have an account?{" "}
                    <Link className="sign_up" to="/signup">
                      {" "}
                      Sign Up
                    </Link>
                  </p>

                  <form
                    autocomplete="off"
                    className="centerLogin mt-3"
                    onSubmit={hendleSubmit}
                  >
                    <div className="mb-3">
                      <label className="ml-1 mb-2">
                        {" "}
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        autocomplete="off"
                        type="email"
                        className="form-control mb-0 bginput changes"
                        placeholder="Email address"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                    </div>

                    <div className="d-flex justify-content-between">
                      <label className="ml-1 mb-2">
                        {" "}
                        Password <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="mb-0">
                      <div className="inputWrapper">
                        <input
                          autoComplete="new-password"
                          type={eyes.password ? "text" : "password"}
                          className="form-control mb-0 bginput changes"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                          required
                        />

                        <i
                          className={
                            eyes.password ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                          onClick={() =>
                            setEyes({ ...eyes, password: !eyes.password })
                          }
                        ></i>
                      </div>
                    </div>

                    <div className="mt-2 d-flex justify-content-between">
                      <div className=" d-flex align-items-baseline">
                        <input
                          type="checkbox"
                          className="mr-1"
                          checked={remember}
                          onChange={(e) => setRemember(e.target.checked)}
                        />
                        <label className="clickBox ms-1 mb-0">
                          Remember me
                        </label>
                      </div>
                      <div className="forget text-right">
                        <Link to="/forgotpassword" className="">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>

                    <div className="mt-0">
                      <button type="submit" className="btn dark-btn my-4 mb-3">
                        Sign in
                      </button>
                    </div>
                    {/* <div className='borderCls mt-5'><span className='or'>or</span></div>
                <div className='text-center d-flex justify-content-center mt-5'>
                <a className='btn btn-outline google_id mr-3 '>
                  <img src='/assets/img/gogle.png' />
                  <span className='google_heading'></span>
                </a>
                <a className='btn btn-outline google_id'>
                  <img src='/assets/img/facebooklogo.png' />
                  <span className='google_heading'></span>
                </a>
                </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
