import { useState } from "react"

const ReadMore=({content='',length=5})=>{
    const [seeAll,setSeeAll]=useState(false)
    return <>
    {seeAll?content:content.slice(0,length)}
    {content.length>length?<>
    <span className="text-primary see-more" onClick={()=>setSeeAll(!seeAll)}>{seeAll?'...see less':'...see more'}</span>
    </>:<></>}
    </>
}
export default ReadMore