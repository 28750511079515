import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import methodModel from '../../../../methods/methods';
import Sidebar from '../../sidebar';
import { useSelector } from "react-redux";
import Img from '../../../../assets/imgpsh_fullsize_anim (1).jpeg'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LuLogOut } from "react-icons/lu";
import { LuLock } from "react-icons/lu";
import { RiUser6Line } from "react-icons/ri";
import { RiHomeLine } from "react-icons/ri";
import { BsBriefcase } from "react-icons/bs";
import ApiClient from "../../../../methods/api/apiClient";
import loader from "../../../../methods/loader";
import environment from "../../../../environment";
import moment from "moment";
import { toast } from "react-toastify";

const Html = ({ isOpen, toggle, searchHandle, search, user, isOpen1, searchChange, clear, Logout }) => {
  const pathname = location.pathname;
  const history = useHistory()
  const [List, setList] = useState([])
  const [total, settotal] = useState(0)

  const GetNotification = () => {
    ApiClient.get("notification/all", {
      send_to: user?.id,
      status: "unread",
    }).then((res) => {
      if (res.success) {
        settotal(res?.data?.total_count)
        setList(res?.data?.data);
      }
    });
  };
  useEffect(() => {
    if (user && user?.id) GetNotification();

  }, [user]);
  const UpdateNotificationStatus = (id, status) => {
    loader(true);
    ApiClient.put("notification/change-status", {
      id: id,
      status: status,
    }).then((res) => {
      if (res.success) {
        // toast.success(res?.message);
        loader(false);
        GetNotification();

      }
      loader(false);
      GetNotification();
    });
  };
  const UpdateNotificationStatusAll = (id, status) => {
    loader(true);
    ApiClient.put("notification/change-status-all", {
      status: 'read',
    }).then((res) => {
      if (res.success) {
        // toast.success(res?.message);
        loader(false);
        GetNotification();

      }
      loader(false);
      GetNotification();
    });
  };
  const websitedetails = useSelector(state => state.WebsiteDetails);
  return (
    <nav
      component="header"
      className={
        isOpen
          ? 'navbar navbar-expand-lg main-navbar min-sidebar nowrapflex navfix'
          : 'navbar navbar-expand-lg main-navbar nowrapflex navfix'
      }
    >



      {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}
      <div className="logo-width logo_box pointer">
        <img onClick={() => {
          history.push('/')
        }} src={Img} alt="" />
      </div>

      <a
        onClick={toggle}
        className={
          isOpen
            ? 'btn barlink text-primary active'
            : 'btn barlink  text-primary'
        }
      >
        <i className="fas fa-bars" />
      </a>

      <div className="d-flex justify-content-end w-100 align-items-center main-head">
        {pathname == "/staff" || pathname == "/carriers" || pathname == '/carrier-requests' || pathname == '/bids' || pathname == "/carrier/requests" || pathname == "/carriers" || pathname == "/skills" || pathname == "/industry" || pathname == "/masterskils" || pathname == "/questions" || pathname == "/assessment" || pathname == "/faq" || pathname == "/categorylist" || pathname == "/projects" || pathname == "/technologies" || pathname == "/speciality" || pathname == "/recruiters" || pathname.includes("/Reqrooter") || pathname.includes("/content") || pathname.includes("/loads") ||
          pathname.includes("/boards") ?
          <form className='headerSearch ml-3' onSubmit={searchHandle}>
            <input type="text" placeholder="Search..." value={search} onChange={e => searchChange(e.target.value)} className="Searchbar"></input>
            <i className="fa fa-search" onClick={searchHandle} aria-hidden="true"></i>
            {search ? <i className="fa fa-times" onClick={clear} aria-hidden="true"></i> : <></>}
          </form> : null}
        <div class="dropdown">
          <span className="notcount">{total}</span>
          <a
            id="dLabel"
            role="button"
            data-bs-toggle="dropdown"
            data-target="#"
            href="/page.html"
            onClick={() => {
              GetNotification()
            }}
          >
            <i class="fa fa-bell"></i>
          </a>

          <ul
            class="dropdown-menu notifications notifilayout"
            role="menu"
            aria-labelledby="dLabel"
          >
            <div class="notification-heading">
              <h4 class="menu-title">Notifications</h4>
              {/* <h4 class="menu-title pull-right">
                      View all
                      <i class="glyphicon glyphicon-circle-arrow-right"></i>
                    </h4> */}
              {
                List?.length > 0 && <button onClick={() => {
                  UpdateNotificationStatusAll('read')
                }} className="btn btn-primary">Clear All</button>
              }
            </div>
            <li class="divider"></li>
            <div class="notifications-wrapper">
              {List?.length > 0 ? (
                List?.map((itm, index) => {
                  return (
                    <a class="content">
                      <div
                        class="notification-item"
                        onClick={() => {
                          UpdateNotificationStatus(itm?.id, "read");
                          history.push(`/bidDetail/${itm?.bid_id}`);
                        }}
                      >

                        <div className="d-flex align-items-center gap-2">      <img
                          className="noteimg"
                          src={`${environment.api}/images/users/${itm?.addedBy_image}`}
                          alt=""
                        />

                          {/* <p class="item-info d-flex m-0 align-items-center"><span class="item-title">{itm?.title} </span> <span className="notmess">{itm?.message}</span> </p> */}
                          <p class="m-0 messagep pl-1">  {itm?.message} </p>


                        </div>    <p className="text-end m-0 nottime">
                          {moment(itm?.createdAt).format(
                            "DD MMM YYYY h:m A"
                          )}
                        </p>
                      </div>
                    </a>
                  );
                })
              ) : (
                <a class="content">
                  <div class="pt-3">
                    <h6 >No Notifications</h6>
                  </div>
                </a>
              )}
            </div>
            <li class="divider"></li>
          </ul>
        </div>




        <div className="dropdown profile-dropDOwn secdrop">

          <div>
            <a data-bs-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-user text-dark d-flex align-items-center" >


              <div className="me-1 username-head ">
                <b>{methodModel.capitalizeFirstLetter(user.fullName)}</b>
                <p className=" mb-0 text-capitalize">{user.role}</p>
              </div>
              <img alt="image" src={methodModel.userImg(user.image)} className="rounded-circle ms-1 " />
            </a>
            <div className="dropdown-menu dropdown-menu-right position-absolute shadow bg_hover up_it">
              <Link to="/" className="dropdown-item has-icon">
                <RiHomeLine className="me-2" />
                Home
              </Link>
              <Link to="/profile1" className="dropdown-item has-icon">
                <RiUser6Line className="me-2" /> Profile
              </Link>
              <Link to="/jobs1" className="dropdown-item has-icon">
                <BsBriefcase className="me-2" /> Loads
              </Link>
              <Link to="/profile1" className="dropdown-item has-icon">
                <LuLock className="me-2" /> Change Password
              </Link>
              <a id="handleLogout" onClick={() => Logout()} className="dropdown-item has-icon">
                <LuLogOut className="me-2" /> Logout
              </a>
            </div>
          </div>
        </div>
      </div>

      {
        isOpen1 ? (
          <div className="w-100 mobi-dropdown">
            <Sidebar />
          </div>
        ) : (
          <></>
        )
      }
    </nav>
  );
}

export default Html