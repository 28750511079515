import Layout2 from '../../components/global/Layout2';
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import environment from '../../environment';
import moment from 'moment';

export default function Notification2() {
  const user = useSelector((state) => state.user);
  const pathname = location.pathname;
  const history = useHistory();
  // const [List, setList] = useState([]);
  const [AwardedList, setAwardedList] = useState([]);
  const [RejectedList, setRejectedList] = useState([]);
  const [AcceptedList, setAcceptedList] = useState([]);
  const [total, settotal] = useState(0);

  const GetNotification = () => {
    ApiClient.get('notification/all', {
      send_to: user?.id,
      status: 'unread',
    }).then((res) => {
      if (res.success) {
        settotal(res?.data?.total_count);
        setAwardedList(res?.data?.data.filter((itm) => itm.bid_status == "awarded"))
        setRejectedList(res?.data?.data.filter((itm) => itm.bid_status == "rejected"))
        setAcceptedList(res?.data?.data.filter((itm) => itm.bid_status == "accepted"))
      }
    });
  };

  useEffect(() => {
    if (user && user?.id) GetNotification();
  }, [user]);
  const UpdateNotificationStatus = (id, status) => {
    loader(true);
    ApiClient.put('notification/change-status', {
      id: id,
      status: status,
    }).then((res) => {
      if (res.success) {
        // toast.success(res?.message);
        loader(false);
        GetNotification();
      }
      loader(false);
      GetNotification();
    });
  };
  const UpdateNotificationStatusAll = (id, status) => {
    loader(true);
    ApiClient.put('notification/change-status-all', {
      status: 'read',
    }).then((res) => {
      if (res.success) {
        // toast.success(res?.message);
        loader(false);
        GetNotification();
      }
      loader(false);
      GetNotification();
    });
  };
  const websitedetails = useSelector((state) => state.WebsiteDetails);

  return (
    <>
      <Layout2 title2='Notifications'>
        <div className="mainareas">
          <div className="row">
            <div className="col-lg-7 mx-auto">
              <div className="notification-main  ">
                <div className="notify-upper">
                  <div className="notify-img">
                    <img src="assets/img/notify.png" />

                  </div>
                </div>
                <div className="notify-bottom">
                  {AwardedList?.length ==0 && AcceptedList?.length == 0 && RejectedList?.length == 0 ?
                  <div className=" no-data notify-no-data">
                 No Notifications
                </div>
                  :
                  <> {AwardedList?.length > 0 ?
                    <div class="notifications-wrapper">

                      <h4>Awarded</h4>
                      {
                        AwardedList?.map((itm, index) => {
                          return (
                            <a class="content">
                              <div
                                class="notification-item"

                              >
                                <div className="d-flex align-items-center gap-2 justify-content-between inner-notify">
                                  {' '}
                                  <div className='d-flex align-items-center'>
                                    <img
                                      className="noteimg me-2"
                                      src={`${environment.api}/images/users/${itm?.addedBy_image}`}
                                      alt=""
                                    />
                                    {/* <p class="item-info d-flex m-0 align-items-center"><span class="item-title">{itm?.title} </span> <span className="notmess">{itm?.message}</span> </p> */}
                                    <p class="m-0 messagep pl-1 pointer"
                                      onClick={() => {
                                        UpdateNotificationStatus(itm?.id, 'read');
                                        history.push(`/bids/Detail/${itm?.bid_id}`);
                                      }}>

                                      {itm?.message}{' '}
                                    </p>
                                  </div>
                                  <p className="text-end m-0 nottime">
                                    {moment(itm?.createdAt).format(
                                      'DD MMM YYYY h:mm A'
                                    )}
                                  </p>
                                </div>
                              </div>
                            </a>
                          );
                        })
                        // ) : (
                        //   <a class="content">
                        //     <div class="pt-3">
                        //       <h6 className='text-center'>No Notifications</h6>
                        //     </div>
                        //   </a>
                        // )
                      }
                    </div>
                    : <></>
                  }
                  {AcceptedList?.length > 0 ?
                    <div class="notifications-wrapper">
                      <h4>Accepted</h4>
                      {AcceptedList?.map((itm, index) => {
                        return (
                          <a class="content">
                            <div
                              class="notification-item"

                            >
                              <div className="d-flex align-items-center gap-2 justify-content-between inner-notify">
                                {' '}
                                <div className='d-flex align-items-center'>
                                  <img
                                    className="noteimg me-2"
                                    src={`${environment.api}/images/users/${itm?.addedBy_image}`}
                                    alt=""
                                  />
                                  {/* <p class="item-info d-flex m-0 align-items-center"><span class="item-title">{itm?.title} </span> <span className="notmess">{itm?.message}</span> </p> */}
                                  <p class="m-0 messagep pl-1 pointer"
                                    onClick={() => {
                                      UpdateNotificationStatus(itm?.id, 'read');
                                      history.push(`/bids/Detail/${itm?.bid_id}`);
                                    }}>

                                    {itm?.message}{' '}
                                  </p>
                                </div>
                                <p className="text-end m-0 nottime">
                                  {moment(itm?.createdAt).format(
                                    'DD MMM YYYY h:mm A'
                                  )}
                                </p>
                              </div>
                            </div>
                          </a>
                        );
                      })
                      }

                    </div>
                    : <></>
                  }
                  {RejectedList?.length > 0 ?
                    <div class="notifications-wrapper">
                      <h4>Rejected</h4>
                      {
                        RejectedList?.map((itm, index) => {
                          return (
                            <a class="content">
                              <div
                                class="notification-item"

                              >
                                <div className="d-flex align-items-center gap-2 justify-content-between inner-notify">
                                  {' '}
                                  <div className='d-flex align-items-center'>
                                    <img
                                      className="noteimg me-2"
                                      src={`${environment.api}/images/users/${itm?.addedBy_image}`}
                                      alt=""
                                    />
                                    {/* <p class="item-info d-flex m-0 align-items-center"><span class="item-title">{itm?.title} </span> <span className="notmess">{itm?.message}</span> </p> */}
                                    <p class="m-0 messagep pl-1 pointer"
                                      onClick={() => {
                                        UpdateNotificationStatus(itm?.id, 'read');
                                        history.push(`/bids/Detail/${itm?.bid_id}`);
                                      }}>

                                      {itm?.message}{' '}
                                    </p>
                                  </div>
                                  <p className="text-end m-0 nottime">
                                    {moment(itm?.createdAt).format(
                                      'DD MMM YYYY h:mm A'
                                    )}
                                  </p>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      }
                    </div>
                    : <></>
                  }</>
                  }
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout2>
    </>
  );
}
