import React from "react";
import WebsiteHeader from "../../components/global/WebsiteHeader";

function OurServices() {
  return (
    <>
      <WebsiteHeader />
      <div className="page_wrapper mb-0">
        <div className="about_hero">
          <div className="container d-flex justify-content-center align-items-center h-100">
            <h4 className="about_header">About Us</h4>
          </div>
        </div>
        {/* <div className="serhero">
        <img src="/assets/img/ser.jpg" class="ser_img"/>
        <div className="container d-flex justify-content-center align-items-center h-100 ">
        <div class="about_parentt"> <h3 class="about_headingg">Our Services</h3></div>
        </div>
        </div> */}
        {/* <div className="about_ser">
          <div className="container d-flex justify-content-center align-items-center h-100">
            <h4 className="about_header">Our Services</h4>
          </div>
        </div> */}
        <div className="container my-5">
          <div className="mt-5">
            <h4 className="servicespera1">
              Welcome to One Brokerage LLC, your premier partner in asset-based
              logistics and transportation services. With a robust fleet of over
              800 trucks and a vast array of trailers, we provide flexible and
              reliable freight solutions across the nation.
            </h4>

            <div className="row my-5 reversecol">
              <div className="col-md-6 my-md-5 my-3 soloflex">
                <div className="">
                  {" "}
                  <h6 class="story_header serivcesh">
                    Solo and Team Transport:
                  </h6>
                  <p className="story_desc serivcesp serivcesps">
                    Whether you need a dedicated solo driver or a team for
                    faster delivery, we have the resources to meet your
                    requirements.
                  </p>
                </div>
              </div>
              <div className="col-md-6 my-md-5 my-3 text-end">
                <div>
                  <img
                    src="/assets/img/team.jpg"
                    alt=""
                    className="serviceimges"
                  />
                </div>
              </div>
            </div>

            <div className="row my-5 ">
              <div className="col-md-6 my-md-5 my-3">
                <div>
                  <img
                    src="/assets/img/expited.jpg"
                    alt=""
                    className="serviceimges"
                  />
                </div>
              </div>
              <div className="col-md-6 my-md-5 my-3 soloflex">
                <div><h6 class="story_header serivcesh"> Expedited Services:</h6>
                  <p className="story_desc serivcesp serivcesps">
                    For time-sensitive shipments, our expedited transport services
                    ensure your freight arrives when needed.
                  </p></div>
              </div>
            </div>
            <div className="row my-5 reversecol">
              <div className="col-md-6 my-md-5 my-3 soloflex">
                <div>   <h6 class="story_header serivcesh">
                  Freight Management and Forwarding:
                </h6>
                  <p className="story_desc serivcesp serivcesps">
                    Our expertise extends to managing complex logistics
                    operations, ensuring efficient freight movement across
                    multiple channels.
                  </p></div>
              </div>
              <div className="col-md-6 my-md-5 my-3 text-end">
                <div>
                  <img
                    src="/assets/img/whitegb.jpg"
                    alt=""
                    className="serviceimges"
                  />
                </div>
              </div>
            </div>
            <div className="row my-5 ">
              <div className="col-md-6 my-md-5 my-3">
                <div>
                  <img
                    src="/assets/img/whitegb.jpg"
                    alt=""
                    className="serviceimges"
                  />
                </div>
              </div>

              <div className="col-md-6 my-md-5 my-3 soloflex">
                <div>   <h6 class="story_header serivcesh">White Glove Services:</h6>
                  <p className="story_desc serivcesp serivcesps">
                    We go beyond traditional delivery with our white glove
                    services, offering end-to-end handling of delicate and
                    high-value shipments with the utmost care.
                  </p></div>
              </div>
            </div>
            <div className="row my-5 reversecol ">
              <div className="col-md-6 my-md-5 my-3 soloflex">
                <div>   <h6 class="story_header serivcesh">Consulting Services:</h6>
                  <p className="story_desc serivcesp serivcesps">
                    We provide expert consulting on freight management facility
                    optimization, helping you streamline operations and increase
                    efficiency.{" "}
                  </p></div>
              </div>
              <div className="col-md-6 my-md-5 my-3 text-end">
                <div>
                  <img
                    src="/assets/img/consult.jpg"
                    alt=""
                    className="serviceimges"
                  />
                </div>
              </div>
            </div>
            <div className="row my-5 ">
              <div className="col-md-6 my-md-5 my-3">
                <div>
                  <img
                    src="/assets/img/commit.jpg"
                    alt=""
                    className="serviceimges"
                  />
                </div>
              </div>
              <div className="col-md-6 my-md-5 my-3 soloflex">
                <div>    <h6 class="story_header serivcesh">Our Commitment:</h6>
                  <p className="story_desc serivcesp serivcesps">
                    Our direct access to a large fleet not only allows us to offer
                    competitive rates but also ensures the highest level of
                    service. We prioritize transparency and communication,
                    providing live tracking for all shipments and 24/7 support.
                    Each client benefits from a dedicated customer representative,
                    ensuring personalized service and rapid response to any need.
                    At One Brokerage LLC, we are more than just a logistics
                    provider. We are your strategic partner in driving operational
                    excellence and supporting the growth of your business. Trust
                    us to deliver not just your cargo, but also peace of mind and
                    logistical brilliance.
                  </p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer_section section-padding">
        <div class="container">
          <div class="row ">
            <div class="col-12 col-sm-12 col-md-12  col-lg-8">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-5 col-lg-5">
                  <div class="footer_space">
                    <img
                      src="assets/img/footer_logo.png"
                      class="img-fluid"
                      alt="logo"
                    />
                    <p class="footer_details">
                      Your trusted partner in transportation, delivering results
                      that move businesses forward.
                    </p>
                    <ul class="footer_iconlist">
                      <li>
                        <a href="#">
                          <div class="footer_img ">
                            <img
                              src="assets/img/youtube.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="assets/img/twitter.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="assets/img/insta.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="assets/img/facebook.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-7">
                  <div class="row">
                    <div class="col-12 col-sm-5 col-md-5 col-lg-5">
                      <div class="footer_space">
                        <h5 class="footer_heading">Quick Links</h5>

                        <ul class="footer_items">
                          <li>
                            <a href="#"> About Us</a>
                          </li>

                          <li>
                            <a href="/support"> Support</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class=" col-12 col-sm-7 col-md-7 col-lg-7">
                      <div class="footer_space">
                        <h5 class="footer_heading">Contact Us</h5>

                        <ul class="footer_items">
                          <li>
                            <div class="footer_list">
                              <img
                                src="assets/img/message.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">hello@website.com</p>
                            </div>
                          </li>
                          <li>
                            <div class="footer_list">
                              <img
                                src="assets/img/map.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">
                                Riverside Building, County Hall, London SE1 7PB,
                                United Kingdom
                              </p>
                            </div>
                          </li>
                          <li>
                            <div class="footer_list">
                              <img
                                src="assets/img/call.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">+15055221111</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="col-12 col-sm-12 col-md-12 col-lg-4">
              <div class="footer_space">
                <h5 class="footer_heading">Newsletter</h5>
                <div class="position-relative">

                  <form onSubmit={LetSubscribe}>
                    <input
                      type="email"
                      value={form?.email}
                      required
                      onChange={(e) =>
                        setform({ ...form, email: e.target.value })
                      }
                      class="form-control enter_mail"
                      placeholder="Enter your email"
                    />
                    <button class="subscribe_btn">Subscribe</button>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}

export default OurServices;
