import React from "react";
import WebsiteHeader from "../../components/global/WebsiteHeader";

function Support() {
  return (
    <>
      <WebsiteHeader />
      <div className="page_wrapper mb-0">
        <div className="serhero">
          <img src="/assets/img/supp.jpg" class="ser_img" />
          <div className="container d-flex justify-content-center align-items-center h-100 ">
            <div class="about_parentt">
              {" "}
              <h3 class="about_headingg">Support at One Brokerage LLC</h3>
            </div>
          </div>
        </div>

        <div className="container my-5">
          <div className="my-5">
            <h4 className="servicespera1">
              At One Brokerage LLC, your satisfaction and success are our top
              priorities. Our support team is dedicated to providing you with
              the assistance you need, whenever you need it. Below, you’ll find
              information on common inquiries and how to resolve them quickly.
            </h4>
          </div>

          <div className="row my-5">
            <div className="col-12">
              <h3 className="trackthree"> Tracking Your Shipment</h3>
            </div>
            <div className="col-9 mb-5">
              <h6 class="story_header serivceshh">Live Tracking:  </h6>
              <p className="story_descs serivcesp">
                Access real-time updates by logging into your account on our website or using our mobile app.
              </p>
            </div>
            <div className="col-3  text-end">
              <div>
                <img
                  src="/assets/img/live-tracking.png"
                  alt=""
                  className="suppimges"
                />
              </div>
            </div>

          </div>


          <div className="row my-5">

            <div className="col-3 mb-5">
              <div>
                <img
                  src="/assets/img/seo.png"
                  alt=""
                  className="suppimges"
                />
              </div>
            </div>
            <div className="col-9 mb-5">
              <h6 class="story_header serivceshh">Shipment Details:  </h6>
              <p className="story_descs serivcesp">
                Access real-time updates by logging into your account on our website or using our mobile app.
              </p>
            </div>

          </div>


          <div className="row my-5">
            <div className="col-12">
              <h3 className="trackthree"> Billing and Payments</h3>
            </div>
            <div className="col-9 mb-5">
              <h6 class="story_header serivceshh">Invoice Questions:  </h6>
              <p className="story_descs serivcesp">
                If you have questions about your invoice or need clarification on billing details, our accounts support team is ready to assist you.
              </p>
            </div>
            <div className="col-3  text-end">
              <div>
                <img
                  src="/assets/img/invoice.png"
                  alt=""
                  className="suppimges"
                />
              </div>
            </div>

          </div>


          <div className="row my-5">
            <div className="col-3 my-5">
              <div>
                <img
                  src="/assets/img/payment-services.png"
                  alt=""
                  className="suppimges"
                />
              </div>
            </div>
            <div className="col-9 my-5">
              <h6 class="story_header serivceshh">Payment Processing:   </h6>
              <p className="story_descs serivcesp">
                For help with payment submissions or to discuss payment options, please reach out to our billing department.
                Service Modifications
              </p>
            </div>

          </div>


          <div className="row my-5">
            <div className="col-12">
              <h3 className="trackthree">Service Modifications</h3>
            </div>
            <div className="col-9 mb-5">
              <h6 class="story_header serivceshh">Change of Service:   </h6>
              <p className="story_descs serivcesp">
                Need to upgrade to expedited shipping or add white glove service? Contact us to modify your service options.

              </p>
            </div>
            <div className="col-3  text-end">
              <div>
                <img
                  src="/assets/img/customer.png"
                  alt=""
                  className="suppimges"
                />
              </div>
            </div>

          </div>


          <div className="row my-5">
            <div className="col-3 my-5">
              <div>
                <img
                  src="/assets/img/request.png"
                  alt=""
                  className="suppimges"
                />
              </div>
            </div>
            <div className="col-9 my-5">
              <h6 class="story_header serivceshh">Cancellation Requests:   </h6>
              <p className="story_descs serivcesp">
                If you need to cancel or reschedule a shipment, please get in touch as soon as possible to avoid any charges.

              </p>
            </div>

          </div>






          <div className="row my-5">
            <div className="col-12">
              <h3 className="trackthree">Technical Support</h3>
            </div>
            <div className="col-9 mb-5">
              <h6 class="story_header serivceshh">Website Issues:  </h6>
              <p className="story_descs serivcesp">
                Experiencing difficulties with our website or mobile app? Our technical support team is here to help troubleshoot and resolve issues quickly.
              </p>
            </div>
            <div className="col-3 mb-5">
              <div>
                <img
                  src="/assets/img/error.png"
                  alt=""
                  className="suppimges"
                />
              </div>
            </div>


          </div>


          <div className="row my-5">
            <div className="col-12">
              <h3 className="trackthree mb-4">Freight Management Consulting
              </h3>
            </div>

            <div className="col-3  ">
              <div>
                <img
                  src="/assets/img/secure.png"
                  alt=""
                  className="suppimges"
                />
              </div>
            </div>
            <div className="col-9 ">
              <h6 class="story_header serivceshh">Account Access: </h6>
              <p className="story_descs serivcesp">
                If you’re having trouble accessing your account or need assistance with account settings, please contact our technical support. </p>
            </div>

          </div>





          <div className="row mb-5">

            <div className="col-9">
              <h6 class="story_header serivceshh">Optimization Inquiries:  </h6>
              <p className="story_descs serivcesp">
                For advice on optimizing your freight management operations, our expert consultants are available to guide you through improvements and strategies. </p>
            </div>
            <div className="col-3  text-end">
              <div>
                <img
                  src="/assets/img/question.png"
                  alt=""
                  className="suppimges"
                />
              </div>
            </div>

          </div>






          <div className="row my-5">
            <div className="col-12">
              <h3 className="trackthree mb-4">Additional Information:
              </h3>
            </div>
            <div className="col-3 mb-5">
              <div>
                <img
                  src="/assets/img/info.png"
                  alt=""
                  className="suppimges"
                />
              </div>
            </div>
            <div className="col-9 mb-5">
              <h6 class="story_header serivceshh">Additional Information:  </h6>
              <p className="story_descs serivcesp">
                For any other questions or concerns, or to learn more about our services, our customer representatives are ready to provide you with detailed information and support.



              </p>
            </div>


          </div>

        </div>
      </div>

      <footer class="footer_section section-padding">
        <div class="container">
          <div class="row ">
            <div class="col-12 col-sm-12 col-md-12  col-lg-8">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-5 col-lg-5">
                  <div class="footer_space">
                    <img
                      src="assets/img/footer_logo.png"
                      class="img-fluid"
                      alt="logo"
                    />
                    <p class="footer_details">
                      Your trusted partner in transportation, delivering results
                      that move businesses forward.
                    </p>
                    <ul class="footer_iconlist">
                      <li>
                        <a href="#">
                          <div class="footer_img ">
                            <img
                              src="assets/img/youtube.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="assets/img/twitter.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="assets/img/insta.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="assets/img/facebook.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-7">
                  <div class="row">
                    <div class="col-12 col-sm-5 col-md-5 col-lg-5">
                      <div class="footer_space">
                        <h5 class="footer_heading">Quick Links</h5>

                        <ul class="footer_items">
                          <li>
                            <a href="#"> About Us</a>
                          </li>
                          <li>
                            <a href="#"> Service</a>
                          </li>
                          <li>
                            <a href="/support"> Support</a>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div class=" col-12 col-sm-7 col-md-7 col-lg-7">
                      <div class="footer_space">
                        <h5 class="footer_heading">Contact Us</h5>

                        <ul class="footer_items">
                          <li>
                            <div class="footer_list">
                              <img
                                src="assets/img/message.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">hello@website.com</p>
                            </div>
                          </li>
                          <li>
                            <div class="footer_list">
                              <img
                                src="assets/img/map.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">
                                Riverside Building, County Hall, London SE1 7PB,
                                United Kingdom
                              </p>
                            </div>
                          </li>
                          <li>
                            <div class="footer_list">
                              <img
                                src="assets/img/call.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">+02 5421234560</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="col-12 col-sm-12 col-md-12 col-lg-4">
              <div class="footer_space">
                <h5 class="footer_heading">Newsletter</h5>
                <div class="position-relative">

                  <form onSubmit={LetSubscribe}>
                    <input
                      type="email"
                      value={form?.email}
                      required
                      onChange={(e) =>
                        setform({ ...form, email: e.target.value })
                      }
                      class="form-control enter_mail"
                      placeholder="Enter your email"
                    />
                    <button class="subscribe_btn">Subscribe</button>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}

export default Support;
