import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/global/layout";
import "./style.scss";
import AllChart from "../../components/common/AllChart";
import { useHistory } from "react-router-dom";
import methodModel from "../../methods/methods";
import DateRangePicker from "../../components/common/DateRangePicker";
import datepipeModel from "../../models/datepipemodel";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
// import Chart from "react-google-charts";

const Dashboard = () => {
  const Navigate = useHistory();
  const user = useSelector((state) => state.user);
  const [AverageCost, setAverageCost] = useState([]);
  const [Filtername, setFIltername] = useState("");

  const [TruckPerformace, SetTruckPerformace] = useState([]);
  const [DriverPerformace, SetDriverPerformace] = useState([]);
  const [Board, setBoards] = useState([]);
  const [data, setdata] = useState([]);
  const getBaords = () => {
    ApiClient.get("boards?status=active").then((res) => {
      if (res.success) {
        setdata(res?.data?.data);
   
        const filteredBoards = res?.data?.data.filter((item) =>
          user?.board_id?.includes(item.id)
        );
        setBoards(filteredBoards);
      }
    });
  };

  const ChangeStatus = (p) => {
    getData(p);
  };

  let current = datepipeModel.datetostring(new Date());
  let startDate = `${current.split("-")[0]}-01-01`;

  let month2 = new Date(new Date().getFullYear() + 1, 0, 0);
  let endDate = datepipeModel.datetostring(month2);

  const [filters, setFilter] = useState({
    startDate: startDate,
    endDate: endDate,
    compare: "",
    compareStart: "",
    compareEnd: "",
    type: "monthly",
  });
  const [bidChart, setBidChart] = useState([]);
  const [loadChart, setLoadChart] = useState([]);
  const [cartData, setChartData] = useState({
    totalBids: 0,
    totalLoads: 0,
  });

  const [bidData, setBidData] = useState();

  const getData = (p = {}) => {
    getAnalytic(p);
    getBids(p);
  };

  const getAnalytic = (p = {}) => {
    let f = { ...filters, ...p, carrier_id: user.id };
    loader(true);
    ApiClient.get("analytics", f).then((res) => {
      if (res.success) {
        let totalBids = 0;

        let data = res.BidsCounts.response.map((itm) => {
          
          let month = `${datepipeModel.monthfind(itm.month - 1)}`;
          itm.date = `${month} ${itm.year}`;
          if (f.type == "daily") itm.date = `${itm.day} ${month}`;
          totalBids += itm.totalBidsCounts;
          return itm;
        });
     
        let totalLoads = 0;
        let ldata = res.LoadsCounts.response.map((itm) => {
          let month = `${datepipeModel.monthfind(itm.month - 1)}`;
          itm.date = `${month} ${itm.year}`;
          if (f.type == "daily") itm.date = `${itm.day} ${month}`;
          totalLoads += itm.totalBidsCounts;
          return itm;
        });

        setChartData({
          totalBids: totalBids,
          totalLoads: totalLoads,
        });
        setBidChart(data);
        setLoadChart(ldata);
      }
      loader(false);
    });
  };

  const getBids = (p = {}) => {
    let f = { ...filters, ...p };
    ApiClient.get("carrier/bid", f).then((res) => {
      if (res.success) {
        setBidData(res);
      }
    });
  };

  const filter = (p = {}) => {
    setFilter({ ...filters, ...p });
    getData(p);
  };

  const onRangeChange = (e) => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
      compare: e.compare,
      compareStart: e.compareStart,
      compareEnd: e.compareEnd,
      type: e.type,
    };
    if (
      datepipeModel.datetostring(e.startDate) ==
      datepipeModel.datetostring(e.endDate)
    ) {
      setFilter({ ...filters, ...f });
    } else {
      filter({ ...f });
    }
  };

  const GetAverage = [
    ["Origin Address", "Total", "Avg Cost ($)"],
    ...((AverageCost?.length > 0 &&
      AverageCost?.map((item) => {
        
        return [
          `${item?.address}`,
          item?.totalLoad,
          Math.round(item?.avg_cost),
        ];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const GetAverageCost = () => {
    let array = [];
    ApiClient.get("total-load/board").then((res) => {
   
      if (res.success) {
        // setFrequency(res?.data);
        let croped = res?.data?.splice(0, 8);
        croped?.map((itm) => {
          array.push({
            totalLoad: itm?.count,
            address: itm?.origin_address,
            avg_cost: itm?.avg_cost,
          });
        });
      }

      setAverageCost(array);
    });
  };

  const TruckPerformaceArray = [
    ["Origin Address", "Total", "Avg Cost ($)"],
    ...((TruckPerformace?.length > 0 &&
      TruckPerformace?.map((item) => {
        
        return [
          `${item?.address}`,
          item?.totalLoad,
          Math.round(item?.avg_cost),
        ];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const GetTruckPerformace = () => {
    let array = [];
    ApiClient.get("admin/dashboard/lane-frequency/cost-analysis").then(
      (res) => {
 
        if (res.success) {
          // setFrequency(res?.data);
          let croped = res?.data?.splice(0, 8);
          croped?.map((itm) => {
            array.push({
              totalLoad: itm?.count,
              address: itm?.origin_address,
              avg_cost: itm?.avg_cost,
            });
          });
        }

        SetTruckPerformace(array);
      }
    );
  };

  const DriverPerformaceArray = [
    ["Origin Address", "Total", "Avg Cost ($)"],
    ...((DriverPerformace?.length > 0 &&
      DriverPerformace?.map((item) => {
   
        return [
          `${item?.address}`,
          item?.totalLoad,
          Math.round(item?.avg_cost),
        ];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const GetDriverPerformace = () => {
    let array = [];
    ApiClient.get("admin/dashboard/lane-frequency/cost-analysis").then(
      (res) => {
     
        if (res.success) {
          // setFrequency(res?.data);
          let croped = res?.data?.splice(0, 8);
          croped?.map((itm) => {
            array.push({
              totalLoad: itm?.count,
              address: itm?.origin_address,
              avg_cost: itm?.avg_cost,
            });
          });
        }

        SetTruckPerformace(array);
      }
    );
  };
  useEffect(() => {
    if (user.id) {
      getData();
      GetAverageCost();
      getBaords();
    }
  }, [user]);

  return (
    <Layout>
      <div className="row d-flex align-items-center">
        <div className="col-md-9 head_cls">
          <h2 className="mb-1 mainHeading_cls">
            {" "}
            <span className="dsh_hedding">Hi</span>
            {user && methodModel.capitalizeFirstLetter(user?.fullName)}
          </h2>
          <p className="paraCls">
            Here’s what’s going on with your team Designspace
          </p>
        </div>
        <div>
          <div className="d-flex gap-2">
            <div className="ms-auto d-flex align-items-center">
              <div className="dropdown addDropdown">
                <button
                  className="btn btn-primary dropdown-toggle "
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {Filtername
                    ? Filtername == ""
                      ? "All"
                      : methodModel.capitalizeFirstLetter(Filtername)
                    : "All"}
                </button>
                <div
                  className="dropdown-menu shadow bg_hover"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    className={
                      Filtername == ""
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                    onClick={() => {
                      setFIltername("All");

                      ChangeStatus({ board_id: "" });
                    }}
                  >
                    All
                  </a>
                  {Board &&
                    Board?.map((itm) => {
                      return (
                        <a
                          className={
                            filters.board_id == itm?.id
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          onClick={() => {
                            setFIltername(itm?.name);
                            ChangeStatus({ board_id: itm?.id });
                          }}
                        >
                          {methodModel.capitalizeFirstLetter(itm?.name)}
                        </a>
                      );
                    })}
                </div>
              </div>
              <DateRangePicker
                value={{
                  startDate: filters.startDate,
                  endDate: filters.endDate,
                  compare: filters.compare,
                  compareStart: filters.compareStart,
                  compareEnd: filters.compareEnd,
                }}
                onChange={(e) => onRangeChange(e)}
                fullWidth={false}
              />
            </div>
          </div>
        </div>
        <div className="cards_sales">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
              <div className="sales_section">
                <div
                  className="main_sales"
                  onClick={() => {
                    Navigate.push("/awarded-bids");
                    // localStorage.setItem("BidStatus", "accepted");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Awarded Bids</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">{bidData?.awarded_bids}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
              <div className="sales_section">
                <div
                  className="main_sales"
                  onClick={() => {
                    Navigate.push("/accepted-bid");
                    // localStorage.setItem("BidStatus", "accepted");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Accepted Bids</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">{bidData?.accepted_bids}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
              <div className="sales_section">
                <div
                  className="main_sales"
                  onClick={() => {
                    Navigate.push("/rejected-bids");
                    // localStorage.setItem("BidStatus", "rejected");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Rejected Bids</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">{bidData?.rejected_bids}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
              <div className="sales_section">
                <div
                  className="main_sales"
                  onClick={() => {
                    Navigate.push("/pending-bids");
                    // localStorage.setItem("BidStatus", "pending");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Pending Bids</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">{bidData?.pending_bids}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* graph Design */}

          <div className="row">
            <div className="col-12 col-sm-12 cls">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Bids</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/bid`)}
                    >
                      View Bids
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div className="">
                  <AllChart
                    data={bidChart}
                    legends={[{ label: "Total Bids", key: "totalBidsCounts" }]}
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-5">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">
                      Performance Metric of Carrier
                    </h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/jobs`)}
                    >
                      View Jobs
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={GetAverage}
                    options={{
                      colors: ["#febf01", "#a5c956"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-5">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">
                      Performance Metric of Trucks
                    </h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/jobs`)}
                    >
                      View Jobs
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                 
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={TruckPerformaceArray}
                    options={{
                      colors: ["#febf01", "#a5c956"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                 
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-5">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">
                      Performance Metric of Drivers
                    </h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/jobs`)}
                    >
                      View Jobs
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                 
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={DriverPerformaceArray}
                    options={{
                      colors: ["#febf01", "#a5c956"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                  
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
