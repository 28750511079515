import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import rolesModel from "../../models/roles.model";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import environment from "../../environment";
import { useHistory } from "react-router-dom";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import ApiClient from "../../methods/api/apiClient";
import { Tooltip } from "antd";
import { BsThreeDots } from "react-icons/bs";
const Html = ({
  view,
  addressResult,
  edit,
  user,
  ChangeFilter,
  deleteLoad,
  reset,
  sorting,
  add,
  colClick,
  tab,
  tabChange,
  ChangeRole,
  ChangeStatus,
  openModal,
  statusChange,
  pageChange,
  addCol,
  deleteItem,
  exportCsv,
  uTableCols,
  removeCol,
  filters,
  ChangeDocumentStatus,
  tableCols,
  setFilter,
  blockunblock,
  loaging,
  getData,
  data,
  exportfun,
  roles,
  role,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  total = { total },
}) => {
  const Navigate = useHistory();
  const [Min_rate, setMin_rate] = useState("");
  const [Max_rate, setMax_rate] = useState("");
  const [DeleteId, setDeleteId] = useState("");
  const latestSliderValue = React.useRef([0, 0]);

  const debouncedHandleSliderChange = debounce((newValues) => {
    const [min, max] = newValues;
    setMin_rate(min);
    setMax_rate(max);
    // console.log("Filter changed. Calling GetAllprojects...");
    getData({ min_rate: min, max_rate: max });
  }, 500);

  const handleSliderChange = (newValues) => {
    if (
      JSON.stringify(newValues) === JSON.stringify(latestSliderValue.current)
    ) {
      return;
    }
    latestSliderValue.current = newValues;
    debouncedHandleSliderChange(newValues);
  };

  const Delete = () => {
    deleteItem(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };

  const setPriceFilter = () => {
    setFilter({ ...filters, min_rate: Min_rate, max_rate: Max_rate });
    getData({ min_rate: Min_rate, max_rate: Max_rate });
  };

  useEffect(() => {
    setMin_rate(0);
    setMax_rate(4000);
  }, []);

  const Permission = JSON.parse(localStorage.getItem("permission"));
  const Role = [
    {
      key: "staff",
      name: "Staff",
    },
    {
      key: "carrier",
      name: "Carrier",
    },
  ];
  let ListingData = [];
  if (user?.role == "staff") {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }
  return (
    <Layout>
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={StatusCh}
        status={StatusData.status}
      />
      <div className="d-flex justify-content-between align-items-center mb-4 wrapflex">
        <h3 className="hedding">{role ? rolesModel.name(role) : "Staff"}</h3>
        <div className="d-flex align-items-center flex-wrap">
          <article className="d-flex filterFlex phView wrapflex flex-wrap gap-2">
            {/* <div className="dropdown addDropdown chnagesg mr-2 d-flex align-items-center">
              <button
                className="btn blck-border-btn dropdown-toggle removeBg"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {filters.status
                  ? filters.status == "deactive"
                    ? "Inactive"
                    : filters.status
                  : "All Status"}
              </button>

              <div
                className="dropdown-menu shadow bg_hover"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className={
                    filters.status == ""
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  onClick={() => ChangeStatus("")}
                >
                  All Status
                </a>
                <a
                  className={
                    filters.status == "active"
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  onClick={() => ChangeStatus("active")}
                >
                  Active
                </a>
                <a
                  className={
                    filters.status == "Inactive"
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  onClick={() => ChangeStatus("deactive")}
                >
                  Inactive
                </a>
              </div>
            </div> */}

            <form
              onSubmit={(e) => {
                e.preventDefault();
                getData({ filters });
              }}
            >
              <input
                type="text"
                value={filters?.search}
                className="form-control ml-3"
                placeholder="Search here"
                onChange={(e) => {
                  setFilter({ ...filters, search: e.target.value });
                }}
              />
            </form>
            <div className="d-flex flex-wrap gap-2 ">
              {Permission?.carrier_add || user?.role == "admin" ? (
                <>
                  <button className="btn btn-primary   " onClick={(e) => add()}>
                    {/* <i className="fa fa-plus me-2"></i> */}
                    Add{" "}
                    {role ? rolesModel.name(role) : "Load"}
                  </button>
                </>
              ) : (
                <></>
              )}

              {filters.status || filters.role ? (
                <>
                  <a className="btn btn-primary   " onClick={(e) => reset()}>
                    Reset
                  </a>
                </>
              ) : (
                <></>
              )}
              <button className="btn btn-primary  " onClick={(e) => add()}>
                <i className="fa fa-plus me-2"></i>
                Add
              </button>
            </div>
          </article>
        </div>
      </div>

      <>
        <div className={`${total>1?"table-responsive":""} respTable table_section`}>
          <table class="table ">
            <thead className="table_head">
              <tr className="heading_row">
                <th
                  scope="col"
                  className="table_data pointer"
                  onClick={(e) =>
                    filters?.sortBy == "fullName asc"
                      ? sorting("fullName desc", "desc")
                      : sorting("fullName asc", "asc")
                  }
                >
                  {/* Carrier Name {filters?.sortBy === "fullName asc" ? "↑ A-Z" : "↓ Z-A"} */}
                  <span className="d-flex align-items-center">  Staff Name{" "}
                    {filters?.sortBy === "fullName asc"
                      ? <div class="d-flex  zfonts align-items-center"><div className="d-flex flex-column"><span className="zfont">A </span> <span className="afont">Z</span></div> <span><span class="material-icons arrfont">north</span></span></div>
                      : <div class="d-flex zfonts align-items-center"><div className="d-flex flex-column"><span className="zfont">Z </span>  <span className="afont">A</span></div><span><span class="material-icons arrfont">south</span></span></div>}
                  </span>
                </th>
                <th
                  scope="col"
                  className="table_data pointer"
                  onClick={(e) =>
                    filters?.sortBy == "email asc"
                      ? sorting("email desc", "desc")
                      : sorting("email asc", "asc")
                  }
                >
                  {/* Email {filters?.sortBy === "customer_name asc" ? "↑" : "↓"} */}
                  <span className="d-flex align-items-center">   Email{" "}
                    {filters?.sortBy === "email asc"
                      ? <div class="d-flex  zfonts align-items-center"><div className="d-flex flex-column"><span className="zfont">A </span> <span className="afont">Z</span></div> <span><span class="material-icons arrfont">north</span></span></div>
                      : <div class="d-flex zfonts align-items-center"><div className="d-flex flex-column"><span className="zfont">Z </span>  <span className="afont">A</span></div><span><span class="material-icons arrfont">south</span></span></div>}
                  </span>
                </th>

                <th
                  scope="col"
                  className="table_data pointer"
                  onClick={(e) =>
                    filters?.sortBy == "createdAt asc"
                      ? sorting("createdAt desc", "desc")
                      : sorting("createdAt asc", "asc")
                  }
                >
                  Date Created {filters?.sortBy === "createdAt asc" ? "↑" : "↓"}
                </th>

                <th scope="col" className="table_data ">
                  Status
                </th>
                <th scope="col" className="table_data ">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!loaging &&
                ListingData &&
                ListingData.map((itm, i) => {
                  return (
                    <tr className="data_row">
                      <td
                        className="table_dats cursor-pointer"
                        onClick={() => {
                          Navigate.push(`/user1/detail/${itm.id}`);
                        }}
                      >
                        {itm.fullName ? methodModel.capitalizeFirstLetter(itm?.fullName) : methodModel.capitalizeFirstLetter(itm?.firstName)}
                      </td>
                      <td className="table_dats">{itm?.email}</td>

                      <td className="table_dats">
                        {datepipeModel.date(itm.createdAt) || "--"}
                      </td>
                      {/* <td className="table_dats">
                        {itm.status || "--"}
                      </td> */}
                      <td className="table_dats">
                        {" "}
                        <div className={` ${itm.status}`}>
                          {/* {itm.status} */}

                          <span className="custom-toggle-btn heightbtn">
                            {itm.status == "deactive" ? (
                              <Switch
                                onChange={(e) => {
                                  setStatusData(itm);
                                  setShowActiveModal("block");
                                }}
                                checked={false}
                              />
                            ) : (
                              <Switch
                                onChange={(e) => {
                                  setStatusData(itm);
                                  setShowActiveModal("block");
                                }}
                                checked={true}
                              />
                            )}
                          </span>
                        </div>
                      </td>
                      <td className="table_dats">
                        <div class="dropdown dotsbtn  acttwo" >
                          <button class="btn dropdown-toggle  " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <BsThreeDots />
                          </button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item d-flex align-items-center gap-1" onClick={() => {
                              Navigate.push(`/user1/detail/${itm.id}`);
                            }}><span className="">
                                <i

                                  class="fa fa-eye"
                                >

                                </i>
                              </span> <span className="">View</span></a>
                            <a onClick={() => {
                              Navigate.push(`user1/edit/${itm?.id}`);
                            }} class="dropdown-item d-flex align-items-center gap-1"  ><span className="">
                                <i class="material-icons edit" >
                                  {" "}
                                  edit
                                </i>
                              </span>  <span className="">Edit</span></a>
                          </div>
                        </div>
                        {/* <div className="action_icons">
                          <>
                            
                          </>
                          <Tooltip placement="top" title="Edit">
                            <span
                              className="edit_icon"
                              onClick={() => {
                                Navigate.push(`user/edit/${itm?.id}`);
                              }}
                            >
                              <i class="material-icons edit" title="Edit">
                                {" "}
                                edit
                              </i>
                            </span>
                          </Tooltip>

                          <Tooltip placement="top" title="View">
                            <span
                              className="edit_icon"
                              onClick={() => {
                                Navigate.push(`/user/detail/${itm.id}`);
                              }}
                            >
                              <i className="fa fa-eye"></i>
                            </span>
                          </Tooltip>
                        </div> */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

       
      </>

      {(!loaging && total == 0) || data?.length < 1 ? (
        <div className=" no-data">
          <img src="assets/img/no-data.png" />
          No Data
        </div>
      ) : (
        <></>
      )}

      {!loaging && total > 0 ? (
        <div className="paginationWrapper main-pagination">
          <div className="d-flex align-items-center">
            <div className="me-2 user-name-color">Show</div>
            {total > 0 && (
              <div className="dropdown addDropdown chnagesname ">
                <select
                  className="form-control"
                  value={filters?.count}
                  onChange={(e) => {
                    setFilter({ ...filters, count: e.target.value });
                    getData({ ...filters, count: e.target.value });
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="30">30</option>
                </select>
                <div
                  className="dropdown-menu shadow bg_hover"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 5 });
                    }}
                  >
                    5
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 10 });
                    }}
                  >
                    10
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 20 });
                    }}
                  >
                    20
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 30 });
                    }}
                  >
                    30
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 40 });
                    }}
                  >
                    40
                  </a>
                </div>
              </div>
            )}{" "}
            <div className="ms-2 user-name-color">from {total} Staff</div>
          </div>

          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
