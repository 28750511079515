import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import rolesModel from "../../models/roles.model";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import { useHistory } from "react-router-dom";
import { IoTrophyOutline } from "react-icons/io5";
import { FaAward } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { PiSmileySad } from "react-icons/pi";
import { TbBrandAppgallery } from "react-icons/tb";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
import loader from "../../methods/loader";
import { Tooltip } from "antd";
import moment from "moment";
import environment from "../../environment";
import { BsThreeDots } from "react-icons/bs";
import ImageUpload from "../../components/common/ImageUpload";
const Html = ({
  accepted,
  rejected,
  UpdateTransitStatus,
  sorting,
  setFilter,
  pageCompleteBidChange,
  pageRejectBidChange,
  Awarded,
  GetAwarded,
  rejectBid,
  pageChange,
  completeBid,
  filters,
  loaging,
  alldata,
  getData,
  TAB,
  settab,
  Loading,
  url,
  setLoading,
  role,
  total = { total },
}) => {
  const history = useHistory();

  const [Setting, setSetting] = useState({});
  const [form, setform] = useState({ agreed_rate: "", id: "" });
  const [loadDetails, setLoadDetails] = useState({ document: "" });
  const [files, setFIles] = useState([]);
  const [Notes, SetNotes] = useState({ note: "" });


  const HandleSubmit = (e) => {
    loader(true);
    e.preventDefault();
    ApiClient.put("change-rate", form).then((res) => {
      if (res.success) {
        toast.success(res.message);
        document.getElementById("CloseBidUpdateModel").click();
        getData();
      }
    });
    loader(false);
  };

  const [Document, setdocuments] = useState([]);
  const [fileInputs, setFileInputs] = useState([{ id: 1, file: null }]);

  const addFileInput = () => {
    setFileInputs([...fileInputs, { id: fileInputs.length + 1, file: null }]);
  };
  const removeFileInput = (id, file) => {
    setFileInputs(fileInputs.filter((input) => input.id !== id));
    setdocuments(Document.filter((itm) => itm != file));
    // if (file) {
    //   ApiClient.delete(`delete/document?fileName=${file}`).then((res) => {});
    // }
    if (id == 1) {
      setFileInputs([{ id: 1, file: null }]);
    }
  };
  const ReplaceUnderScoreWithSpace = (str) => {
    if (str?.includes("_")) {
      return methodModel.capitalizeFirstLetter(str.split("_").join(" "));
    }
    if (str == "pickedup") {
      return "Picked Up";
    } else {
      return methodModel.capitalizeFirstLetter(str);
    }
  };

  const UpdatePickedUpStatus = (e) => {
    e.preventDefault();
    setLoading("d-flex");
    if (!form?.load_id) {
      return;
    }
    ApiClient.put("load-status", {
      load_id: form?.load_id,
      bol_doc: Document,
      shipment_status: "pickedup",
    }).then((res) => {
      if (res.success) {
        setLoading("d-none");

        loader(false);
        toast.success(res?.message);
        document.getElementById("CloseBOLDocumentsModal").click();
        getData();
        setLoading("d-none");
      }
      loader(false);
    });
  };

  const HandleUploadDocument = async (e, id) => {
    setLoading("d-flex");
    const token = localStorage.getItem("token");

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    let file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);
    const UploadData = await fetch(
      `${environment.api}upload/image-document?modelName=users`,
      {
        method: "post",
        body: formdata,
        headers: headers,
      }
    );
    let res = await UploadData.json();

    if (res.success) {
      files.push(e?.target?.files[0]?.name);
      setLoadDetails({ ...loadDetails, document: res?.data?.fullpath });
      const updatedFileInputs = fileInputs.map((input) =>
        input.id === id ? { ...input, file: res?.data?.fullpath } : input
      );
      Document.push(`${res?.data?.fullpath}`);

      setFileInputs(updatedFileInputs);
      setLoading("d-none");
    } else {
      toast.error(res?.error?.message);

      removeFileInput(id, null);

      Document.pop();
      setLoading("d-none");
    }
  };
  const GetSettings = () => {
    ApiClient.get("settings").then((res) => {
      if (res.success) {
        setSetting(res?.data);
      }
    });
  };
  const CheckIn = (stop_id, load_id, prevoiusStop, index, data) => {
    setLoading("d-flex");
    if (index == data?.length && Document?.length == 0) {
      return setLoadDetails({ stop_id, load_id, prevoiusStop, data });
    }

    if (prevoiusStop) {
      ApiClient.put("load-status", {
        load_id,
        shipment_status: "in_transit",
        stop_id: prevoiusStop,
        checkout: moment(),
      }).then((res) => {});
    }

    setLoading("d-flex");
    let payload = {
      load_id,
      shipment_status: index == data?.length ? "delivered" : "in_transit",
      stop_id,

      checkin: moment(),
    };
    if (index == 0) {
      payload = { ...payload, bol_doc: Document };
    }

    ApiClient.put("load-status", payload).then((res) => {
      if (res.success) {
        toast.success(res?.message);
        getData();
        completeBid();
        rejectBid();
        setdocuments([]);
        setFileInputs([{ id: 1, file: null }]);

        setLoading("d-none");
      }
      setLoading("d-none");
    });
    setTimeout(() => {}, 1000);
  };

  const HandleNoteSubmit = (e) => {
    e.preventDefault();
    setLoading("d-flex");
    ApiClient.post("carrier-note", {
      title: Notes?.title,
      description: Notes?.description,
      load_id: Notes?.load_id,
      stop_id: Notes?.stop_id,
      new_eta: moment(Notes?.new_eta).format("YYYY-MM-DDTHH:mm"),
    }).then((res) => {
      if (res.success) {
        toast.success(res?.message);
        document.getElementById("CloseNotesModal").click();
        setLoading("none");
      }
    });
  };

  const UpdateStopWithDocuments = () => {
    setLoading("d-flex");
    // e.preventDefault()
    if (
      !loadDetails?.load_id ||
      !loadDetails?.stop_id ||
      Document?.length == 0
    ) {
      return false;
    }
    if (loadDetails?.prevoiusStop) {
      ApiClient.put("load-status", {
        load_id: loadDetails?.load_id,
        shipment_status: "in_transit",
        stop_id: loadDetails?.prevoiusStop,
        checkout: moment(),
      }).then((res) => {});
    }
    let payload = {
      load_id: loadDetails?.load_id,
      shipment_status: "delivered",
      stop_id: loadDetails?.stop_id,
      checkin: moment(),
      pod_doc: Document,
    };
    if (loadDetails?.prevoiusStop) {
      setTimeout(() => {
        ApiClient.put("load-status", payload).then((res) => {
          if (res.success) {
            toast.success(res?.message);
            setLoading("d-none");

            document.getElementById("CloseDocumentModal").click();
            getData();
            completeBid();
            setdocuments([]);
            setFileInputs([{ id: 1, file: null }]);
            setLoadDetails({ document: "" });
            setFIles([""]);
            rejectBid();
          }
          setLoading("d-none");
        });
      }, 1000);
    } else {
      ApiClient.put("load-status", payload).then((res) => {
        if (res.success) {
          setLoading("d-none");

          toast.success(res?.message);
          document.getElementById("CloseDocumentModal").click();
          getData();
          setdocuments([]);
          setFileInputs([{ id: 1, file: null }]);
          setLoadDetails({ document: "" });
          setFIles([]);
          completeBid();
          rejectBid();
        }
        setLoading("d-none");
      });
    }
  };

  useEffect(() => {
    GetSettings();
  }, []);

  const ConvertMarginType = (data) => {
    switch (data?.margin_type) {
      case "percentage":
        return `${data?.margin_value} %`;
        break;
      case "amount":
        return `$ ${data?.margin_value}`;
      default:
        return `${data?.margin_value} ${methodModel.capitalizeFirstLetter(
          data?.margin_type
        )}`;
        break;
    }
  };
  const Handlefilter = (e) => {
    const value = e.target.value;
    setFilter({ ...filters, [e.target.name]: value });
    getData({ [e.target.name]: value });
  };

  const clearUploadForm=()=>{
    setdocuments([])
    setFileInputs([{ id: 1, file: null }])
    setLoadDetails({ document: '' })
    setFIles([])
  }

  return (
    <Layout>
      <div className="d-flex justify-content-between align-items-center mb-4 wrapflex flex-wrap">
        <h3 className="hedding">{TAB} Bids</h3>
        <div className="d-flex align-items-center gap-2 flex-wrap mt-2">
          {" "}
          <div className=" ">
            <input
              className="form-control "
              placeholder="Search..."
              type="search"
              value={filters.search}
              name="search"
              onChange={(e) => Handlefilter(e)}
            />
          </div>
          <ul class="nav nav-tabs portal-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class={`nav-link ${url == "/bid" ? "active" : ""}`}
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
                onClick={() => {
                  getData();
                  history.push("/bid");

                  settab("All");
                }}
              >
                <TbBrandAppgallery className="me-1" />
                All
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class={`nav-link ${url == "/accepted-bids" ? "active" : ""}`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={() => {
                  settab("Won");
                  history.push("/accepted-bids");

                  completeBid();
                }}
              >
                <IoTrophyOutline className="me-1" />
                Won
              </button>
            </li>
            <li class="nav-item" role="awarded">
              <button
                class={`nav-link ${url == "/awarded-bids" ? "active" : ""}`}
                id="awarded-tab"
                data-bs-toggle="tab"
                data-bs-target="#awarded"
                type="button"
                role="tab"
                aria-controls="awarded"
                aria-selected="false"
                onClick={() => {
                  settab("Awarded");
                  GetAwarded();
                  history.push("/awarded-bids");
                }}
              >
                <FaAward className="me-1" />
                Awarded
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class={`nav-link ${url == "/rejected-bids" ? "active" : ""}`}
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => {
                  history.push("/rejected-bids");
                  settab("Loss");
                  rejectBid();
                }}
              >
                <PiSmileySad className="me-1" />
                Loss
              </button>
            </li>
          </ul>
        </div>
      </div>

      {
        <>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade "
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className={`${total>1?"table-responsive":""} respTable table_section`}>
                <table class="table ">
                  <thead className="table_head">
                    <tr className="heading_row">
                      <th scope="col" className="table_data pointer">
                        Bid Price($)
                      </th>

                      <th className="table_date pointer"> Bid ETA </th>
                      <th
                        scope="col"
                        className="table_data pointer"
                        onClick={(e) =>
                          filters?.sortBy == "origin_location_state asc"
                            ? sorting("origin_location_state desc")
                            : sorting("origin_location_state asc")
                        }
                      >
                        <span className="d-flex">
                          Origin City{" "}
                          {filters?.sortBy === "origin_location_state asc" ? (
                            <div class="d-flex  zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">A </span>{" "}
                                <span className="afont">Z</span>
                              </div>{" "}
                              <span>
                                <span class="material-icons arrfont">
                                  north
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div class="d-flex zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">Z </span>{" "}
                                <span className="afont">A</span>
                              </div>
                              <span>
                                <span class="material-icons arrfont">
                                  south
                                </span>
                              </span>
                            </div>
                          )}
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="table_data pointer"
                        onClick={(e) =>
                          filters?.sortBy == "destination_location_state asc"
                            ? sorting("destination_location_city desc")
                            : sorting("destination_location_city asc")
                        }
                      >
                        <span className="d-flex">
                          Destination City{" "}
                          {filters?.sortBy ===
                          "destination_location_city asc" ? (
                            <div class="d-flex  zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">A </span>{" "}
                                <span className="afont">Z</span>
                              </div>{" "}
                              <span>
                                <span class="material-icons arrfont">
                                  north
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div class="d-flex zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">Z </span>{" "}
                                <span className="afont">A</span>
                              </div>
                              <span>
                                <span class="material-icons arrfont">
                                  south
                                </span>
                              </span>
                            </div>
                          )}
                        </span>
                      </th>
                      <th scope="col" className="table_data pointer">
                        Pickup Date
                      </th>
                      <th scope="col" className="table_data pointer">
                        Expiration Date{" "}
                      </th>
                      <th scope="col" className="table_data pointer">
                        Stops{" "}
                      </th>
                      <th scope="col" className="table_data pointer">
                        Dilevery Status{" "}
                      </th>
                      <th scope="col" className="table_data ">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loaging &&
                      accepted &&
                      accepted.map((itm, i) => {
                        return (
                          <tr className="data_row">
                            <td className="table_dats">
                              {itm?.carrier_info?.agreed_rate || "--"}
                            </td>
                            <td>
                              {datepipeModel.date(itm?.bid_time)},{" "}
                              {datepipeModel.isotime(itm?.bid_time)}
                            </td>
                            <td className="table_dats">
                              {methodModel.capitalizeFirstLetter(
                                itm?.load_info?.origin_location_city
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              {methodModel.capitalizeFirstLetter(
                                itm?.load_info?.destination_location_city
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              {datepipeModel.date(
                                itm?.load_info?.load_start_date
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              {datepipeModel.date(itm?.expiration_date) || "--"}
                            </td>
                            <td className="table_dats">
                              <div className="dotdiv">
                                {" "}
                                {itm?.shipment_status == "pending" &&
                                itm?.status == "awarded" ? (
                                  <div class="dropdown dotsbtn2">
                                    <button
                                      class="btn dropdown-toggle "
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <BsThreeDots />
                                    </button>
                                    <div class="dropdown-menu">
                                      <a
                                        class="dropdown-item"
                                        onClick={() => {
                                          document
                                            .getElementById(
                                              "OpenBOLDocumentsModal"
                                            )
                                            .click();
                                          setform({
                                            ...form,
                                            load_id: itm?.load_id,
                                          });
                                        }}
                                      >
                                        Picked Up
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    {itm?.stops_details?.length != 0
                                      ? itm?.stops_details?.map(
                                          (item, index) => {
                                            return (
                                              <span className="d-flex align-items-center ">
                                                <Tooltip
                                                  title={`${
                                                    item?.checkin
                                                      ? "CheckedIn-"
                                                      : ""
                                                  } ${item?.address} ${
                                                    item?.checkout
                                                      ? "-CheckedOut"
                                                      : ""
                                                  }`}
                                                >
                                                  <div className="">
                                                    <span className="destspan">
                                                      {item?.address?.substr(
                                                        0,
                                                        10
                                                      )}
                                                      ...
                                                    </span>
                                                  </div>
                                                </Tooltip>
                                                {itm?.status == "awarded" ? (
                                                  <>
                                                    {item?.checkin ||
                                                    (!itm?.stops_details[
                                                      index - 1
                                                    ]?.checkin &&
                                                      index > 0) ? null : (
                                                      <Tooltip>
                                                        <div
                                                          class="dropdown dotsbtn"
                                                          title="Options"
                                                        >
                                                          <button
                                                            class="btn dropdown-toggle "
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            <BsThreeDots />
                                                          </button>
                                                          <div class="dropdown-menu">
                                                            <a
                                                              class="dropdown-item"
                                                              data-bs-toggle={
                                                                index + 1 ==
                                                                itm
                                                                  ?.stops_details
                                                                  ?.length
                                                                  ? "modal"
                                                                  : ""
                                                              }
                                                              data-bs-target={
                                                                index + 1 ==
                                                                itm
                                                                  ?.stops_details
                                                                  ?.length
                                                                  ? "#DocumentModal"
                                                                  : ""
                                                              }
                                                              title="Checkin"
                                                              onClick={() => {
                                                                clearUploadForm()
                                                                if (
                                                                  index + 1 ==
                                                                  itm
                                                                    ?.stops_details
                                                                    ?.length
                                                                ) {
                                                                  return setLoadDetails(
                                                                    {
                                                                      stop_id:
                                                                        item?._id,
                                                                      load_id:
                                                                        itm?.load_id,
                                                                      prevoiusStop:
                                                                        itm
                                                                          ?.stops_details[
                                                                          index -
                                                                            1
                                                                        ]?._id,
                                                                      data: itm?.stops_details,
                                                                    }
                                                                  );
                                                                }
                                                                CheckIn(
                                                                  item?._id,
                                                                  itm?.load_id,
                                                                  itm
                                                                    ?.stops_details[
                                                                    index - 1
                                                                  ]?._id,
                                                                  index + 1,
                                                                  itm?.stops_details
                                                                );
                                                              }}
                                                            >
                                                              Checkin
                                                            </a>
                                                            <a
                                                              class="dropdown-item"
                                                              title="Shipment Notes"
                                                              onClick={() => {
                                                                document
                                                                  .getElementById(
                                                                    "OpenNotesModal"
                                                                  )
                                                                  .click();
                                                                SetNotes({
                                                                  ...Notes,
                                                                  load_id:
                                                                    itm?.load_id,
                                                                  stop_id:
                                                                    item?._id,
                                                                });
                                                              }}
                                                            >
                                                              Add Note
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </Tooltip>
                                                    )}
                                                    {item?.checkin && (
                                                      <span className="tableficon">
                                                        <i
                                                          class="fa fa-map-marker fa-map-marker"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </span>
                                                    )}
                                                  </>
                                                ) : null}
                                              </span>
                                            );
                                          }
                                        )
                                      : "--"}{" "}
                                  </>
                                )}
                              </div>
                            </td>
                            <td className="table_dats statusdivs">
                              <span className={`${itm?.shipment_status}`}>
                                {ReplaceUnderScoreWithSpace(
                                  itm?.shipment_status
                                ) || "--"}
                              </span>
                            </td>

                            <td className="table_dats text-capitalize">
                              <span className="edit_icon">
                                <i
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    history.push(`/bidDetail/${itm?.id}`);
                                  }}
                                  class="fa fa-eye"
                                ></i>
                              </span>
                              {itm?.status == "pending" && (
                                <div className="action_icons">
                                  <span className="edit_icon">
                                    <i
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        document
                                          .getElementById("OpenBidEditModel")
                                          .click();
                                        setform({
                                          ...form,
                                          id: itm?.id,
                                          agreed_rate: itm?.agreed_rate || "",
                                        });
                                      }}
                                      class="material-icons edit"
                                    >
                                      edit
                                    </i>
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {!loaging && total > 0 ? (
                <div className="paginationWrapper main-pagination">
                  <div className="d-flex align-items-center">
                    <div className="me-2 user-name-color">Show</div>
                    {total > 0 && (
                      <select
                        value={filters?.count}
                        onChange={(e) => {
                          setFilter({ ...filters, count: e.target.value });
                          completeBid({ count: e.target.value });
                        }}
                        className="form-control"
                      >
                        <option value={"5"}> 5</option>

                        <option value={"10"}> 10</option>
                        <option value={"30"}> 30</option>
                      </select>
                    )}{" "}
                    <div className="ms-2 user-name-color">
                      from {total} Loads
                    </div>
                  </div>

                  <Pagination
                    currentPage={filters.page}
                    totalSize={total}
                    itemClassPrev="back_page"
                    itemClassNext="next_page"
                    sizePerPage={filters.count}
                    changeCurrentPage={pageCompleteBidChange}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div className={`${total>1?"table-responsive":""} respTable table_section`}>
                <table class="table ">
                  <thead className="table_head">
                    <tr className="heading_row">
                      <th
                        scope="col"
                        className="table_data pointer"
                        onClick={(e) =>
                          filters?.sortBy == "carrier_name asc"
                            ? sorting("carrier_name desc", "desc")
                            : sorting("carrier_name asc", "asc")
                        }
                      >
                        Carrier Name{" "}
                        {filters?.sortBy === "carrier_name asc" ? "↑" : "↓"}
                      </th>
                      <th scope="col" className="table_data pointer">
                        Bid Price($)
                      </th>
                      {/* <th scope="col" className="table_data pointer">
                        USPS Amount($)
                      </th> */}
                      <th>Bid ETA</th>
                      <th
                        scope="col"
                        className="table_data pointer"
                        onClick={(e) =>
                          filters?.sortBy == "origin_location_state asc"
                            ? sorting("origin_location_state desc")
                            : sorting("origin_location_state asc")
                        }
                      >
                        <span className="d-flex">
                          Origin City{" "}
                          {filters?.sortBy === "origin_location_state asc" ? (
                            // ? "↑ A-Z"
                            // : "↓ Z-A"
                            <div class="d-flex  zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">A </span>{" "}
                                <span className="afont">Z</span>
                              </div>{" "}
                              <span>
                                <span class="material-icons arrfont">
                                  north
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div class="d-flex zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">Z </span>{" "}
                                <span className="afont">A</span>
                              </div>
                              <span>
                                <span class="material-icons arrfont">
                                  south
                                </span>
                              </span>
                            </div>
                          )}
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="table_data pointer"
                        onClick={(e) =>
                          filters?.sortBy == "destination_location_state asc"
                            ? sorting("destination_location_city desc")
                            : sorting("destination_location_city asc")
                        }
                      >
                        <span className="d-flex">
                          Destination City{" "}
                          {filters?.sortBy ===
                          "destination_location_city asc" ? (
                            <div class="d-flex  zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">A </span>{" "}
                                <span className="afont">Z</span>
                              </div>{" "}
                              <span>
                                <span class="material-icons arrfont">
                                  north
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div class="d-flex zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">Z </span>{" "}
                                <span className="afont">A</span>
                              </div>
                              <span>
                                <span class="material-icons arrfont">
                                  south
                                </span>
                              </span>
                            </div>
                          )}
                        </span>
                      </th>

                      <th scope="col" className="table_data ">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loaging &&
                      rejected &&
                      rejected?.map((itm, i) => {
                        return (
                          <tr className="data_row">
                            <td
                              className="table_dats pointer"
                              onClick={() => {
                                history.push("/bidDetail/" + itm?.id);
                              }}
                            >
                              {methodModel.capitalizeFirstLetter(
                                itm?.carrier_name
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              {itm?.carrier_info?.agreed_rate || "--"}
                            </td>

                            <td>
                              {datepipeModel.date(itm?.bid_time)},{" "}
                              {datepipeModel.isotime(itm?.bid_time)}
                            </td>
                            <td className="table_dats">
                              {methodModel.capitalizeFirstLetter(
                                itm?.load_info?.origin_location_city
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              {methodModel.capitalizeFirstLetter(
                                itm?.load_info?.destination_location_city
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              <i
                                className="fa fa-eye"
                                onClick={() => {
                                  history.push(`/bidDetail/${itm?.id}`);
                                }}
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {!loaging && total > 0 ? (
                <div className="paginationWrapper main-pagination">
                  <div className="d-flex align-items-center">
                    <div className="me-2 user-name-color">Show</div>
                    {total > 0 && (
                      <select
                        value={filters?.count}
                        onChange={(e) => {
                          setFilter({ ...filters, count: e.target.value });
                          rejectBid({ count: e.target.value });
                        }}
                        className="form-control"
                      >
                        {/* <option value=""> All</option> */}
                        <option value={"5"}> 5</option>

                        <option value={"10"}> 10</option>
                        <option value={"30"}> 30</option>
                      </select>
                    )}{" "}
                    <div className="ms-2 user-name-color 3">
                      from {total} Loads
                    </div>
                  </div>

                  <Pagination
                    currentPage={filters.page}
                    totalSize={total}
                    sizePerPage={filters.count}
                    changeCurrentPage={pageRejectBidChange}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              class="tab-pane fade show active"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <div className={`${total>1?"table-responsive":""} respTable table_section`}>
                <table class="table ">
                  <thead className="table_head">
                    <tr className="heading_row">
                      <th scope="col" className="table_data pointer">
                        Bid Price($)
                      </th>

                      <th>Bid ETA</th>
                      <th
                        scope="col"
                        className="table_data pointer "
                        onClick={(e) =>
                          filters?.sortBy == "origin_location_city asc"
                            ? sorting("origin_location_city desc")
                            : sorting("origin_location_city asc")
                        }
                      >
                        <span className="d-flex align-items-center">
                          {" "}
                          Origin City{" "}
                          {filters?.sortBy === "origin_location_city asc" ? (
                            <div class="d-flex  zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">A </span>{" "}
                                <span className="afont">Z</span>
                              </div>{" "}
                              <span>
                                <span class="material-icons arrfont">
                                  north
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div class="d-flex zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">Z </span>{" "}
                                <span className="afont">A</span>
                              </div>
                              <span>
                                <span class="material-icons arrfont">
                                  south
                                </span>
                              </span>
                            </div>
                          )}
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="table_data pointer "
                        onClick={(e) =>
                          filters?.sortBy == "destination_location_city asc"
                            ? sorting("destination_location_city desc")
                            : sorting("destination_location_city asc")
                        }
                      >
                        <span className="d-flex align-items-center">
                          {" "}
                          Destination City{" "}
                          {filters?.sortBy ===
                          "destination_location_city asc" ? (
                            <div class="d-flex  zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">A </span>{" "}
                                <span className="afont">Z</span>
                              </div>{" "}
                              <span>
                                <span class="material-icons arrfont">
                                  north
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div class="d-flex zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">Z </span>{" "}
                                <span className="afont">A</span>
                              </div>
                              <span>
                                <span class="material-icons arrfont">
                                  south
                                </span>
                              </span>
                            </div>
                          )}
                        </span>{" "}
                      </th>
                      <th scope="col" className="table_data pointer">
                        Pickup Date
                      </th>
                      <th scope="col" className="table_data pointer">
                        Expiration Date{" "}
                      </th>
                      <th scope="col" className="table_data pointer">
                        Stops{" "}
                      </th>
                      <th scope="col" className="table_data pointer">
                        Delivery Status{" "}
                      </th>
                      {TAB == "All" ? (
                        <th scope="col" className="table_data ">
                          Status
                        </th>
                      ) : null}
                      <th scope="col" className="table_data ">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loaging &&
                      alldata.map((itm, i) => {
                        return (
                          <tr className="data_row">
                            <td className="table_dats">
                              {itm?.carrier_info?.agreed_rate || "--"}
                            </td>

                            <td>
                              {datepipeModel.date(itm?.bid_time)},{" "}
                              {datepipeModel.isotime(itm?.bid_time)}
                            </td>
                            <td className="table_dats">
                              {methodModel.capitalizeFirstLetter(
                                itm?.load_info?.origin_location_city
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              {methodModel.capitalizeFirstLetter(
                                itm?.load_info?.destination_location_city
                              ) || "--"}
                            </td>

                            <td className="table_dats">
                              {datepipeModel.date(
                                itm?.pickedup_at
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              {datepipeModel.date(itm?.expiration_date) || "--"}
                            </td>
                            <td className="table_dats">
                              <div className="dotdiv d-flex flex-column">
                                {" "}
                                {itm?.shipment_status == "pending" &&
                                itm?.status == "awarded" ? (
                                  //  && itm?.status != 'rejected'
                                  <>
                                    {itm?.stops_details?.length != 0
                                      ? itm?.stops_details?.map(
                                          (item, index) => {
                                            return (
                                              <Tooltip title={item?.address}>
                                                <span className="destspan">
                                                  {item?.address?.substr(0, 10)}
                                                  ...
                                                </span>
                                              </Tooltip>
                                            );
                                          }
                                        )
                                      : ""}

                                    <div
                                      class="dropdown dotsbtn2 text-center aginposition"
                                      title="Options"
                                    >
                                      <button
                                        class="btn dropdown-toggle "
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <BsThreeDots />
                                      </button>
                                      <div class="dropdown-menu">
                                        <a
                                          class="dropdown-item"
                                          onClick={() => {
                                            document
                                              .getElementById(
                                                "OpenBOLDocumentsModal"
                                              )
                                              .click();
                                            // UpdateTransitStatus({ load_id: itm?.load_id })
                                            setform({
                                              ...form,
                                              load_id: itm?.load_id,
                                            });
                                          }}
                                        >
                                          Picked Up
                                        </a>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {itm?.stops_details?.length != 0
                                      ? itm?.stops_details?.map(
                                          (item, index) => {
                                            return (
                                              <span className="d-flex align-items-center ">
                                                <Tooltip
                                                  title={`${
                                                    item?.checkin
                                                      ? "CheckedIn-"
                                                      : ""
                                                  } ${item?.address} ${
                                                    item?.checkout
                                                      ? "-CheckedOut"
                                                      : ""
                                                  }`}
                                                >
                                                  <div className="">
                                                    {/* <p>{itm?.stops_details[1]?.state}</p> */}
                                                    <span className="destspan">
                                                      {item?.address?.substr(
                                                        0,
                                                        10
                                                      )}
                                                      ...
                                                    </span>
                                                  </div>
                                                </Tooltip>
                                                {itm?.status == "awarded" ? (
                                                  <>
                                                    {item?.checkin ||
                                                    (!itm?.stops_details[
                                                      index - 1
                                                    ]?.checkin &&
                                                      index > 0) ? null : (
                                                      <Tooltip>
                                                        <div
                                                          class="dropdown dotsbtn"
                                                          title="Options"
                                                        >
                                                          <button
                                                            class="btn dropdown-toggle "
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            <BsThreeDots
                                                              onClick={() => {
                                                                console.log(
                                                                  fileInputs
                                                                );
                                                              }}
                                                            />
                                                          </button>
                                                          <div class="dropdown-menu">
                                                            <a
                                                              class="dropdown-item"
                                                              data-bs-toggle={
                                                                index + 1 ==
                                                                itm
                                                                  ?.stops_details
                                                                  ?.length
                                                                  ? "modal"
                                                                  : ""
                                                              }
                                                              data-bs-target={
                                                                index + 1 ==
                                                                itm
                                                                  ?.stops_details
                                                                  ?.length
                                                                  ? "#DocumentModal"
                                                                  : ""
                                                              }
                                                              title="Checkin"
                                                              onClick={() => {
                                                                clearUploadForm()
                                                                // console.log(
                                                                //   fileInputs,
                                                                //   "==============DATA"
                                                                // );
                                                                // console.log(
                                                                //   fileInputs,
                                                                //   "==============DATA"
                                                                // );
                                                                setFileInputs([
                                                                  {},
                                                                ]);
                                                                if (
                                                                  index + 1 ==
                                                                  itm
                                                                    ?.stops_details
                                                                    ?.length
                                                                ) {
                                                                  return setLoadDetails(
                                                                    {
                                                                      stop_id:
                                                                        item?._id,
                                                                      load_id:
                                                                        itm?.load_id,
                                                                      prevoiusStop:
                                                                        itm
                                                                          ?.stops_details[
                                                                          index -
                                                                            1
                                                                        ]?._id,
                                                                      data: itm?.stops_details,
                                                                    }
                                                                  );
                                                                }
                                                                CheckIn(
                                                                  item?._id,
                                                                  itm?.load_id,
                                                                  itm
                                                                    ?.stops_details[
                                                                    index - 1
                                                                  ]?._id,
                                                                  index + 1,
                                                                  itm?.stops_details
                                                                );
                                                              }}
                                                            >
                                                              Checkin
                                                            </a>
                                                            <a
                                                              class="dropdown-item"
                                                              title="Shipment Notes"
                                                              onClick={() => {
                                                                document
                                                                  .getElementById(
                                                                    "OpenNotesModal"
                                                                  )
                                                                  .click();
                                                                SetNotes({
                                                                  ...Notes,
                                                                  load_id:
                                                                    itm?.load_id,
                                                                  stop_id:
                                                                    item?._id,
                                                                });
                                                              }}
                                                            >
                                                              Add Note
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </Tooltip>
                                                    )}

                                                    {item?.checkin && (
                                                      <Tooltip
                                                        title={`Reached ${
                                                          item?.address
                                                        } at  ${moment(
                                                          item?.checkin
                                                        ).format(
                                                          "DD-MMM-YYYY h:m A"
                                                        )}`}
                                                      >
                                                        <span
                                                          onMouseEnter={(
                                                            e
                                                          ) => {}}
                                                          className="tableficon"
                                                        >
                                                          <i
                                                            class="fa fa-map-marker text-success"
                                                            aria-hidden="true"
                                                          ></i>
                                                        </span>
                                                      </Tooltip>
                                                    )}
                                                  </>
                                                ) : null}
                                              </span>
                                            );
                                          }
                                        )
                                      : "--"}{" "}
                                  </>
                                )}
                              </div>
                            </td>
                            <td className="table_dats statusdivs">
                              {itm?.status == "rejected"
                                ? "--"
                                : (
                                    <span className={`${itm?.shipment_status}`}>
                                      {ReplaceUnderScoreWithSpace(
                                        itm?.shipment_status
                                      )}
                                    </span>
                                  ) || "--"}
                            </td>
                            {TAB == "All" ? (
                              <td className="table_dats statusdivs">
                                <span className={`${itm?.status}`}>
                                  {methodModel.capitalizeFirstLetter(
                                    itm?.status
                                  )}
                                </span>
                              </td>
                            ) : null}

                            <td className="table_dats ">
                              <div class="dropdown dotsbtn  acttwo">
                                <button
                                  class="btn dropdown-toggle  "
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <BsThreeDots />
                                </button>
                                <div class="dropdown-menu">
                                  <a
                                    class="dropdown-item d-flex align-items-center gap-1"
                                    onClick={(e) => {
                                      history.push(`/bidDetail/${itm?.id}`);
                                    }}
                                  >
                                    {" "}
                                    <span className="">
                                      <i class="fa fa-eye"></i>
                                    </span>{" "}
                                    <span className="">View</span>{" "}
                                  </a>
                                  {itm?.status == "pending" ? (
                                    <a
                                      onClick={(e) => {
                                        document
                                          .getElementById("OpenBidEditModel")
                                          .click();
                                        setform({
                                          ...form,
                                          id: itm?.id,
                                          agreed_rate: itm?.agreed_rate || "",
                                        });
                                      }}
                                      class="dropdown-item d-flex align-items-center gap-1"
                                    >
                                      {" "}
                                      <span className="">
                                        <i class="material-icons edit">edit</i>
                                      </span>{" "}
                                      <span className="">Edit</span>{" "}
                                    </a>
                                  ) : null}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {!loaging && total > 0 ? (
                <div className="paginationWrapper main-pagination">
                  <div className="d-flex align-items-center">
                    <div className="me-2 user-name-color">Show</div>
                    {total > 0 && (
                      <select
                        value={filters?.count}
                        onChange={(e) => {
                          setFilter({ ...filters, count: e.target.value });
                          getData({ count: e.target.value });
                        }}
                        className="form-control"
                      >
                        {/* <option value=""> All</option> */}
                        <option value={"5"}> 5</option>

                        <option value={"10"}> 10</option>
                        <option value={"30"}> 30</option>
                      </select>
                    )}{" "}
                    <div className="ms-2 user-name-color 1">
                      from {total} Bids
                    </div>
                  </div>

                  <Pagination
                    currentPage={filters.page}
                    totalSize={total}
                    sizePerPage={filters.count}
                    changeCurrentPage={pageChange}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      }

      <button
        type="button "
        id="OpenBidEditModel"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Update Bid Price
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form onSubmit={HandleSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="">
                      Agreed Rate
                      <label className="text-danger">*</label>
                    </label>
                    <input
                      value={form?.agreed_rate}
                      required
                      min={0}
                      multiple
                      type="number"
                      onChange={(e) => {
                        setform({ ...form, agreed_rate: e.target.value });
                      }}
                      className="form-control mt-2"
                      placeholder="Enter Bid Rate"
                      name=""
                      id=""
                    />
                  </div>
                  <div class="modal-footer">
                    <button
                      id="CloseBidUpdateModel"
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" class="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {(!loaging && total == 0) ? (
        <div className=" no-data">
          <img src="/assets/img/no-data.png" />
          No Data
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}

      <div
        class="modal fade"
        id="DocumentModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog ">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Upload POD (Proof of Delivery)</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" onClick={() => {
                clearUploadForm()
              }} aria-label="Close"></button>
            </div>
            <form onSubmit={UpdateStopWithDocuments}>
              <div class="modal-body documentation_modal position-relative">
              <ImageUpload
                        value={Document}
                        multiple={true}
                        result={(e) => {
                          setdocuments(e.value)
                        }}
                      />
              </div>
              <div class="modal-footer">
                <button type="button" id="CloseDocumentModal" onClick={() => {
                  clearUploadForm()
                }} class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" onClick={() => {
                  if (Document?.length != 0) {
                    UpdateStopWithDocuments()
                  } else {
                    alert("Please upload document")
                  }
                }}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="BOLDocumentModel"
        tabindex="-1"
        aria-labelledby="exampleBOLDocumentModel"
        aria-hidden="true"
      >
        <div class="modal-dialog ">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleBOLDocumentModel">Upload BOL ( Bill of lading )</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" onClick={() => {
               clearUploadForm()
              }} aria-label="Close"></button>
            </div>
            <form onSubmit={UpdatePickedUpStatus}>
              <div class="modal-body documentation_modal position-relative">
               
                   <ImageUpload
                        value={Document}
                        multiple={true}
                        result={(e) => {
                          setdocuments(e.value)
                        }}
                      />
               
              </div>
              <div class="modal-footer">
                <button type="button" id="CloseBOLDocumentsModal" onClick={() => {
                  clearUploadForm()
                }} class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <button
        type="button"
        id="OpenBOLDocumentsModal"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#BOLDocumentModel"
      >
        Launch demo modal
      </button>
      <button
        type="button"
        id="OpenNotesModal"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#NoteModal"
      >
        Launch demo modal
      </button>
      <div
        class="modal fade"
        id="NoteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Shipment Notes
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                HandleNoteSubmit(e);
              }}
            >
              <div class="modal-body documentation_modal position-relative">
                <div className="col-md-6 mb-3">
                  <label>
                    Title <label className="text-danger">*</label>
                  </label>
                  <input
                    placeholder="Enter Title"
                    value={Notes?.title}
                    type="text"
                    required
                    onChange={(e) => {
                      SetNotes({ ...Notes, title: e.target.value });
                    }}
                    className="form-control mb-2"
                  />
                </div>
                <div className="col-md-6 mb-3 mt-3 ">
                  <label htmlFor="">
                    Description <label className="text-danger">*</label>
                  </label>
                  <textarea
                    value={Notes?.description}
                    cols={10}
                    type="text"
                    placeholder="Enter Description"
                    required
                    onChange={(e) => {
                      SetNotes({ ...Notes, description: e.target.value });
                    }}
                    className="form-control mb-2"
                  />
                </div>
                <div className="col-md-6 mb-3 mt-3 pickdate ">
                  <span className="d-block">
                    {" "}
                    <label htmlFor="" className="d-flex">
                      New ETA <label className="text-danger d-block">*</label>
                    </label>
                  </span>
                  <DatePicker
                    selected={Notes?.new_eta}
                    className="form-control w-100"
                    minDate={Date.now()}
                    onChange={(e) => {
                      SetNotes({ ...Notes, new_eta: e });
                    }}
                    showTimeSelect
                    dateFormat="dd-MM-yyyy h:m a"
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="CloseNotesModal"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Html;
