import React, { useEffect, useState } from "react";
import methodModel from "../../../methods/methods";
import { Link } from "react-router-dom";
import "./style.scss";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GooglePlaceAutoComplete from "../../common/GooglePlaceAutoComplete";
import addressModel from "../../../models/address.model";
import Footer from "../../global/Footer/footer";
import WebsiteHeader from "../../global/WebsiteHeader";

const Html = ({
  handleSubmit,
  setForm,
  form,
  getError,
  uploadImage,
  submitted,
  setAddressSellected,
}) => {
  const user = useSelector((state) => state.user);

  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = addressModel.getAddress(e.place);
      setAddressSellected(true);
    } else {
      setAddressSellected(false);
    }
    setForm({
      ...form,
      address: e.value,
      country: address.country || "",
      city: address.city || "",
      state: address.state || "",
      zipcode: address.zipcode || "",
      lat: address.lat || "",
      lng: address.lng || "",
    });
    if (e.place) {
      // setTimezoneLoader(true)
      const apires = await addressModel.gettimeZone(e.place);
      // setTimezoneLoader(false)
      setForm({
        ...form,
        address: e.value,
        country: address.country || "",
        city: address.city || "",
        state: address.state || "",
        zipcode: address.zipcode || "",
        lat: address.lat || "",
        lng: address.lng || "",
      });
    }
  };

  return (
    <>
      <WebsiteHeader />

      <div className="portal-page">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="pprofile1 ">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5>Basic Information</h5>
                </div>

                <form className="form-row" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile_img_side">
                        <img
                          src={methodModel.userImg(form && form.image)}
                          className="profileImage"
                        />

                        <div>
                          <label className="new_images edit">
                            <input
                              id="bannerImage"
                              type="file"
                              className="d-none "
                              accept="image/*"
                              value={form.baseImg ? form.baseImg : ""}
                              onChange={(e) => {
                                uploadImage(e);
                              }}
                            />
                            <span className="uploader">
                              <i className="material-icons">camera_alt</i>Upload
                              Image
                            </span>
                          </label>
                        </div>
                        <div className="changes_image">
                          <div>
                            {form.image ? (
                              <label
                                className="deleticon  delete "
                                onClick={(e) => setForm({ ...form, image: "" })}
                              >
                                {/* <span className="remove" title="Remove">
                              {" "}
                              <i  className="material-icons">close</i>
                            </span> */}
                              </label>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="label_profile">
                            Name<span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Name"
                              value={form.fullName ? form.fullName : ""}
                              onChange={(e) =>
                                setForm({ ...form, fullName: e.target.value })
                              }
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="label_profile">
                            Email<span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Name"
                              value={form.email ? form.email : ""}
                              disabled
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6 mb-3">
                        <label className="label_profile">TimeZone<span className='text-danger'>*</span></label>
                        <div>
                        <TimezoneSelect
          value={form.timeZone}
          onChange={e=>setForm({...form,timeZone:e})}
          required
        />
                        </div>
                      </div> */}
                        <div className="col-md-6 mb-3">
                          <label className="label_profile">
                            Address<span className="text-danger">*</span>
                          </label>
                          <div>
                            <GooglePlaceAutoComplete
                              value={form.address}
                              result={addressResult}
                              id="address"
                              placeholder=""
                            />
                            {form.address == "" && submitted ? (
                              <div className="invalid-feedback d-block">
                                Please Select Location Suggestion
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="label_profile">Carrier ID</label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Carrier ID"
                              value={form.carrier_id}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="">
                            Mobile No <span className="text-danger">*</span>
                          </label>
                          <div className="phoneInput_cls d-flex form-control p-0">
                            <PhoneInput
                              country='us'
                              value={form?.dialCode | '1'}
                              countryCodeEditable={false}
                              enableSearch={true}
                              onChange={(phone, country) => {
                                setForm({
                                  ...form,
                                  dialCode: country.dialCode,
                                });
                              }}
                              required
                            />

                            <input
                              type="text"
                              className="form-control phph"
                              placeholder="Mobile No."
                              value={(form && form.mobileNo) || ""}
                              maxLength={12}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  mobileNo: methodModel.isNumber(e),
                                })
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="label_profile">Role</label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Name"
                              value={methodModel.capitalizeFirstLetter(
                                form?.role
                              )}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="label_profile">Fax</label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Fax"
                              value={form.fax ? form.fax : ""}
                              onChange={(e) =>
                                setForm({ ...form, fax: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="label_profile">Tax ID</label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Tax ID"
                              value={form.fax ? form.fax : ""}
                              onChange={(e) =>
                                setForm({ ...form, fax: e.target.value })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="label_profile">Truck Number</label>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Number"
                              value={form.truckNo}
                              onChange={(e) =>
                                setForm({ ...form, truckNo: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="label_profile">
                            Trailer Number
                          </label>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Number"
                              value={form?.trailerNo}
                              onChange={(e) =>
                                setForm({ ...form, trailerNo: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="label_profile">Team Drivers</label>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Number"
                              value={form?.driver_team}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  driver_team: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="label_profile">Solo Drivers</label>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Number"
                              value={form?.solo_driver}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  solo_driver: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label ml-2">
                            Trailer Type
                            <span className="text-danger">*</span>
                          </label>
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                id="dry_van"
                                type="checkbox"
                                name="type"
                                value="dry_van"
                                checked={form?.trailers_type?.includes(
                                  "dry_van"
                                )}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  let updatedTypes = [
                                    ...form?.trailers_type,
                                  ];
                                  if (isChecked) {
                                    updatedTypes.push("dry_van");
                                  } else {
                                    <div className="col-md-12 mb-3">
                                      <label className="form-label ml-2">
                                        Trailer Type
                                        <span className="text-danger">
                                          *
                                        </span>
                                      </label>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <input
                                            id="dry_van"
                                            type="checkbox"
                                            name="type"
                                            value="dry_van"
                                            checked={form?.trailers_type?.includes(
                                              "dry_van"
                                            )}
                                            onChange={(e) => {
                                              const isChecked =
                                                e.target.checked;
                                              let updatedTypes = [
                                                ...form?.trailers_type,
                                              ];
                                              if (isChecked) {
                                                updatedTypes.push(
                                                  "dry_van"
                                                );
                                              } else {
                                                updatedTypes =
                                                  updatedTypes.filter(
                                                    (type) =>
                                                      type !== "dry_van"
                                                  );
                                              }
                                              setForm({
                                                ...form,
                                                trailers_type: updatedTypes,
                                              });
                                            }}
                                          />
                                          <label
                                            for="dry_van"
                                            className="ms-2"
                                          >
                                            Dry-Van
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          <input
                                            id="dry_van"
                                            type="checkbox"
                                            name="type"
                                            value="reefer"
                                            checked={form?.trailers_type?.includes(
                                              "reefer"
                                            )}
                                            onChange={(e) => {
                                              const isChecked =
                                                e.target.checked;
                                              let updatedTypes = [
                                                ...form?.trailers_type,
                                              ];
                                              if (isChecked) {
                                                updatedTypes.push("reefer");
                                              } else {
                                                updatedTypes =
                                                  updatedTypes.filter(
                                                    (type) =>
                                                      type !== "reefer"
                                                  );
                                              }
                                              setForm({
                                                ...form,
                                                trailers_type: updatedTypes,
                                              });
                                            }}
                                          />
                                          <label
                                            for="reefer"
                                            className="ms-2"
                                          >
                                            Reefer
                                          </label>
                                        </div>
                                      </div>
                                    </div>;
                                    updatedTypes = updatedTypes.filter(
                                      (type) => type !== "dry_van"
                                    );
                                  }
                                  setForm({
                                    ...form,
                                    trailers_type: updatedTypes,
                                  });
                                }}
                              />
                              <label for="dry_van" className="ms-2">
                                Dry Van
                              </label>
                            </div>
                            <div className="col-md-6">
                              <input
                                id="reefer"
                                type="checkbox"
                                name="type"
                                value="reefer"
                                checked={form?.trailers_type?.includes(
                                  "reefer"
                                )}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  let updatedTypes = [
                                    ...form?.trailers_type,
                                  ];
                                  if (isChecked) {
                                    updatedTypes.push("reefer");
                                  } else {
                                    updatedTypes = updatedTypes.filter(
                                      (type) => type !== "reefer"
                                    );
                                  }
                                  setForm({
                                    ...form,
                                    trailers_type: updatedTypes,
                                  });
                                }}
                              />
                              <label for="reefer" className="ms-2">
                                Reefer
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6 mb-3">
                          <label className="label_profile">Truck Type</label>
                          <div>
                            <select
                              className="form-control col-md-12 select-new"
                              value={form?.truck_type}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  truck_type: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select Type</option>
                              <option value="dry van">Dry Van</option>
                              <option value="reefer">Reefer</option>
                            </select>
                          </div>
                        </div> */}

                        <div className="col-md-12 d-flex justify-content-end gap-2">
                          <Link to="/profile1" className=" btn btn-save">
                            Discard
                          </Link>

                          <button type="submit" className="btn btn-cancel">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Html;
