import { useHistory, useParams } from 'react-router';
import Layout2 from '../../../components/global/Layout2';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import React, { useEffect, useState } from 'react';
// import "./style.scss";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import methodModel from '../../../methods/methods';
import ReadMore from '../../../components/common/ReadMore';
const UserDetails2 = (p) => {
  const history = useHistory();
  const { id, userId } = useParams();
  const [Permission, SetPermission] = useState({});
  const [data, setData] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const Name = searchParams.get('Name');
  const getDetail = (did) => {
    loader(true);
    ApiClient.get(`user/detail`, { id: id }).then((res) => {
      if (res.success) {
        const newdata = res.data;
        setData(res?.data);
        if (res?.data?.role == 'staff') {
          ApiClient.get(`permission/user-base?user_id=${id}`).then((res) => {
            SetPermission(res?.data);
          });
        }
      }
      loader(false);
    });
  };

  const back = () => {
    history.goBack();
  };

  const edit = (id) => {
    let url = `/users1/edit/${id}`;
    if (role) url = `/users1/${role}/edit/${id}`;
    history.push(url);
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  const [EducationData, setEducationData] = useState([]);
  const GetEducation = () => {
    loader(true);
    ApiClient.get(`educations?userId=${id}`).then((res) => {
      if (res.success) {
        setEducationData(res.data);
      }
    });
  };
  const [EmployeementData, setEmoloyeementData] = useState([]);
  const GetEmployementData = () => {
    ApiClient.get(`employments?userId=${id}`).then((res) => {
      if (res.success) {
        setEmoloyeementData(res.data);
      }
    });
  };

  const [images, setimages] = useState([]);
  const [AccecptID, setAcceptID] = useState('');
  const [ProtofolioError, setProtofolioError] = useState(false);
  const [selectedBoards, setSelectedBoards] = useState([]);

  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);
  const [boards, setBoards] = useState([]);
  const [enableNote, setEnableNote] = useState(false);

  const getboards = () => {
    ApiClient.get('boards', { status: 'active' }).then((res) => {
      if (res.success) {
        setBoards(res?.data?.data);
      }
    });
  };

  const handleNotes = () => {
    setEnableNote(true);
  };

  useEffect(() => {
    getboards();
  }, []);

  const RejecetCarrier = (id) => {
    loader(true);
    ApiClient.put('carrier-request', { id: id, status: 'rejected' }).then(
      (res) => {
        if (res.success) {
          toast.success(res.message);
          getDetail({ search: '', page: 1 });
        }
        loader(false);
      }
    );
  };
  return (
    <Layout2 title2={`${Name ? 'Carrier' : 'Staff'}`}>
      <div className="mainareas">
        <div className="pprofile1 edit-page-common edit-page-commons ">
          <div className="row">
            <div className="col-lg-12">
              <div className=" title-head mb-0">
                <div className="d-flex justify-content-between align-items-center">
                  <div class=" title-head">
                    <h3
                      className="ViewUseraa mb-0 user-back"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <i
                        className="fa fa-arrow-left me-2 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                      {Name ? Name : 'Staff'} Detail
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="common-padding profile-height-set">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 height-set">
                    <div className=" white-bg-main ">
                      <div className=" white-head-inner ">
                        <div className=" white-head mb-3">
                          <h5 class="profilelist">Profile</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mb-3 ">
                          <div className="user-info-bx">
                            <div className="imagethumbWrapper w-100 new-width">
                              <img
                                src={methodModel.userImg(data && data.image)}
                                className="uploadimage  d-block"
                              />
                            </div>
                            <h4>
                              {data &&
                                methodModel.capitalizeFirstLetter(
                                  data.fullName
                                )}
                            </h4>

                            <div className=" email-text">
                              {data && data.email}
                            </div>
                            {data?.position && (
                              <div className="position-person">
                                {data.position == 'accounting_manager' ? (
                                  'Accounting Manager'
                                ) : (
                                  <>
                                    {data?.position == 'owner' ? (
                                      'Owner'
                                    ) : (
                                      <>
                                        {data?.position == 'manager' ? (
                                          'Manager'
                                        ) : (
                                          <>
                                            {data?.position == 'disptacher'
                                              ? 'Dispatcher'
                                              : ''}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-8 height-set">
                    <div className=" white-bg-main ">
                      <div className=" white-head mb-3">
                        <h5 class="profilelist">Address</h5>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                Address :
                              </label>
                              <div className="profiledetailscls address-w">
                                {methodModel.capitalizeFirstLetter(
                                  data?.address
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                City :
                              </label>
                              <div className="profiledetailscls">
                                {methodModel.capitalizeFirstLetter(
                                  data?.city
                                ) || '--'}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                State :
                              </label>
                              <div className="profiledetailscls">
                                {methodModel.capitalizeFirstLetter(
                                  data?.state
                                ) || '--'}
                              </div>
                            </div>

                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                Country :
                              </label>
                              <div className="profiledetailscls">
                                {methodModel.capitalizeFirstLetter(
                                  data?.country
                                ) || '--'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-xl-4  col-lg-12 height-set ">
                    <div className=" white-bg-main ">
                      <div className=" white-head mb-3">
                        <h5 class="profilelist">Basic Information</h5>
                      </div>
                      <div className="row">
                        {data?.dialCode && data?.mobileNo && (
                          <div className="col-md-12 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Mobile No :
                            </label>
                            <div className="profiledetailscls">
                              {/* {data?.dialCode?.split("+")?.length < 1
                                ? null
                                : "+"} */}{' '}
                              {data && data?.dialCode} {data?.mobileNo || '--'}
                            </div>
                          </div>
                        )}
                        {data?.telephoneExt && data?.telephoneNo && (
                          <div className="col-md-12 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Telephone :
                            </label>
                            <div className="profiledetailscls">
                              {/* {data?.telephoneExt?.split("+")?.length < 1
                                ? null
                                : "+"} */}{' '}
                              {data && data?.telephoneExt}{' '}
                              {data?.telephoneNo || '--'}
                            </div>
                          </div>
                        )}
                        {data &&
                        data.role &&
                        data.role._id == '6540cfa35a7513892be61415' ? (
                          <div className="col-md-12 mb-3 view-flex">
                            <label>Merchant Fee (%)</label>
                            <div className="profiledetailscls">
                              {data && data.merchant_fee}
                            </div>
                          </div>
                        ) : null}
                        {/* <div className="col-md-12 mb-3 view-flex">
                            <label className="profileheddingcls">Email:</label>
                            <div className="profiledetailscls">
                              {data && data.email}
                            </div>
                          </div> */}
                        {data?.company_name && (
                          <div className="col-md-12 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Company Name :
                            </label>
                            <div className="profiledetailscls">
                              {methodModel.capitalizeFirstLetter(
                                data?.company_name
                              ) || '--'}
                            </div>
                          </div>
                        )}
                        {data?.fax_number && (
                          <div className="col-md-12 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Fax Number :
                            </label>
                            <div className="profiledetailscls">
                              {methodModel.capitalizeFirstLetter(
                                data?.fax_number
                              ) || '--'}
                            </div>
                          </div>
                        )}
                        {data?.tax_number && (
                          <div className="col-md-12 mb-3 view-flex">
                            <label className="profileheddingcls profileheddingclsMatch">
                              Tax Number :
                            </label>
                            <div className="profiledetailscls">
                              {methodModel.capitalizeFirstLetter(
                                data?.tax_number
                              ) || '--'}
                            </div>
                          </div>
                        )}
                  
                        <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls profileheddingclsMatch">
                            Trailer Type :
                          </label>
                          <div className="profiledetailscls d-flex flex-wrap">
                            {data?.trailer_type?.map((itm) => {
                              return (
                                <>
                                  <span className="badge badge-primary me-2">
                                  {itm=="dry_van"?"dry van":itm}
                                  </span>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                        {data?.boardDetails?.length ? (
                          <>
                           <div className="   col-lg-12 height-set mt-4">
                    <div className=" white-bg-main ">
                      <div className=" white-head mb-3">
                        <h5 class="profilelist">Selected Boards</h5>
                      </div>
                      <div className="row">
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls profileheddingclsMatch">
                                Selected Board :
                              </label>
                              <div className="profiledetailscls d-flex flex-wrap">
                                {data?.boardDetails?.map((itm) => {
                                  return (
                                    <>
                                      <span className="badge badge-primary me-2">
                                        {itm.name}
                                      </span>
                                    </>
                                  );
                                })}
                              </div>
                            </div>

                      </div>
                    </div>
                  </div>
                          </>
                        ) : (
                          <></>
                        )}
           
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default UserDetails2;
