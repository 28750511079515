import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../../methods/methods";
import { roleType } from "../../../models/type.model";
import { Link, useHistory, useParams } from "react-router-dom";
import Layout from "../../../components/global/layout";
import statusModel from "../../../models/status.model";
import SelectDropdown from "../../../components/common/SelectDropdown";
import { toast } from "react-toastify";

import { useLocation } from "react-router-dom";
import Switch from "react-switch";
import environment from "../../../environment";
import GooglePlaceAutoComplete from "../../../components/common/GooglePlaceAutoComplete";
import PhoneInput from "react-phone-input-2";
import ImageUpload from "../../../components/common/ImageUpload";
import addressModel from "../../../models/address.model";
import { IoIosCamera } from "react-icons/io";
const AddEditCarrierUsers = () => {
  const { id, CarrierID } = useParams();

  const [form, setform] = useState(roleType);
  const history = useHistory();

  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const [images, setImages] = useState({ image: "" });

  const [emailErr, setEmailErr] = useState("");
  const [Boards, setBoards] = useState([]);

  const [emailLoader, setEmailLoader] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (
      !form?.firstName ||
      !form?.email ||
      !form?.address ||
      !form?.city ||
      !form?.country ||
      !form?.position ||
      // !form?.mobileNo ||
      !form?.pincode
    ) {
      return false;
    }
    loader(true);

    let url = "admin/add-user";
    let method = "post";
    let dvalue = {
      firstName: form?.firstName,
      lastName: form?.lastName,
      image: form?.image,
      address: form?.address,
      city: form?.city,
      country: form?.country,
      state: form?.state,
      pincode: form?.pincode,
      carrier_id: user?.id,
      mobileNo: form?.mobileNo,
      email: form?.email,
      dialCode: form?.dialCode,
      board_id: user.board_id,
      trailer_type: user.trailer_type,
      mc_description: user.mc_description,
      tax_number: user.tax_number,
      dot_description: user.dot_description,
    };
    let value = {
      ...dvalue,
      role: "user",
      position: form?.position,
    };
    if (id) {
      method = "put";
      url = "admin/edit-user";
      value = {
        id: id,
        ...dvalue,
      };
    }



    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        history.goBack();
      }
      loader(false);
    });
  };

  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  const GetUser = () => {
    setform({});
    loader(true);
    ApiClient.get("user/detail", { id: id }).then((res) => {
      if (res.success) {
        setform(res?.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    if (id) {
      GetUser();
    }
  }, [id]);

  useEffect(() => {
    setform({ ...form, permissions: roleType.permissions });
  }, [id]);


  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }

    setform({
      ...form,
      address: e.value,
      country: address.country || "",
      city: address.city || "",
      state: address.state || "",
      pincode: address.zipcode || "",
      // lat: `${address.lat}` || '',
      // lng: `${address.lng}` || ''
    });
  };

  const uploadImage = (e) => {
    setform({ ...form, baseImg: e.target.value });
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", { file: file }).then(
      (res) => {
        if (res.success) {
          let image = res?.data?.fullpath;
          setform({ ...form, image: image, baseImg: "" });
        } else {
          setform({ ...form, baseImg: "" });
        }
        loader(false);
      }
    );
  };

  const imageResult = (e, key) => {

    images[key] = e.value;
    setImages(images);
    setform({ ...form, image: images?.image });
   
  };

  const Carrier = () => {
    ApiClient.get("user/detail", { id: CarrierID }).then((res) => {
      if (res.success) {
        setform({ ...form, carrierName: res?.data?.fullName });
      }
    });
  };
  useEffect(() => {
    
    if (CarrierID) {
      Carrier();
    }
  }, []);
  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1 edit-page-common">
            <div className="row">
              <div className="col-lg-12">
                <div class=" title-head">
                  <h3
                    className="ViewUseraa mb-0 user-back"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <i
                      className="fa fa-arrow-left me-2 "
                      title="Back"
                      aria-hidden="true"
                    ></i>
                    {id ? "Edit" : "Add"} Staff
                  </h3>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class=" white-bg-main mb-4">
                  <div class=" white-head mb-3">
                    <h5 class="profilelist">Basic Information</h5>
                  </div>
                  <div className="row">
                    <div class="col-lg-3">
                      <div className="imagesRow col-md-3 mb-3">
                        <div className="imagethumbWrapper">
                          <img
                            src={
                              form?.image
                                ? `http://74.208.206.18:4020/images/users/${form?.image}`
                                : "/assets/img/person.jpg"
                            }
                            className="thumbnail"
                          />
                          <div className="upload-img-icon">
                            {form?.image ? (
                              <>
                                <label
                                  className={`btn  `}
                                  onClick={() => {
                                    setform({ ...form, image: "" });
                                  }}
                                >
                                  <i className="fa fa-times"></i>
                                </label>{" "}
                              </>
                            ) : (
                              <label className={`btn  `}>
                                <input
                                  type="file"
                                  className="d-none"
                                  accept="image/*"
                                  onChange={(e) => {
                                    uploadImage(e);
                                  }}
                                />
                                <IoIosCamera />
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-9">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label>
                            Carrier Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={methodModel.capitalizeFirstLetter(
                              user?.fullName
                            )}
                            disabled
                          // required
                          />
                          {/* {submitted && !form.firstName ? (
                        <div className="invalid-feedback d-block">
                          First Name is Required
                        </div>
                      ) : (
                        <></>
                      )} */}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>
                            First Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={form.firstName}
                            onChange={(e) =>
                              setform({ ...form, firstName: e.target.value })
                            }
                          // required
                          />
                          {submitted && !form.firstName ? (
                            <div className="invalid-feedback d-block">
                              First Name is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={form.lastName}
                            onChange={(e) =>
                              setform({ ...form, lastName: e.target.value })
                            }
                          // required
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label> Position</label>
                          <select
                            name=""
                            className="form-control"
                            id=""
                            value={form?.position}
                            onChange={(e) => {
                              setform({ ...form, position: e.target.value });
                            }}
                          >
                            <option value="">Select Position</option>
                            <option value="owner">Owner</option>
                            <option value="manager">Manager</option>
                            <option value="accounting_manager">
                              Account Manager
                            </option>
                            <option value="dispatcher">Dispatcher</option>
                          </select>
                          {submitted && !form.position ? (
                            <div className="invalid-feedback d-block">
                              Position is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="">Mobile No</label>
                          <div className="phoneInput_cls d-flex form-control p-0">
                            <PhoneInput
                              country={"us"}
                              value={form?.dialCode}
                              countryCodeEditable={false}
                              enableSearch={true}
                              placeholder=""
                              onChange={(phone, country) => {
                                setform({
                                  ...form,
                                  dialCode: country.dialCode,
                                });
                              }}
                            />

                            <input
                              type="text"
                              className="form-control phph"
                              placeholder="Mobile No."
                              value={(form && form.mobileNo) || ""}
                              maxLength={12}
                              onChange={(e) =>
                                setform({
                                  ...form,
                                  mobileNo: methodModel.isNumber(e),
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label>
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={form.email}
                            disabled={id}
                            onChange={(e) => {
                              setform({ ...form, email: e.target.value });
                              // emailCheck(e.target.value);
                            }}
                          // required
                          />
                          {submitted && !form.email ? (
                            <div className="invalid-feedback d-block">
                              Email is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <div class=" white-bg-main mb-4">
                  <div class=" white-head mb-3">
                    <h5 class="profilelist">Address</h5>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label>
                        Address<span className="text-danger">*</span>
                      </label>
                      <GooglePlaceAutoComplete
                        value={form.address}
                        result={addressResult}
                        id="address"
                        placeholder=""
                      />
                      {submitted && !form.address ? (
                        <div className="invalid-feedback d-block">
                          Address is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                        City<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={form.city}
                        onChange={(e) =>
                          setform({ ...form, city: e.target.value })
                        }
                      // required
                      />
                      {submitted && !form.city ? (
                        <div className="invalid-feedback d-block">
                          City is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                        state<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={form.state}
                        onChange={(e) =>
                          setform({ ...form, state: e.target.value })
                        }
                      // required
                      />
                      {submitted && !form.state ? (
                        <div className="invalid-feedback d-block">
                          State is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label>
                        Country<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={form.country}
                        onChange={(e) =>
                          setform({ ...form, country: e.target.value })
                        }
                      // required
                      />
                      {submitted && !form.country ? (
                        <div className="invalid-feedback d-block">
                          Country is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label>
                        Zipcode<span className="text-danger">*</span>
                      </label>
                      <input
                        type="numeric"
                        pattern="^[a-zA-Z0-9]+$"
                        onKeyPress={(e) => {
                          var regex = new RegExp("^[a-zA-Z0-9]+$");
                          var key = String.fromCharCode(
                            !e.charCode ? e.which : e.charCode
                          );
                          if (!regex.test(key)) {
                            e.preventDefault();
                            return false;
                          }
                        }}
                        className="form-control"
                        value={form.pincode}
                        onChange={(e) =>
                          setform({ ...form, pincode: e.target.value })
                        }
                      // required
                      />
                      {submitted && !form.pincode ? (
                        <div className="invalid-feedback d-block">
                          Zipcode is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-right">
              {
                <button
                  type="submit"
                  className="btn btn-primary text-end d-block btn-end-save"
                  onClick={() => {
                    setSubmitted(true);
                  }}
                >
                  Save
                </button>
              }
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEditCarrierUsers;
