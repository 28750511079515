import React, { useState, useEffect } from "react";

function CountdownTimer2({ expirationDate,TimerClass }) {
    const calculateTimeRemaining = () => {
        const now = new Date();
        const expiration = new Date(expirationDate);
        const difference = expiration - now;

        if (difference <= 0) {
            return { expired: true };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    if (timeRemaining.expired) 
        {
        return (
          
          <div className={`expired timer ${!TimerClass && "expiredfull "}`}>
            {TimerClass && (
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                class="me-2"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
              </svg>
            )}
            Expired
          </div>
        );
      }
    

    const { days, hours, minutes, seconds } = timeRemaining;

    if (days > 0) {
        return (
            <div>
                <div className="badge-success d-flex align-items-center timer-main">
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        class="me-2"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                    </svg>
                
                {days} day{days > 1 ? "s " : " "} left
                {/* {hours > 0 && <span>{hours} hour{hours > 1 ? 's ' : ' '}</span>}
        {minutes > 0 && <span>{minutes} minute{minutes > 1 ? 's ' : ' '}</span>}
        <span>{seconds} second{seconds !== 1 ? 's' : ''}</span> */}
            </div>
            </div>
        );
    } else {
        return (
            <div>
                <div className="badge-success d-flex align-items-center timer-main">
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        class="me-2"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                    </svg>
              

                {hours > 0 && hours}
                {hours > 0 && "hr"}
                {hours > 1 ? "s " : " "}
                {minutes > 0 && minutes}
                {minutes > 0 && "min"}
                {minutes > 1 ? "s " : " "}
                {seconds > 0 && seconds}
                {seconds > 0 && "sec"}
                {seconds > 1 ? "s" : ""}
            </div>
            </div>
        );
    }
}

export default CountdownTimer2;
