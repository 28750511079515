import React, { useState } from "react";
import Header from "../../components/global/layout/Header";
import WebsiteHeader from "../../components/global/WebsiteHeader";
import { Footer } from "antd/es/layout/layout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { toast } from "react-toastify";

function AboutUs() {
  const history = useHistory();
  const [form, setform] = useState({});

  const LetSubscribe = (e) => {
    loader(true);
    e.preventDefault();
    ApiClient.post("addNewsLetter", form).then((res) => {
      if (res.success) {
        loader(false);
        setform({ email: "" });

        toast.success(res?.message);
      }
      loader(false);
    });
  };
  return (
    <>
      <WebsiteHeader />

      <div className="page_wrapper mb-0">
        {/* Hero section */}

        <div className="about_hero">
          <div className="container d-flex justify-content-center align-items-center h-100">
            <h4 className="about_header">About Us</h4>
          </div>
        </div>

        <div className="container">
          {/* Story section */}
          <div className="about_div">
            <h6 className="story_header">Our Story</h6>
            <p className="story_desc">
              Welcome to One Brokerage LLC, your premier partner in asset-based logistics and transportation services. With a robust fleet of over 800 trucks and a vast array of trailers, we provide flexible and reliable freight solutions across the nation.
            </p>
            <p className="story_desc">
              Our Services

              At One Brokerage LLC, we offer a comprehensive suite of transportation services tailored to meet the diverse needs of our clients:

              Solo and Team Transport: Whether you need a dedicated solo driver or a team for faster delivery, we have the resources to meet your requirements.
            </p>
            <p className="story_desc">Expedited Services: For time-sensitive shipments, our expedited transport services ensure your freight arrives when needed.</p>
            <p className="story_desc">
              White Glove Services: We go beyond traditional delivery with our white glove services, offering end-to-end handling of delicate and high-value shipments with the utmost care.
              Freight Management and Forwarding: Our expertise extends to managing complex logistics operations, ensuring efficient freight movement across multiple channels.
            </p>
            <p className="story_desc">
              Consulting Services: We provide expert consulting on freight management facility optimization, helping you streamline operations and increase efficiency.
              Our Commitment {""}
              Our direct access to a large fleet not only allows us to offer competitive rates but also ensures the highest level of service. We prioritize transparency and communication, providing live tracking for all shipments and 24/7 support. Each client benefits from a dedicated customer representative, ensuring personalized service and rapid response to any need.
            </p>

            {/* <p className="story_desc">At One Brokerage LLC, we are more than just a logistics provider. We are your strategic partner in driving operational excellence and supporting the growth of your business. Trust us to deliver not just your cargo, but also peace of mind and logistical brilliance.</p> */}
          </div>

          {/* hero section */}
          <div className="about_div">
            <div className="row">
              {/* <div className="col-md-7">
                {" "}
                <div className="hero_section">
                  <div>
                    <img
                      src="/assets/img/best1.png"
                      className="best_images"
                    />
                  </div>
                  <div className="">
                    <img
                      src="/assets/img/about2.png "
                      className="aboutImg best_images"
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-lg-7">
                <div>
                  <img
                    src="/assets/img/best-group.png"
                    className="img-fluid best_images"
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <p className="why_use">
                  Why Choose us <div className="choose_bar"></div>{" "}
                </p>
                <h5 className="best_right">Why we are the best?</h5>
                <p className="right_desc">
                  At One Brokerage LLC, we are more than just a logistics provider. We are your strategic partner in driving operational excellence and supporting the growth of your business. Trust us to deliver not just your cargo, but also peace of mind and logistical brilliance.
                </p>
                {/* <p className="right_desc mt-5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Varius sed pharetra dictum neque massa congue
                </p> */}
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="best_cards">
                      <div className="d-flex align-items-center">
                        <img
                          src="assets/img/Timer.svg"
                          class="short_cards"
                          alt="logo"
                        />
                        <span className="cards_title">Full Time Jobs</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="best_cards">
                      <div>
                        <img
                          src="assets/img/Timer.svg"
                          class="short_cards"
                          alt="logo"
                        />
                        <span className="cards_title">Longer Contracts</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="best_cards">
                      <div>
                        <img
                          src="assets/img/Timer.svg"
                          class="short_cards"
                          alt="logo"
                        />
                        <span className="cards_title">Real Time Payments</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="best_cards">
                      <div>
                        <img
                          src="assets/img/Factory.svg"
                          class="short_cards"
                          alt="logo"
                        />
                        <span className="cards_title">Assurance</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button className="btn dark-btn w-auto mt-5 px-4">
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Management section */}
          {/* <div className="about_div">
            <div className="row">
              <div className="col-xl-6 trending_col">
                <h6 className="story_header mt-md-5">Trending Management</h6>
                <p className="story_desc mb-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque diam pellentesque bibendum non dui volutpat fringilla
                  bibendum. Urna, elit augue urna, vitae feugiat pretium donec
                  id elementum. Ultrices mattis sed vitae mus risus. Lacus nisi,
                  et ac dapibus sit eu velit in consequat.
                </p>
                <p className="story_desc mb-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Varius sed pharetra dictum neque massa congue
                </p>
                <p className="story_desc mb-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque diam pellentesque bibendum non dui volutpat fringilla
                  bibendum. Urna, elit augue urna, vitae feugiat pretium donec
                  id elementum. Ultrices mattis sed vitae mus risus. Lacus nisi,
                  et ac dapibus sit eu velit in consequat.
                </p>
                <div>
                  <button className="btn dark-btn w-auto mt-4 px-4">
                    Learn More
                  </button>
                </div>
              </div>
              <div className=" col-xl-6  ">
                <div className="d-flex">
                  <div>
                    <div className="d-flex manage_child_flex">
                      {" "}
                      <div>
                        <img src="assets/img/m1.png" class=" m_images img_m1" />
                      </div>
                      <div className=" text-lg-end mt-md-5 mt-3 flex_assests">
                        <img
                          src="assets/img/m2.png"
                          class=" m_images img_m2 mb-3"
                        />
                        <img src="assets/img/m3.png" class=" m_images img_m3" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        {/* Grow Section */}
        <div className="about_div">
          <div className="grow_parent">
            <div className="container d-flex justify-content-center align-items-center flex-column h-100">
              <p className="grow_desc">
                Learn more about latest offers available onsite{" "}
              </p>
              <h4 className="grow-header">Grow business outcomes</h4>
              <div>
                <button
                  onClick={() => {
                    history.push("/signup");
                  }}
                  className="btn dark-btn w-auto mt-md-5 mt-2 px-4"
                >
                  Register Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="footer_section section-padding">
        <div class="container">
          <div class="row ">
            <div class="col-12 col-sm-12 col-md-12  col-lg-8">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-5 col-lg-5">
                  <div class="footer_space">
                    <img
                      src="assets/img/footer_logo.png"
                      class="img-fluid"
                      alt="logo"
                    />
                    <p class="footer_details">
                      Your trusted partner in transportation, delivering results that move businesses forward.

                    </p>
                    <ul class="footer_iconlist">
                      <li>
                        <a href="#">
                          <div class="footer_img ">
                            <img
                              src="assets/img/youtube.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="assets/img/twitter.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="assets/img/insta.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="assets/img/facebook.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-7">
                  <div class="row">
                    <div class="col-12 col-sm-5 col-md-5 col-lg-5">
                      <div class="footer_space">
                        <h5 class="footer_heading">Quick Links</h5>

                        <ul class="footer_items">
                          <li>
                            <a href="#"> About Us</a>
                          </li>
                          <li>
                            <a href="#"> Service</a>
                          </li>
                          <li>
                            <a href="/support"> Support</a>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div class=" col-12 col-sm-7 col-md-7 col-lg-7">
                      {/* <div class="footer_space">
                        <h5 class="footer_heading">Contact Us</h5>

                        <ul class="footer_items">
                          <li>
                            <div class="footer_list">
                              <img
                                src="assets/img/message.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">hello@website.com</p>
                            </div>
                          </li>
                          <li>
                            <div class="footer_list">
                              <img
                                src="assets/img/map.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">
                                Riverside Building, County Hall, London SE1 7PB,
                                United Kingdom
                              </p>
                            </div>
                          </li>
                          <li>
                            <div class="footer_list">
                              <img
                                src="assets/img/call.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">+02 5421234560</p>
                            </div>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-4">
              <div class="footer_space">
                <h5 class="footer_heading">Newsletter</h5>
                <div class="position-relative">

                  <form onSubmit={LetSubscribe}>
                    <input
                      type="email"
                      value={form?.email}
                      required
                      onChange={(e) =>
                        setform({ ...form, email: e.target.value })
                      }
                      class="form-control enter_mail"
                      placeholder="Enter your email"
                    />
                    <button class="subscribe_btn">Subscribe</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default AboutUs;
