import React, { useEffect, useState } from "react";
import WebsiteHeader from "../../components/global/WebsiteHeader";
import Footer from "../../components/global/Footer/footer";
import ApiClient from "../../methods/api/apiClient";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaCircleExclamation } from "react-icons/fa6";
import { FaRegClock } from "react-icons/fa";
import ApplyModal from "./ApplyModal";
import loader from "../../methods/loader";

function JobView() {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [timeRemaining, setTimeRemaining] = useState("");

  const [createdDate, setCreated] = useState(0);
  const [ExpiredDate, setExpiredDate] = useState(0);
  const [FinalDate, setDate] = useState(0);
  const [data, setData] = useState({});
  const { id } = useParams();
  const [BidStatus, setBidStatus] = useState(false);
  const [LeftDays, setLeftDays] = useState(0);
  const [Trucks, setTruck] = useState([]);
  const [Hour, setHour] = useState(0);
  const [showItem, setShowItem] = useState({});

  const toggleShowItem = (index) => {
    setShowItem({ ...showItem, [index]: !showItem[index] });
  };
  let isBig =
    moment().format("DD-MM-YYYY HH:MM") >
    moment(data?.expiration_date).format("DD-MM-YYYY HH:MM");

  const GetLoad = () => {
    loader(true);
    ApiClient.get(`load`, { id, user_id: user?.id }).then((res) => {
      if (res.success) {
        setData(res?.data);
        setCreated(res?.data?.createdAt);
        setExpiredDate(res?.data?.board_id?.expiration_date);
      }
      setTimeout(() => {
        loader(false);
      }, 2000);
    });
  };

  const GetTrucks = () => {
    ApiClient.get("trucks").then((res) => {
      if (res.success) {
        setTruck(res?.data?.data);
      }
    });
  };

  const Timeleft = () => {
    let Created = new Date(ExpiredDate);

    let expired = new Date();

    let Total = Created.getTime() - expired.getTime();
    let LeftDay = Math.round(Total / (1000 * 3600 * 24));
    setLeftDays(LeftDay);
    // Timeleft()
  };

  const StartTimer = () => {
    let Created = new Date(ExpiredDate);
    let expired = new Date();
    let Total = Created.getTime() - expired.getTime();

    const days = Math.floor((Total / (1000 * 60 * 60)) * 24);

    const hours = Math.floor(Total / (1000 * 60 * 60));
    const minutes = Math.floor((Total % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((Total % (1000 * 60)) / 1000);
    setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
    setHour(hours);
  };

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      StartTimer();
      Timeleft();
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining]);

  useEffect(() => {
    Timeleft();

    let UpdatedDate = new Date(ExpiredDate);
    setDate(UpdatedDate);
  }, [data]);

  useEffect(() => {
    if (id && user?.id) {
      GetLoad();
    }
  }, [user]);

  return (
    <>
      <WebsiteHeader />

      {BidStatus ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <img
            style={{ borderRadius: "50%" }}
            src="https://cdn.dribbble.com/users/253392/screenshots/6906291/check.gif"
            alt=""
          />
        </div>
      ) : (
        <div className=" main_container">
          <div className="container ">
            <div className="header_nav">
              <span
                className="headerNav_child"
                onClick={() => history.push("/")}
              >
                Home
              </span>
              <span
                className="headerNav_child"
                onClick={() => history.push("/jobs1")}
              >
                Loads
              </span>
              <span className="headerNav_child headerNav_child_active">
                {data?.origin_location_city} to {data?.destination_address}
              </span>
            </div>
            <div className="first_child_jobs">
              {!loader && (
                <div className="d-flex justify-content-between ">
                  <h4 className="ware_header">
                    {data?.origin_location_city} to {data?.destination_address}
                  </h4>
                  {LeftDays < 1 && Hour >= 0 ? (
                    <div className="d-flex gap-4 align-items-center hours-left">
                      {timeRemaining && (
                        <span className="d-flex align-items-center">
                          <FaRegClock className="me-2" />
                          Hours Left:
                        </span>
                      )}

                      {timeRemaining && (
                        <span className="">{timeRemaining}</span>
                      )}
                    </div>
                  ) : null}
                  {timeRemaining == "0h 0m 0s" ||
                    (Hour < 0 && (
                      <div className="expired-job">
                        <FaCircleExclamation />

                        <span>Expired</span>
                      </div>
                    ))}
                </div>
              )}

              <div className="d-flex flex-wrap gap-2 my-2">
                {/* <div className="d-flex gap-4 align-items-center   ">
                  <span className="quentities">Range:</span>
                  <span className="ranges">
                    ${data?.min_rate} - ${data?.max_rate}
                  </span>
                </div> */}
                {data?.total_distance?<>
                  <div className="d-flex gap-4 align-items-center  ">
                  <span className="quentities">Distance (in Mi):</span>
                  <span className="ranges">{data?.total_distance}</span>
                </div>
                </>:<></>}

               
                
                  {data?.load_id ? (
                    <div className="d-flex gap-4 align-items-center  ">
                      <span className="quentities">Load ID:</span>

                      <span className="ranges me-2">{data?.load_id} </span>
                    </div>
                  ) : null}
                  {data?.lane_id ? (
                    <div className="d-flex gap-4 align-items-center  ">
                      <span className="quentities">Lane ID:</span>

                      <span className="ranges me-2">{data?.lane_id} </span>
                    </div>
                  ) : null}

                  {data?.all_in_rate ? <>
                    <div className="d-flex gap-4 align-items-center  ">
                      <span className="quentities">All in Rate:</span>
                      <span className="ranges">{data?.all_in_rate}</span>
                    </div>
                  </> : <></>}

                  {data?.capacity ? <>
                    <div className="d-flex gap-4 align-items-center  ">
                      <span className="quentities">Capacity:</span>
                      <span className="ranges">{data?.capacity}</span>
                    </div>
                  </> : <></>}

                  {data?.dispatch_days ? <>
                    <div className="d-flex gap-4 align-items-center  ">
                      <span className="quentities">Dispatch Days:</span>
                      <span className="ranges">{data?.dispatch_days}</span>
                    </div>
                  </> : <></>}

                {data?.est_volume?<>
                  <div className="d-flex gap-4 align-items-center  ">
                  <span className="quentities">EST Volume:</span>
                  <span className="ranges">{data?.est_volume}</span>
                </div>
                </>:<></>}

                {data?.frequency?<>
                  <div className="d-flex gap-4 align-items-center  ">
                  <span className="quentities">Frequency:</span>
                  <span className="ranges">{data?.frequency}</span>
                </div>
                </>:<></>}

               

                {/* <div className="d-flex gap-4 align-items-center  ">
                  <span className="quentities">Total Pallets:</span>

                  <span className="ranges me-2">{data?.total_pallets} </span>
                </div>
                <div className="d-flex gap-4 align-items-center  ">
                  <span className="quentities">Total Volume:</span>

                  <span className="ranges me-2">{data?.total_volume} </span>
                </div>

                <div className="d-flex gap-4 align-items-center  ">
                  <span className="quentities">Total Pieces:</span>

                  <span className="ranges me-2">{data?.total_pieces}</span>
                </div> */}
              </div>

              <div>
                <div className="d-flex flex-wrap gap-2 my-2">
                  <div className="d-flex gap-4 align-items-center">
                    <span className="quentities">Start Date:</span>

                    <span className="ranges">
                      {moment(data?.load_start_date||data?.pu_date_time).format("DD-MMM-YYYY")}
                    </span>
                  </div>
                  {data?.load_end_date?<>
                    <div className="d-flex gap-4 align-items-center ">
                    <span className="quentities">End Date:</span>

                    <span className="ranges">
                      {moment(data?.load_end_date).format("DD-MMM-YYYY")}
                    </span>
                  </div>
                  </>:<></>}
                 
                  {LeftDays > 1 && (
                    <div className="d-flex gap-4 align-items-center ">
                      <span className="quentities">Day Left:</span>

                      <span className="ranges">{LeftDays}</span>
                    </div>
                  )}
                </div>
                <div className=" my-2">
                  <div className="d-flex gap-4 align-items-center">
                    <span className="quentities">Origin Address:</span>

                    <span className="ranges">
                      {data?.origin_address || "--"}
                    </span>
                  </div>
                  <div className="d-flex gap-4 align-items-center my-2  ">
                    <span className="quentities">Destination Address:</span>

                    <span className="ranges">
                      {data?.destination_address || "--"}
                    </span>
                  </div>
                </div>
              </div>
              {data?.stops?.length>0?
                  <p className="warehouse_desc">
                  <div className="d-flex gap-4 align-items-center ">
                    <span className="quentities">Stops :</span>
                    {data?.stops?.map((itm) => {
                      return (
                        <span className="ranges">
                          {itm?.address?.substr(0, showItem?.address ? 200 : 23)}
                          {data?.address?.length > 23 && (
                            <span
                              className="see-more"
                              onClick={() => {
                                toggleShowItem("address");
                              }}
                            >
                              {showItem?.address ? "see less" : "see more"}
                            </span>
                          )}
                        </span>
                      );
                    })}
                  </div>
                  </p>:<></>
              }
             
              <div className="warehouse_buttons d-flex gap-3 mt-4">
                {new Date().getTime >
                new Date(data?.expiration_date).getTime() ? (
                  <>
                    <button
                      className="btn btn-danger"
                      disabled={data.isBidSubmitted}
                    >
                      Expired
                    </button>
                  </>
                ) : (
                  <>
                    {!data?.isBidSubmitted ? (
                      <button
                        disabled={timeRemaining == "0h 0m 0s" || Hour < 0}
                        type="button"
                        className="btn dark-btn dark-btn-width"
                        onClick={() => {
                          if (user.loggedIn) {
                            document.getElementById("applyModalClick").click();
                          } else {
                            history.push("/login");
                          }
                        }}
                      >
                        Apply Now
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn dark-btn dark-btn-width"
                      >
                        Applied
                      </button>
                    )}
                  </>
                )}
              </div>
              <div className="d-flex justify-content-between wares_flex">
                <div className="warehouse_footer mt-4 d-flex gap-3  flex-wrap ">
                  <span className="d-flex align-items-center gap-3  warehouse_footer_child">
                    {/* <img src="/assets/img/loc.svg"  /> */}
                    <i class="fa fa-map-marker" aria-hidden="true"></i>{" "}
                    <span className="warehouse_location">
                      {data?.origin_location_state} (
                      {data?.origin_location_country})
                    </span>{" "}
                  </span>
                  {/* <span className="d-flex align-items-center gap-3 warehouse_footer_child">
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                    <span className="warehouse_location">
                      29 Companies Applied
                    </span>{" "}
                  </span> */}
                  {/* <span className="d-flex align-items-center gap-3 warehouse_footer_child">
                    {" "}
                    <i class="fa fa-truck" aria-hidden="true"></i>{" "}
                    <span className="warehouse_location">
                      {} Vehicles Required
                    </span>{" "}
                  </span> */}
                </div>
              </div>
            </div>

            {/* <div className="mt-5 response_parent pe-0">
              <div className="row">
                <div className="col-lg-9 col-md-12">
                  <h5 className="warehouse_respons">Responsibilities</h5>
                  <p className="warehouse_desc2 mt-3">
                    <ul>
                      <li>
                        A carrier has the duty to ensure that the goods are
                        properly loaded and secured for transport.
                      </li>
                      <li>
                        {" "}
                        It is the carrier's responsibility to handle the goods
                        with care and prevent any damage during transportation.
                      </li>
                      <li>
                        {" "}
                        The carrier must follow all safety regulations and
                        guidelines to safeguard the goods in transit.
                      </li>
                      <li>
                        {" "}
                        In case of any delays or issues during transport, the
                        carrier should promptly inform the relevant parties and
                        take necessary actions to mitigate the situation.
                      </li>
                      <li>
                        The carrier is accountable for the safe delivery of the
                        goods to the designated destination in the agreed-upon
                        condition.
                      </li>
                      <li>
                        Proper documentation and communication regarding the
                        status of the goods should be maintained by the carrier
                        throughout the transportation process.
                      </li>
                      <li>
                        Any unforeseen circumstances or incidents that may
                        affect the delivery of the goods should be promptly
                        addressed by the carrier to minimize disruptions.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div> */}

            {/* <div className="row response_parent">
              <div className="col-md-6 ">
                <div className="list_parent">
                  <ul>
                    <li className="ware_list">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    </li>
                    <li className="ware_list">
                      Aliquam tincidunt mauris eu risus.
                    </li>
                    <li className="ware_list">
                      Vestibulum auctor dapibus neque.
                    </li>
                    <li className="ware_list">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    </li>
                    <li className="ware_list">
                      Aliquam tincidunt mauris eu risus.
                    </li>
                    <li className="ware_list">
                      Vestibulum auctor dapibus neque.
                    </li>
                    <li className="ware_list">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    </li>
                    <li className="ware_list">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    </li>
                    <li className="ware_list">
                      Aliquam tincidunt mauris eu risus.
                    </li>
                    <li className="ware_list">
                      Vestibulum auctor dapibus neque.
                    </li>
                    <li className="ware_list">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    </li>
                    <li className="ware_list">
                      Aliquam tincidunt mauris eu risus.
                    </li>
                    <li className="ware_list">
                      Vestibulum auctor dapibus neque.
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>

          <ApplyModal
            data={data}
            result={(e) => {
              if (e.event == "submit") {
                setBidStatus(true);
                // GetLoad();
                setTimeout(() => {
                  setBidStatus(false);
                  GetLoad();
                }, 2000);
              }
            }}
          />
        </div>
      )}

      <Footer />
    </>
  );
}

export default JobView;
