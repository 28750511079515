import React, { useState } from 'react';
import Layout2 from '../../components/global/Layout2';
import Pagination from 'react-pagination-js';
// import "./style.scss";
import rolesModel from '../../models/roles.model';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import environment from '../../environment';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import CommonDeleteModal from '../CommonDelete&ActiveModal/CommonDeleteModal';
import CommonActiveModal from '../CommonDelete&ActiveModal/CommonActiveModal';
import { toast } from 'react-toastify';
import ApiClient from '../../methods/api/apiClient';
import { Tooltip } from 'antd';
import moment from 'moment';

const Html = ({
  view,
  edit,
  user,
  ChangeFilter,
  reset,
  RollBack,
  sorting,
  add,
  colClick,
  tab,
  tabChange,
  ChangeRole,
  ChangeStatus,
  openModal,
  statusChange,
  pageChange,
  addCol,
  deleteItem,
  exportCsv,
  uTableCols,

  removeCol,
  filters,
  ChangeDocumentStatus,
  tableCols,
  setFilter,
  blockunblock,
  loaging,
  getData,
  data,
  exportfun,
  roles,
  role,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  total = { total },
}) => {
  const Navigate = useHistory();

  const [DeleteId, setDeleteId] = useState('');
  const Delete = () => {
    deleteItem(DeleteId);
  };
  const [TAB, SETTAB] = useState('list');
  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };
  const columns = ['description', 'createdAt'];
  const [visibleColumns, setVisibleColumns] = useState(['description']);

  const handleColumnToggle = (columnName) => {
    // Check if the column is currently visible
    const isColumnVisible = visibleColumns.includes(columnName);

    // Toggle the column visibility
    const updatedColumns = isColumnVisible
      ? visibleColumns.filter((col) => col !== columnName)
      : [...visibleColumns, columnName];

    setVisibleColumns(updatedColumns);
  };

  function replaceUnderscoresWithSpace(inputString) {
    // Use the replace method with a regular expression to replace underscores with spaces
    const resultString = inputString.replace(/_/g, ' ');

    return resultString;
  }

  const ColumnReturner = (data, value, itm) => {
    switch (data) {
      case 'carrier_name':
        return value;
        break;
      case 'agreed_rate':
        return itm?.carrier_info?.agreed_rate;
        break;
      case 'createdAt':
        return datepipeModel.date(value);
        break;
      case 'origin_location_city':
        return itm?.load_info?.origin_location_city;
        break;
      case 'destination_location_city':
        return itm?.load_info?.destination_location_city;
        break;
      case 'createdAt':
        return datepipeModel.date(value);
        break;
      case 'description':
        return methodModel.capitalizeFirstLetter(itm?.description);
        break;
      // case "status":
      //     return <select class="custom-select text-capitalize" disabled={itm?.status !== "pending"} value={itm?.status} onChange={e => statusChange(itm, e.target.value)}>
      //         <option>{itm?.status}</option>
      //         <option value="accepted">Accept</option>
      //         <option value="cancelled">Reject</option>
      //     </select>
      //     break;
      default:
        return value;
        break;
    }
  };

  function findUniqueElements(arr1, arr2) {
    const uniqueInArr1 = arr1.filter((item) => !arr2.includes(item));
    const uniqueInArr2 = arr2.filter((item) => !arr1.includes(item));

    const uniqueElements = [...uniqueInArr1, ...uniqueInArr2];

    return uniqueElements;
  }
  const Permission = JSON.parse(localStorage.getItem('permission'));
  const Role = [
    {
      key: 'staff',
      name: 'Staff',
    },
    {
      key: 'carrier',
      name: 'Carrier',
    },
  ];
  let ListingData = [];
  if (user?.role == 'staff') {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }
  return (
    <Layout2 title="Shipment Notes" title2="Shipment Notes">
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={StatusCh}
        status={StatusData.status}
      />
      <div className="mainareas">
        <div className="staff-full">
          <div className="d-flex justify-content-between align-items-center mb-4 wrapflex">
            <div className="bids-top">
              <h5 className="mb-0">
                Results <span>{total}</span>
              </h5>
            </div>
          </div>
          {/* <div className="dropdown addDropdown chnagesg  d-flex align-items-center equal-width-50 mt-0">
                <button
                  className="btn dropdown-toggle removeBg blck-border-btn mb-0 "
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {filters.status
                    ? filters.status == "deactive"
                      ? "Inactive"
                      : filters.status
                    : "All Status"}
                </button>
                <div
                  className="dropdown-menu shadow bg_hover"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    className={
                      filters.status == ""
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                    onClick={() => ChangeStatus("")}
                  >
                    All Status
                  </a>
                  <a
                    className={
                      filters.status == "active"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                    onClick={() => ChangeStatus("active")}
                  >
                    Active
                  </a>
                  <a
                    className={
                      filters.status == "Inactive"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                    onClick={() => ChangeStatus("deactive")}
                  >
                    Inactive
                  </a>
                </div>
              </div> */}
          <>

            <div>
              <div className="table-responsive table_section job-sect-table new-table">
                <table class="table ">
                  <thead className="table_head">
                    <tr className="heading_row">
                      <th scope="col" className="table_data pointer">
                        Carrier Name
                      </th>
                      <th scope="col" className="table_data pointer">
                        Title
                      </th>
                      <th scope="col" className="table_data pointer">
                        Description
                      </th>

                      <th scope="col" className="table_data pointer">
                        New ETA
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {!loaging &&
                      ListingData &&
                      ListingData.map((itm, i) => {
                        return (
                          <tr>
                            <td>
                              {methodModel.capitalizeFirstLetter(
                                itm?.addedBy_name
                              )}
                            </td>
                            <td> {methodModel.capitalizeFirstLetter(itm?.title)}</td>
                            <td>
                              {itm?.description}
                            </td>

                            <td>
                            {datepipeModel.date(itm?.new_eta)},{" "}
                            {datepipeModel.isotime(itm?.new_eta)}
                        
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>

          </>

          {!loaging && total == 0 ? (
            <div className=" no-data">
              <img src="assets/img/no-data.png" />
              No Data
            </div>
          ) : (
            <></>
          )}

          {!loaging && total > 0 ? (
            <div className="paginationWrapper main-pagination page-new">
              <div className="d-flex align-items-center">
                <div className="me-2 user-name-color">Show</div>
                {total > 0 && (
                  <div className="dropdown addDropdown chnagesname ">
                    <select
                      className="form-control"
                      value={filters?.count}
                      onChange={(e) => {
                        setFilter({ ...filters, count: e.target.value });
                        getData({ ...filters, count: e.target.value });
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="30">30</option>
                    </select>
                    <div
                      className="dropdown-menu shadow bg_hover"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 5 });
                        }}
                      >
                        5
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 10 });
                        }}
                      >
                        10
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 20 });
                        }}
                      >
                        20
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 30 });
                        }}
                      >
                        30
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 40 });
                        }}
                      >
                        40
                      </a>
                    </div>
                  </div>
                )}{' '}
                <div className="ms-2 user-name-color">from {total} Shipment Notes</div>
              </div>

              <div className="">
                <Pagination
                  currentPage={filters.page}
                  totalSize={total}
                  sizePerPage={filters.count}
                  changeCurrentPage={pageChange}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          {loaging ? (
            <div className="text-center py-4">
              <img src="/assets/img/loader.gif" className="pageLoader" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Layout2>
  );
};

export default Html;
