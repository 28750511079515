import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import "./home.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory,Link } from "react-router-dom/cjs/react-router-dom.min";
import WebsiteHeader from "../../components/global/WebsiteHeader";
import addressModel from "../../models/address.model";
import { toast } from "react-toastify";
import { login_success } from "../../actions/user";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { message } from "antd";

const Home = () => {
  // Get the query string from the URL
  const queryString = window.location.search;

  // Create a new URLSearchParams object with the query string
  const searchParams = new URLSearchParams(queryString);

  // Get the value of a specific parameter, for example 'q'
  const id = searchParams.get("id");
  const user = useSelector((state) => state.user);

  const websitedetails = useSelector((state) => state.website);
  const [form, setform] = useState({});
  const [form2, setform2] = useState({});
  const [google, setgoogle] = useState(false);
  const [data, setData] = useState([]);
  const [total, settotal] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [filters, setFilter] = useState({ city: "" });
  const [datafaq, setdatafaq] = useState([]);

  const Getdata = () => {
    loader(true);
    ApiClient.get("faq/all").then((res) => {
      if (res.success) {
        setdatafaq(res?.data?.data);
        loader(false);
      }
    });
  };

  useEffect(() => {
    Getdata();
  }, []);

  const GetLoads = () => {
    setgoogle(true);
    ApiClient.get("loads", { count: 6 }).then((res) => {
      if (res.success) {
        setTimeout(() => {
          setgoogle(false);
        }, 1000);
        setData(res?.data?.data);
        settotal(res?.data?.total);
        // ExtractState(res?.data?.data);
      }
    });
  };

  const LetSubscribe = (e) => {
    loader(true);
    e.preventDefault();
    ApiClient.post("addNewsLetter", form).then((res) => {
      if (res.success) {
        loader(false);
        setform({ email: "" });

        toast.success(res?.message);
      }
      loader(false);
    });
  };

  useEffect(() => {
    localStorage.removeItem("Step1");
    localStorage.removeItem("Step2");
    GetLoads();
  }, []);

  const DestinationAddress = async (e) => {

    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }

    setFilter({
      ...filters,
      address: e.value || "",
      city: address.city || "",
      state: address.state || "",
      country: address.country || "",
      pincode: address.zipcode || "",
      lat: `${address.lat}` || "",
      lng: `${address.lng}` || "",
    });
  };

  useEffect(() => {
    if (id) {
      loader(true);
      localStorage.clear();
      ApiClient.post("user/auto-login", { id: id }).then((response) => {
        if (response.success) {
          dispatch(login_success(response.data));
          localStorage.setItem("token", response.data.access_token);
          toast.success(response.message);
          const newdata = response.data;
          ApiClient.get("trucks").then((res) => {
            if (res?.data?.data?.length == 0) {
              history.push("/trucks/add");
            } else {
              history.push("/profile");
            }
          });
        }
        loader(true);
      });
    }
    localStorage.removeItem("Step1");
    localStorage.removeItem("Step2");
  }, []);

  const HandleSubmit = () => {
    // if (filters?.keyword && filters?.city) {
    // history.push(
    //   `/jobs?keyword=${filters?.keyword}&lat=${filters?.lat}&lng=${filters?.lng}`
    // );
    // } else {
  
    if(user?.loggedIn){
      if (filters?.keyword) 
        {
          history.push(`/jobs?search=${filters?.keyword}`);
        }
        else
        {
          history.push(`/jobs`);
        }
     
    }
    else
    {
      history.push(`/login`);
    }
    // if (filters?.city) {
    //   history.push(`/jobs?city=${filters?.city}`);
    // }
    // if (filters?.city && filters?.keyword) {
    //   history.push(
    //     `/jobs?search=${filters?.keyword}${
    //       filters?.city ? `&city=${filters?.city}` : ""
    //     }`
    //   );
    // }
    // toast.error("Please Fill Location and Search");
    // }
  };
  const contactus=(e)=>
  {
    loader(true)
    e.preventDefault()
    ApiClient.post("contact-us",form2).then((response) => {
      if (response.success) {
        setform2({
          firstName:"",
          email:"",
          message:""
        })
        toast.success(response?.message);
      }
      loader(true);
    });
  }

  // const ExtractState = (loadData) => {
  //   loadData?.map((itm) => {

  //     let city = itm?.destination_address?.split(",")[0].split(" ")[1];
  //     let state = itm?.destination_address?.split(",")[1].split(" ")[0];

  //   });
  //   // return {
  //   //   city,
  //   //   state,
  //   // };
  // };
  return (
    <>
      <div className="wrapper">
        <WebsiteHeader />

        <section
          className="banner-section"
          style={{ backgroundImage: "url('assets/img/bgbanner.png')  " }}
        >
          <div className="container padbottom">
            <div className="row reversecol">
              <div className="col-lg-6 col-md-6">
                <div className="banner-img pt-5">
                  <img
                    src="assets/img/leftbaaner.png"
                    className="py-5 bannerleft"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="banner-right-section">
                  <h1>Looking for a load?</h1>
                  <p>
                    Search for loads and setup with us to gain access for more
                    visibility.
                  </p>
                  <div className="home-searchbar">
                    {/* <div class="input-group ">
                      <span class="input-group-text" id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                          fill="none"
                        >
                          <path
                            d="M10.9206 17.8712C15.007 17.8712 18.3197 14.5585 18.3197 10.4721C18.3197 6.3857 15.007 3.073 10.9206 3.073C6.8342 3.073 3.5215 6.3857 3.5215 10.4721C3.5215 14.5585 6.8342 17.8712 10.9206 17.8712Z"
                            stroke="#3F559E"
                            stroke-width="1.38733"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.1694 19.7208L16.1462 15.6975"
                            stroke="#3F559E"
                            stroke-width="1.38733"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <input
                        type="search"
                        class="keysearch"
                        placeholder="Job title, Keyword..."
                        aria-label="Username"
                        disabled
                        // value={filters?.keyword}
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          setFilter({ ...filters, keyword: e.target.value });
                        }}
                      />
                    </div> */}
                    <div className="border-btm d-none"></div>

                    <div class="input-group ">
                      <span class="input-group-text" id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_19_13)">
                            <path
                              d="M19.5375 9.54715C19.5375 16.0214 11.2135 21.5707 11.2135 21.5707C11.2135 21.5707 2.88953 16.0214 2.88953 9.54715C2.88953 7.33949 3.76652 5.22224 5.32757 3.66119C6.88863 2.10014 9.00587 1.22314 11.2135 1.22314C13.4212 1.22314 15.5384 2.10014 17.0995 3.66119C18.6605 5.22224 19.5375 7.33949 19.5375 9.54715Z"
                              stroke="#3F559E"
                              stroke-width="1.38733"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.2135 12.3218C12.7459 12.3218 13.9882 11.0795 13.9882 9.54713C13.9882 8.01472 12.7459 6.77246 11.2135 6.77246C9.6811 6.77246 8.43884 8.01472 8.43884 9.54713C8.43884 11.0795 9.6811 12.3218 11.2135 12.3218Z"
                              stroke="#3F559E"
                              stroke-width="1.38733"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_19_13">
                              <rect
                                width="22.1973"
                                height="22.1973"
                                fill="white"
                                transform="translate(0.11496 0.29834)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      {/* {!google && (
                        <GooglePlaceAutoComplete
                          value={filters?.location}
                          key="Map Component"
                          id={'map'}
                          result={DestinationAddress}
                          placeholder="Your Location"
                        />
                      )} */}
                      <input
                        placeholder="Enter Location"
                        value={filters?.keyword}
                        onChange={(e) => {
                          setFilter({ ...filters, keyword: e.target.value });
                        }}
                      />
                    </div>
                    <div className="submit-btn">
                      <button className="btn dark-btn" onClick={HandleSubmit}>
                        Find Job
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        {/* Featured  Jobs */}

        {user?.loggedIn? <section className="jobs-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-head">
                  <h3>Featured Loads</h3>
                  {data?.length > 5 && (
                    <div className="viewall-btn">
                      <a
                        onClick={() => {
                          history.push("/jobs");
                        }}
                        className="btn btn-secondary"
                      >
                        View All
                        <img
                          src="assets/img/white-arrow.svg"
                          className="ms-1"
                        />
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                {total == 0 ? (
                  <p className="notfounddata">No Active Loads</p>
                ) : (
                  <div className="row">
                    {data?.map((itm) => {
                      return (
                        <div className="col-xl-4 col-lg-6 col-md-6">
                          <div
                            className="jobs-inner"
                            onClick={() => {
                              if (!localStorage.getItem("token")) {
                                history.push("/login");
                              } else {
                                history.push(`/jobs/view/${itm?.id}`);
                              }
                            }}
                          >
                            <div className="job-detail">
                              <h4>
                                {itm?.origin_location_city} To{" "}
                                {itm?.destination_location_city}
                              </h4>
                              <div className="job-load">
                                <p className="badge">
                                  {itm?.origin_location_state}
                                </p>
                                {/* <p class="job-price">
                                Range:$
                                <span>
                                  {itm?.min_rate} - ${itm?.max_rate}
                                </span>
                              </p> */}
                              </div>
                            </div>
                            <div className="jobs-location-main">
                              <div className="google-search">
                                {/* <div className="google-icon">
                                <img src="assets/img/google.svg" className="" />
                              </div> */}
                                <div className="job-location">
                                  {/* <h6>Google Inc.</h6> */}
                                  <p>
                                    {" "}
                                    <img
                                      src="assets/img/loc.svg"
                                      className="me-1"
                                    />
                                    {itm?.destination_location_city}{" "}
                                    {itm?.destination_location_state ||
                                      itm?.destination_location_city}
                                    ,
                                    {
                                      itm?.destination_location_state
                                      // ExtractState(itm?.destination_address)
                                      //   ?.state
                                    }
                                    {/* ExtractState(itm?.destination_address)
                                        ?.city */}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <img
                                  src="assets/img/bookmarkSimple.svg"
                                  className="me-1"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>:""}
       

        {/* Video section */}
        <section className="video-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="common-title common-title2">
                  <h2>
                    Would You Like To CUT Your Shipping Costs? Looking For
                    Better Service?
                  </h2>
                </div>
                <div className="video-content">
                  <div className="video-sect-inner">
                    <img src="assets/img/truckimg.png" />
                    {/* <div className="play-btn">
                      <img src="assets/img/play-btn.png" />
                    </div> */}
                  </div>
                  <p>
                    In today's competitive marketplace, managing logistics
                    efficiently while keeping costs down can be the difference
                    between thriving and merely surviving. At One Brokerage, we
                    understand the challenges you face with shipping and
                    logistics. As an asset-based brokerage company, we are not
                    just another intermediary; we are your partner in success,
                    leveraging our resources and expertise to bring you
                    cost-effective, reliable, and superior service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Featured  Jobs */}
        {/* <section className="jobs-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-head">
                  <h3>Featured Jobs</h3>
                  <div className="viewall-btn">
                    <a
                      onClick={() => {
                        history.push("/jobs");
                      }}
                      className="btn btn-secondary"
                    >
                      View All
                      <img src="assets/img/white-arrow.svg" className="ms-1" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  {data?.map((itm) => {
                    return (
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div
                          className="jobs-inner"
                          onClick={() => {
                            if (!localStorage.getItem("token")) {
                              history.push("/login");
                            } else {
                              history.push(`/job/view/${itm?.id}`);
                            }
                          }}
                        >
                          <div className="job-detail">
                            <h4>
                              {itm?.origin_location_city} To{" "}
                              {itm?.destination_location_city}
                            </h4>
                            <div className="job-load">
                              <p className="badge">
                                {itm?.origin_location_state}
                              </p>
                              <p class="job-price">
                                Range:$
                                <span>
                                  {itm?.min_rate} - ${itm?.max_rate}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="jobs-location-main">
                            <div className="google-search">
                              <div className="google-icon">
                                <img src="assets/img/google.svg" className="" />
                              </div>
                              <div className="job-location">
                                <h6>Google Inc.</h6>
                                <p>
                                  {" "}
                                  <img
                                    src="assets/img/loc.svg"
                                    className="me-1"
                                  />
                                  {itm?.destination_location_city}{" "}
                                  {itm?.destination_location_state ||
                                    ExtractState(itm?.destination_address)
                                      ?.city}
                                  ,
                                  {
                                    ExtractState(itm?.destination_address)
                                      ?.state
                                  }
                                </p>
                              </div>
                            </div>
                            <div>
                              <img
                                src="assets/img/bookmarkSimple.svg"
                                className="me-1"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Brokerage section */}
        <section class="brokerage_secton section-padding">
          <div class="container">
            <div class="row align-items-center">
              <div class=" col-lg-5">
                <h2 class="brokerage_heading">Why choose One brokerage?</h2>
                <p class="brokerage_details d-inline">
                  Unlike non-asset brokers, our asset-based approach means we
                  have a vested interest in your shipments from start to finish.
                  With access to our own fleet and a wide network of trusted
                  carriers, we ensure your goods are handled with care and
                  professionalism every step of the way.
                </p>
              </div>
              <div class=" col-lg-7">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="list_box ">
                      <img
                        src="assets/img/Technsology.png"
                        alt=""
                        class="view_img1"
                      />
                      {/* <img
                        src="assets/img/delivery.png"
                        alt=""
                        class="view_img"
                      /> */}
                      <h6 class="option_bx">Cutting-Edge Technology</h6>
                      <p class="option_details d-inline">
                        At One Brokerage, we utilize the latest in logistics
                        technology to optimize routes, track shipments in
                        real-time, and provide you with analytics to make
                        informed decisions. This technology not only reduces the
                        chance of delays and mishaps but also cuts down
                        unnecessary costs.
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="list_box ">
                      <img
                        src="assets/img/Service.png"
                        alt=""
                        class="view_img1"
                      />
                      {/* <img src="assets/img/clock.png" alt="" class="view_img" /> */}
                      <h6 class="option_bx">Personalized Service</h6>
                      <p class="option_details d-inline">
                        We believe in a tailored approach to logistics. Our team
                        of experts works closely with you to understand your
                        specific needs and challenges, offering customized
                        solutions that meet your budget and timing requirements.
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="list_box ">
                      <img
                        src="assets/img/Price.png"
                        alt=""
                        class="view_img1"
                      />
                      {/* <img src="assets/img/api.png" alt="" class="view_img" /> */}
                      <h6 class="option_bx">Transparent Pricing</h6>
                      <p class="option_details d-inline">
                        With One Brokerage, there are no hidden fees or surprise
                        charges. We pride ourselves on transparent pricing,
                        ensuring you get the best value for your shipping needs
                        without compromising on service quality.
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="list_box ">
                      <img
                        src="assets/img/Focus.png"
                        alt=""
                        class="view_img1"
                      />
                      {/* <img src="assets/img/share.png" alt="" class="view_img" /> */}
                      <h6 class="option_bx">Sustainability Focus</h6>
                      <p class="option_details d-inline">
                        Reducing your shipping costs shouldn't come at the
                        environmental expense. We are committed to sustainable
                        logistics practices, helping you not only cut costs but
                        also minimize your carbon footprint.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Statistics Data */}
        <section className="statistics-section section-padding">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div className="common-title">
                  <h2 className="mb-4">Stats For One Brokerage</h2>
                </div>
                <p className="text-center mb-0">
                  Powered by precision, fueled by data. Our company's stats
                  speak volumes, delivering impressive on-time performance,
                  impeccable safety records, and unmatched customer
                  satisfaction.
                </p>
              </div>
              <div class="col-md-12">
                <ul className="stats-inner">
                  <li>
                    <h2>
                      95%<span>+</span>
                    </h2>
                    <p>deliveries per month</p>
                  </li>
                  <li>
                    <h2>
                      2600<span>+</span>
                    </h2>
                    <p>deliveries per month</p>
                  </li>
                  <li>
                    <h2>
                      5-<span>Star </span>
                    </h2>
                    <p>reviews from the clients</p>
                  </li>
                  <li>
                    <h2>
                      95%<span>+</span>
                    </h2>
                    <p>delivered samples</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonial Section */}
        {/* <section className="testimonial-section section-padding">
          <div class="container">
            <div class="row ">
              <div class="col-md-12">
                <div className="common-title">
                  <h2 className="mb-4">see what others people are saying</h2>
                </div>
                <p className="text-center mb-0">
                  Hear the road-tested tales! Discover why our company is the
                  talk of the town, delivering satisfaction one journey at a
                  time.
                </p>
              </div>
              <div class="col-md-12">
                <div className="testimonial-inner">
                  <div className="blue-bg"></div>
                  <div className="row">
                    <div class="col-lg-4">
                      <div className="testimonial-content">
                        <div className="testimonial-person">
                          <img src="assets/img/profile.png" className="" />
                          <div className="testimonial-detail">
                            <h6>Gary Pollich</h6>
                            <p>Future Group Facilitator</p>
                          </div>
                        </div>
                        <div className="client-review">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed facilisis eleifend quam, non efficitur
                            nisi mattis quis. Vivamus scelerisque orci nec erat
                            cursus, sed facilisis velit porttitor.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div className="testimonial-content">
                        <div className="testimonial-person">
                          <img src="assets/img/profile.png" className="" />
                          <div className="testimonial-detail">
                            <h6>Gary Pollich</h6>
                            <p>Future Group Facilitator</p>
                          </div>
                        </div>
                        <div className="client-review">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed facilisis eleifend quam, non efficitur
                            nisi mattis quis. Vivamus scelerisque orci nec erat
                            cursus, sed facilisis velit porttitor.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div className="testimonial-content">
                        <div className="testimonial-person">
                          <img src="assets/img/profile.png" className="" />
                          <div className="testimonial-detail">
                            <h6>Gary Pollich</h6>
                            <p>Future Group Facilitator</p>
                          </div>
                        </div>
                        <div className="client-review">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed facilisis eleifend quam, non efficitur
                            nisi mattis quis. Vivamus scelerisque orci nec erat
                            cursus, sed facilisis velit porttitor.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* FAQ Section */}
        <section className="faq-section section-padding position-relative">
          <div class="container">
            <div class="row ">
              <div class="col-md-6">
                <div className="faq-absolute">
                  <h2 class="brokerage_heading">Have Any Questions?</h2>
                  <p class="brokerage_details d-inline">
                    Got queries? We've got answers. Reach out and let company
                    guide you through any questions you have about
                    transportation solutions.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div className="faq-inner">
                  <div class="accordion heightaccordian" id="accordionExample">
                    {datafaq.map((itm) => (
                      <div class="accordion-item">
                        <div class="accordion-item accordileft">
                          <h2 class="accordion-header" id={`headingTwo`}>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#headingTwo${itm?.id}`}
                              aria-expanded="false"
                            >
                              {methodModel.capitalizeFirstLetter(itm?.question)}
                            </button>
                          </h2>
                          <div
                            id={`headingTwo${itm?.id}`}
                            class="accordion-collapse collapse "
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: itm?.answer }}
                              class="accordion-body"
                            ></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What are your hours of operation?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the third item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div> */}

        {/* <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFour">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          Do you operate 24/7?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse "
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the first item's accordion body.
                          </strong>{" "}
                          It is shown by default, until the collapse plugin adds
                          the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div> */}
        {/* <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFive">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          What equipment do you have?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the second item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div> */}
        {/* <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSix">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          Can I track my application?
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the third item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div> */}
        {/* </div> */}
        {/* </div> */}

        {/* Contact Section */}
        <section className="contactus-section section-padding">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div className=" gradient-bg">
                  <div className="common-title">
                    <h2 className="mb-4">Get in touch with us</h2>
                  </div>
                  <p className="text-center mb-0">
                    Stay connected on the road to success. Get in gear with our
                    company and let's drive your logistics forward together.
                  </p>

                  <div className="form-view">
                    <div className="row ">
                      {/* <div class="col-lg-5">
                        <div className="contact-us-left">
                          <h4>Contact Information</h4>
                          <p>
                            Sed facilisis eleifend quam, non efficitur nisi
                            mattis quis
                          </p>
                          <ul class="footer_items">
                            <li>
                              <div class="footer_list">
                                <img
                                  src="assets/img/message.png"
                                  class="all_icon"
                                  alt=""
                                />
                                <p class="list_name">hello@website.com</p>
                              </div>
                            </li>
                            <li>
                              <div class="footer_list">
                                <img
                                  src="assets/img/map.png"
                                  class="all_icon"
                                  alt=""
                                />
                                <p class="list_name">
                                  Riverside Building, County Hall, London SE1
                                  7PB, United Kingdom
                                </p>
                              </div>
                            </li>
                            <li>
                              <div class="footer_list">
                                <img
                                  src="assets/img/call.png"
                                  class="all_icon"
                                  alt=""
                                />
                                <p class="list_name">+02 5421234560</p>
                              </div>
                            </li>
                          </ul>
                          <ul class="footer_iconlist">
                            <li>
                              <a href="#">
                                <div class="footer_img ">
                                  <img
                                    src="assets/img/youtube.png"
                                    class="img-fluid"
                                    alt=""
                                  />
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div class="footer_img">
                                  <img
                                    src="assets/img/twitter.png"
                                    class="img-fluid"
                                    alt=""
                                  />
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div class="footer_img">
                                  <img
                                    src="assets/img/insta.png"
                                    class="img-fluid"
                                    alt=""
                                  />
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div class="footer_img">
                                  <img
                                    src="assets/img/facebook.png"
                                    class="img-fluid"
                                    alt=""
                                  />
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                      <div class="col-lg-7 m-auto">
                        <form onSubmit={contactus}>
                          <div className="contact-us-form">
                            <div className="form-group">
                              <label>Full name</label>
                              <input
                                type="text"
                                required
                                placeholder="Enter your full name"
                                className="form-control"
                                value={form2?.firstName}
                                onChange={(e)=>
                                {
                                  setform2({...form2,firstName:e.target.value})
                                }
                
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                required
                                placeholder="Enter your email"
                                className="form-control"
                                value={form2?.email}
                                onChange={(e)=>
                                {
                                  setform2({...form2,email:e.target.value})
                                }
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Message</label>
                              <textarea className="form-control"
                              value={form2?.message}
                              onChange={(e)=>
                              {
                                setform2({...form2,message:e.target.value})
                              }
                              }
                              ></textarea>
                            </div>
                            <div className="mt-5 get-started-btn">
                              <button className="btn dark-btn">
                                Get Started
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Footer Section */}
        <footer class="footer_section section-padding">
          <div class="container">
            <div class="row ">
              <div class="col-12 col-sm-12 col-md-12  col-lg-8">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-5 col-lg-5">
                    <div class="footer_space">
                      <img
                        src="assets/img/footer_logo.png"
                        class="img-fluid"
                        alt="logo"
                      />
                      <p class="footer_details">
                        Your trusted partner in transportation, delivering
                        results that move businesses forward.
                      </p>
                      <ul class="footer_iconlist">
                        <li>
                          <a href="#">
                            <div class="footer_img ">
                              <img
                                src="assets/img/youtube.png"
                                class="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="footer_img">
                              <img
                                src="assets/img/twitter.png"
                                class="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="footer_img">
                              <img
                                src="assets/img/insta.png"
                                class="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div class="footer_img">
                              <img
                                src="assets/img/facebook.png"
                                class="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-7 col-lg-7">
                    <div class="row">
                      <div class="col-12 col-sm-5 col-md-5 col-lg-5">
                        <div class="footer_space">
                          <h5 class="footer_heading">Quick Links</h5>

                          <ul class="footer_items">
                            <li>
                              <Link to="/about-us"> About Us</Link>
                            </li>
                            <li>
                              <Link to="/privacy-polices">Privacy & Policy</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class=" col-12 col-sm-7 col-md-7 col-lg-7">
                        <div class="footer_space">
                          <h5 class="footer_heading">Contact Us</h5>

                          <ul class="footer_items">
                            <li>
                              <div class="footer_list">
                                <img
                                  src="assets/img/message.png"
                                  class="all_icon"
                                  alt=""
                                />
                                <p class="list_name">hello@website.com</p>
                              </div>
                            </li>
                            <li>
                              <div class="footer_list">
                                <img
                                  src="assets/img/map.png"
                                  class="all_icon"
                                  alt=""
                                />
                                <p class="list_name">
                                  Riverside Building, County Hall, London SE1
                                  7PB, United Kingdom
                                </p>
                              </div>
                            </li>
                            <li>
                              <div class="footer_list">
                                <img
                                  src="assets/img/call.png"
                                  class="all_icon"
                                  alt=""
                                />
                                <p class="list_name">+02 5421234560</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                <div class="footer_space">
                  <h5 class="footer_heading">Newsletter</h5>
                  <div class="position-relative">
                    <form onSubmit={LetSubscribe}>
                      <input
                        type="email"
                        value={form?.email}
                        required
                        onChange={(e) =>
                          setform({ ...form, email: e.target.value })
                        }
                        class="form-control enter_mail"
                        placeholder="Enter your email"
                      />
                      <button class="subscribe_btn">Subscribe</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Home;
