import React from "react";
import './style.scss';
import Select from 'react-select'

const Html = ({ options,selectedValues,handleChange,displayValue='name',id}) => {

    const mOptions=options.map(itm=>{
        return { value: itm.id, label: itm?.[displayValue] }
    })

    const mSelectedValues=selectedValues.map(itm=>{
        return { value: itm.id, label: itm?.[displayValue] }
    })

    const selectAllOption = { value: '*', label: 'Select All' };

    return <>
        <div className="selectDropdowndd">
        <Select options={[selectAllOption, ...mOptions]}
        isMulti
        onChange={e=>{
            if (e) {
                if (e.some(option => option.value === selectAllOption.value)) {
                  handleChange(mOptions,'select')
                } else {
                    handleChange(e,'select')
                }
              } else {
                handleChange([],'select')
              }
        }}
        value={mSelectedValues}
        closeMenuOnSelect={false}
        />
        </div>
    </>
}

export default Html