const initialState = {
  data: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "MESSAGE_COUNT":
      return { ...state, data: action.data };
    default:
      return state;
  }
}
