import React, { useEffect, useState } from "react";
import WebsiteHeader from "../../components/global/WebsiteHeader";
import "./style.scss";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

function Conditions() {
const [detail,setDetail]=useState()

const getDetail=()=>{
  loader(true)
  ApiClient.get('content',{slug:'privacy-policy'}).then(res=>{
    if(res.success){
      setDetail(res.data)
    }
    loader(false)
  })
}
useEffect(()=>{
  getDetail()
},[])

  return (
    <>
      <WebsiteHeader />
      <div className="extramargin"></div>
      <div className="container privacyheader">
        <h3 class=" text-center mb-4 text-capitalize">{detail?.title}</h3>

        <div dangerouslySetInnerHTML={{__html:detail?.description}}></div>
      </div>

    </>
  );
}

export default Conditions;
