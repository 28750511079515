import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Layout2 from "../../../components/global/Layout2";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
// import "./style.scss";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import methodModel from "../../../methods/methods";
import rolesModel from "../../../models/roles.model";
import environment from "../../../environment";
import parse from "html-react-parser";
import moment from "moment";

import { toast } from "react-toastify";
import ReadMore from "../../../components/common/ReadMore";

const DriverDetails2 = (p) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { id, userId } = useParams();
  const [Permission, SetPermission] = useState({});
  const [data, setData] = useState();
  const getDetail = (id) => {
    loader(true);
    ApiClient.get(`driver`, { id: id }).then((res) => {
      if (res.success) {
        const newdata = res.data;
        setData(res.data);
        // GetTruck(res?.data?.truck_id);
        // if (res?.data?.role == "staff") {
        //   getPermission();
        // }
      }
      loader(false);
    });
  };

  const back = () => {
    history.push("/drivers");
  };

  // const GetTruck = (id) => {
  //   ApiClient.get("truck", { id }).then((res) => {

  //   });
  // };
  const edit = (id) => {
    let url = `/users1/edit/${id}`;
    if (role) url = `/users1/${role}/edit/${id}`;
    history.push(url);
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  const [EducationData, setEducationData] = useState([]);
  const GetEducation = () => {
    loader(true);
    ApiClient.get(`educations?userId=${id}`).then((res) => {
      if (res.success) {
        setEducationData(res.data);
      }
    });
  };
  const [EmployeementData, setEmoloyeementData] = useState([]);
  const GetEmployementData = () => {
    ApiClient.get(`employments?userId=${id}`).then((res) => {
      if (res.success) {
        setEmoloyeementData(res.data);
      }
    });
  };
  // useEffect(()=>{
  // GetEducation();
  // GetEmployementData();
  // },[])

  // const HandleAccpetReject=(e,status,role)=>{
  //     e.preventDefault();
  //     loader(true);
  //     const payload={isVerifiedDocument:status,id:id}
  //     ApiClient.put(`edit/profile`,payload).then(res=>{
  //         if(res.success){
  //           // toast.success(`${role} `)
  //             getDetail(id);
  //         }
  //     })
  //   }

  const [images, setimages] = useState([]);

  const [ProtofolioError, setProtofolioError] = useState(false);

  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);

  const imageResult = (e) => {
    if (e.target.files.length > 0) {
      setDocumentUploadLoading(true);
      const files = e.target.files;
      const newdata = new FormData();
      let newarray = [];
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        ApiClient.multiImageUpload("single/documents?modelName=document", {
          data: file,
        }).then((res) => {
          if (res.success) {
            // console.log(res?.data?.imagePath, "THis is Image Path");
            const path = res?.data?.imagePath;
            setResume(path);
            setform({ ...form, resumeDocument: path });
            setDocumentUploadLoading(false);
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;
      }
      setProtofolioError(false);
      setdocuments(newarray);
      setimages(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };
  const getPermission = () => {
    ApiClient.get(`permission/user-base?user_id=${id}`).then((res) => {
      if (res.success) {
        SetPermission({
          carrier_add: res?.data?.carrier_add,
          carrier_complete_access: res?.data?.carrier_complete_access,
          carrier_delete: res?.data?.carrier_delete,
          carrier_edit: res?.data?.carrier_edit,
          carrier_get: res?.data?.carrier_get,
        });

        // setform({...form,permissions:res?.data})
      }
    });
  };

  return (
    <Layout2 title2="Drivers">
      <div className="mainareas">
        <div className="pprofile1 edit-page-common edit-page-commons ">
          <div className="row">
            <div className="col-lg-12">
              <div className="title-head mb-0">
                <div className="d-flex justify-content-between align-items-center ">
                  <h3 className="viewUsers mb-0 user-back">
                    {" "}
                    <a to="/drivers" onClick={back}>
                      {" "}
                      <i
                        className="fa fa-arrow-left me-2 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </a>
                    Driver Detail
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="common-padding">
                <div className="row">
                  <div className="col-xl-3 col-lg-12 height-set">
                    <div className=" white-bg-main mb-4">
                      <div className=" white-head mb-3">
                        <h5 class="profilelist">License Image </h5>
                      </div>
                      <div class="row">
                          <div class=" col-lg-12 mb-3  ">
                            <div class="user-info-bx">
                              <div class=" w-100 widthitbox">
                              <img
                              src={data?.license_image?`${environment.api}documents/${data?.license_image}`: "/assets/img/drivers-license.png"
                              }
                              className=" borderUnset"
                            />
                              </div>
                              <h4> {data &&
                              methodModel.capitalizeFirstLetter(
                                data.fullName || data?.firstName
                              )}</h4>
                              <div class=" email-text">{data?.email}</div>
                     
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-xl-4  col-lg-6 height-set">
                    <div className=" white-bg-main mb-4">
                      <div className=" white-head mb-3">
                        <h5 class="profilelist">Basic Information</h5>
                      </div>
                      <div className="row">
                        {/* <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Driver Name
                          </label>
                          <div className="profiledetailscls">
                            {data &&
                              methodModel.capitalizeFirstLetter(
                                data.fullName || data?.firstName
                              )}
                          </div>
                        </div> */}

                        {/* <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">Email :</label>
                          <div className="profiledetailscls">{data?.email}</div>
                        </div> */}

                        <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">
                            License Number :
                          </label>
                          <div className="profiledetailscls">
                            {data?.licence_number}
                          </div>
                        </div>
                        {/* <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">
                            License Image :
                          </label>
                          <div className="profiledetailscls">
                            <img
                              src={
                                methodModel.userImg(
                                  data && data.license_image
                                ) || "--"
                              }
                              className="profileImage-main"
                            />
                          </div>
                        </div> */}

                        <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">
                            {" "}
                            Truck Number :
                          </label>
                          <div className="profiledetailscls">
                            {data?.truck_details?.truck_number||"--"}
                          </div>
                        </div>
                        {data?.mobileNo?  <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Mobile Number :
                          </label>
                          <div className="profiledetailscls">
                            {data?.dialCode} {data?.mobileNo}
                          </div>
                        </div>:<></>}
                      
                      </div>
                    </div>
                  </div>

                  <div className=" col-xl-5 col-lg-6 height-set">
                    <div className=" white-bg-main mb-4">
                      <div className=" white-head ">
                        <h5 class="profilelist">Address</h5>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls addressWidth">
                                Address :
                              </label>
                              <div className="profiledetailscls address-w">
                              {methodModel.capitalizeFirstLetter(
                                  data?.address
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                City :
                              </label>
                              <div className="profiledetailscls">
                                {data && data.city ||"--"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                State :
                              </label>
                              <div className="profiledetailscls">
                                {methodModel.capitalizeFirstLetter(
                                  data?.state
                                ) || "--"}
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                {" "}
                                Postal Code :
                              </label>
                              <div className="profiledetailscls">
                                {methodModel.capitalizeFirstLetter(
                                  data?.pincode
                                ) || "--"}
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                Country :
                              </label>
                              <div className="profiledetailscls">
                                {methodModel.capitalizeFirstLetter(
                                  data?.country
                                ) || "--"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default DriverDetails2;
