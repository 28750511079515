import React, { useEffect, useState } from "react";
import GooglePlaceAutoComplete from "../../../components/common/GooglePlaceAutoComplete";
import Layout from "../../../components/global/layout";
import "react-datetime/css/react-datetime.css";
import SelectDropdown from "../../../components/common/SelectDropdown";
import dateFormat from "dateformat";
import Datetime from "react-datetime";
// import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import environment from "../../../environment";
import ApiClient from "../../../methods/api/apiClient";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import loader from "../../../methods/loader";
import addressModel from "../../../models/address.model";

const Html = ({
  role,
  form,
  handleSubmit,
  setform,
  addressResult,
  submitted,
  back,
  detail,
}) => {
  const user = useSelector((state) => state.user);
  const [boards, setBoards] = useState([]);
  const [data, setdata] = useState([]);

  let [Truck, setTrucks] = useState([{ truck_number: "", vin_number: "" }]);
  const [num, setnum] = useState(1);
  const AddTag = () => {
    Truck.push({
      truck_number: "",
      vin_number: "",
    });
    setTrucks([...Truck]);
  };

  const removetag = (index) => {
    setTrucks([...Truck.filter((itm, i) => i != index)]);
    setform({
      ...form,
      truck_data: [...Truck.filter((itm, i) => i != index)],
    });

    setnum(num - 1);
  };

  const updatetag = (index, key, value) => {
    let arr = Truck;
    arr[index][key] = value;
    setTrucks([...arr]);
    setform({
      ...form,
      truck_data: [...arr],
      // no_of_reservation: arr.length,
    });
    setnum(arr.length);
   

    // if (num > data[0]?.sizeOfVenue) {
    //   toast.warn("You have Exceed the limit of booking");
    // }
  };
  useEffect(() => {
    ApiClient.get("boards").then((res) => {
      setBoards(res?.data?.data);
    });
  }, []);

  const DestinationAddress = async (e) => {

    let address = {};
    if (e.place) {
      address = await addressModel.getAddress2(e.place);
    }

    setform({
      ...form,
      destination_address: e.value || "",
      destination_location_city: address.city || "",
      destination_location_state: address.state || "",
      destination_location_country: address.country || "",
      destination_location_postal_code: address.zipcode || "",
      // lat: `${address.lat}` || '',
      // lng: `${address.lng}` || ''
    });
  };

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 1000);
  }, []);

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1 add-truck-sect">
            <div className="row">
              <div className="col-lg-12">
                <div className=" title-head">
                  <div className="heddings d-flex justify-content-between align-items-center">
                    <h4 className="viewUsers mb-0 user-back">  <Link to="/trucks1" className="">
                      <i
                        className="fa fa-arrow-left text-black me-2"
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </Link>
                      {form && form.id ? "Edit" : "Add"} Truck</h4>

                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-primary light_white "
                      onClick={AddTag}
                    >
                      <i class="fa fa-plus me-2" aria-hidden="true"></i>Add
                      Truck
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-row  ">
              <div className="row ">
                {Truck.map((itm, i) => {
                  return (
                    <div
                      className="col-md-12  mb-3"
                      onChange={(e) => {
                        if (num > data[0]?.sizeOfVenue) {
                          removetag(i);
                        }
                      }}
                    >
                      <div className="row">
                        <div className="col-md-6  ">
                          <label className="mb-2">
                            Truck Number<span className="text-danger">*</span>
                          </label>
                          <div className="d-flex align-items-center ">
                            <input
                              type="text"
                              pattern="^[a-zA-Z0-9]+$"
                              onKeyPress={(e) => {
                                var regex = new RegExp("^[a-zA-Z0-9]+$");
                                var key = String.fromCharCode(
                                  !e.charCode ? e.which : e.charCode
                                );
                                if (!regex.test(key)) {
                                  e.preventDefault();
                                  return false;
                                }
                              }}
                              className="form-control"
                              value={itm?.truck_number}
                              required
                              onChange={(e) =>
                                updatetag(i, "truck_number", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className=" col-md-6">
                          <div className="d-flex w-100 justify-content-end align-items-end">
                            <div className="w-100">
                              <label className="mb-2">
                                VIN Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                pattern="^[a-zA-Z0-9]+$"
                                onKeyPress={(e) => {
                                  var regex = new RegExp("^[a-zA-Z0-9]+$");
                                  var key = String.fromCharCode(
                                    !e.charCode ? e.which : e.charCode
                                  );
                                  if (!regex.test(key)) {
                                    e.preventDefault();
                                    return false;
                                  }
                                }}
                                required
                                value={itm?.vin_number}
                                onChange={(e) =>
                                  updatetag(i, "vin_number", e.target.value)
                                }
                              />
                            </div>
                            <div
                              className="d-flex justify-content-end h-100 align-items-end   ms-2  delete-btn-red
                          "
                            >
                              {Truck?.length > 1 && (
                                <i
                                  className="fa fa-trash text-danger pointer"
                                  onClick={(e) => {
                                    removetag(i);
                                  }}
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <p>Make sure to link driver with truck to bid with particular truck.</p> */}
            </div>
          </div>
          <div className="text-right  mt-4 d-flex justify-content-end">
            {/* <button
                type="button"
                className="btn btn-secondary discard mr-2"
                onClick={(e) => back()}
              >
                Back
              </button> */}

            <button type="submit" className="btn dark-btn width-set">
              Save
            </button>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default Html;
