import React, { useEffect, useState } from 'react';
import GooglePlaceAutoComplete from '../../../components/common/GooglePlaceAutoComplete';
import Layout from '../../../components/global/layout';
import 'react-datetime/css/react-datetime.css';
import SelectDropdown from '../../../components/common/SelectDropdown';
import dateFormat from 'dateformat';
import Datetime from 'react-datetime';
// import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import environment from '../../../environment';
import ApiClient from '../../../methods/api/apiClient';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import loader from '../../../methods/loader';
import addressModel from '../../../models/address.model';
import PhoneInput from 'react-phone-input-2';
import methodModel from '../../../methods/methods';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const Html = ({
  role,
  form,
  handleSubmit,
  setform,
  addressResult,
  submitted,
  detail,
}) => {
  const user = useSelector((state) => state.user);
  const [boards, setBoards] = useState([]);
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [filter, setfilter] = useState({});
  const [isSearch, setisSearch] = useState(false);
  const [Trucks, SetTruck] = useState([]);
  const [Vin, setVin] = useState('');
  const GetTruck = (e) => {
    if (e?.search) {
      setisSearch(true);
    } else {
      setisSearch(false);
    }
    ApiClient.get('trucks', { ...e, addedBy: user?.id }).then((res) => {
      if (res.success) {
        SetTruck(res?.data?.data);
      }
    });
  };
  // useEffect(() => {
 
  //   let fltr = Trucks?.filter((itm) => itm?.truck_id == form?.truck_id);

  //   if (fltr) {
  //     setVin(fltr[0]?.vin_number);

  //   }
  // }, [Trucks, form]);

  useEffect(() => {
    GetTruck();
    ApiClient.get('boards').then((res) => {
      setBoards(res?.data?.data);
    });
  }, []);

  const DestinationAddress = async (e) => {

    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }

    setform({
      ...form,
      address: e.value || '',
      city: address.city || '',
      state: address.state || '',
      country: address.country || '',
      pincode: address.zipcode || '',
      // lat: `${address.lat}` || '',
      // lng: `${address.lng}` || ''
    });
  };

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 1000);
  }, []);

  return (
    <>
      <Layout>
        <div className="pprofile1  edit-page-common">
          <div className="row">
            <div className="col-lg-12">
              <div className=" title-head mb-0">
                <h4 className="viewUsers mb-0 user-back">
                  <Link to="/drivers1" className="">
                    <i
                      className="fa fa-arrow-left me-2 text-black"
                      title="Back"
                      aria-hidden="true"
                    ></i>
                  </Link>
                  {form && form.id ? 'Edit' : 'Add'} Driver
                </h4>
              </div>
            </div>
          </div>

          <div className="main-row common-padding ">
            <form onSubmit={handleSubmit}>
              <div className="row ">
                <div className="col-lg-12">
                  <div className=" white-bg-main mb-4">
                    <div className=" white-head mb-3">
                      <h5 class="profilelist">Basic Information</h5>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3 ">
                        <label>
                          First Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={form.firstName}
                          required
                          onChange={(e) =>
                            setform({ ...form, firstName: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3 ">
                        <label>Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={form.lastName}
                          onChange={(e) =>
                            setform({ ...form, lastName: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3 ">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          required
                          disabled={id}
                          value={form.email}
                          onChange={(e) =>
                            setform({ ...form, email: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>
                          License Number<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          pattern="^[a-zA-Z0-9]+$"
                          onKeyPress={(e) => {
                            var regex = new RegExp('^[a-zA-Z0-9]+$');
                            var key = String.fromCharCode(
                              !e.charCode ? e.which : e.charCode
                            );
                            if (!regex.test(key)) {
                              e.preventDefault();
                              return false;
                            }
                          }}
                          className="form-control"
                          required
                          value={form.licence_number}
                          onChange={(e) =>
                            setform({ ...form, licence_number: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="label_profile">
                          Mobile No <span className="text-danger">*</span>
                        </label>
                        <div className="phoneInput_cls d-flex form-control p-0">
                          <PhoneInput
                            country={'us'}
                            value={form?.dialCode}
                            countryCodeEditable={false}
                            enableSearch={true}
                            placeholder=""
                            onChange={(phone, country) => {
                              setform({
                                ...form,
                                dialCode: country.dialCode,
                              });
                            }}
                          />

                          <input
                            type="text"
                            className="form-control phph"
                            placeholder="Mobile No."
                            value={(form && form.mobileNo) || ''}
                            maxLength={12}
                            onChange={(e) =>
                              setform({
                                ...form,
                                mobileNo: methodModel.isNumber(e),
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" white-bg-main mb-4">
                    <div className=" white-head mb-3">
                      <h5 class="profilelist">Address </h5>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label>
                          Address <span className="text-danger">*</span>
                        </label>
                        <GooglePlaceAutoComplete
                          key="Map2"
                          value={form?.address}
                          result={DestinationAddress}
                          id="address"
                          placeholder=""
                        />
                      </div>
                      <div className="col-md-6 mb-3 ">
                        <label>
                          City<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={form.city}
                          onChange={(e) =>
                            setform({ ...form, city: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3 ">
                        <label>
                          State<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={form.state}
                          onChange={(e) =>
                            setform({ ...form, state: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3 ">
                        <label>
                          Zipcode<span className="text-danger">*</span>
                        </label>
                        <input
                          type="numberic"
                          className="form-control"
                          value={form.pincode}
                          required
                          onChange={(e) =>
                            setform({ ...form, pincode: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3 ">
                        <label>
                          Country<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control"
                          value={form.country}
                          onChange={(e) =>
                            setform({ ...form, country: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" white-bg-main mb-4">
                    <div className=" white-head mb-3">
                      <div className="row justify-content-end">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                          <h5 class="profilelist ">Truck Details</h5>
                          <div className="position-relative">
                            {' '}
                            <input
                              placeholder="Search Trucks"
                              value={filter?.search}
                              className="form-control"
                              type="text"
                              onChange={(e) => {
                                GetTruck({ search: e.target.value });
                                setfilter({
                                  ...filter,
                                  search: e.target.value,
                                });
                              }}
                            />
                            {isSearch && (
                              <div className="dropspdiv">
                                {Trucks &&
                                  Trucks?.map((itm) => {
                                    return (
                                      <span
                                        className="dropspans"
                                        onClick={() => {
                                          setform({
                                            ...form,
                                            truck_id: itm?.id,
                                            vin_number: itm?.vin_number,
                                          });
                                          setVin(itm?.vin_number);
                                          setisSearch(false);
                                          setfilter({ search: '' });
                                        }}
                                      >
                                        {itm?.truck_number}
                                      </span>
                                    );
                                  })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label>
                          Truck <span className="text-danger">*</span>
                        </label>
                        <select
                          required
                          className="form-control"
                          onChange={(e) => {
                            setform({
                              ...form,
                              truck_id: e.target.value,
                              vin_number: e.target.id,
                            });
                            let fltr = Trucks?.filter(
                              (itm) => itm?.id == e.target.value
                            );
                            setVin(fltr[0]?.vin_number);
                          }}
                          value={form?.truck_id}
                        >
                          <option value="">Select Truck</option>
                          {Trucks?.map((itm) => {
                            return (
                              <option value={itm.id} id={itm?.vin_number}>
                                {itm?.truck_number}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-md-6 mb-3 ml-1">
                        <label>VIN Number</label>
                        <input
                          type="text"
                          required
                          disabled
                          className="form-control"
                          value={Vin}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right p-2 d-flex justify-content-end">
                <button type="submit" className="btn dark-btn width-set">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Html;
