import './style.scss';
import { BsAward } from 'react-icons/bs';
import { Tooltip } from 'antd';
import { GiWeight } from 'react-icons/gi';
import { CgNotes } from 'react-icons/cg';
import { LuFileCog } from 'react-icons/lu';
import { MdManageHistory, MdOutlinePendingActions, MdPending } from "react-icons/md";
import { TbArrowsRight, TbHandOff, TbShoppingCartCheck } from "react-icons/tb";
import { FaNoteSticky, FaRegBell } from 'react-icons/fa6';
import { ImHammer2 } from 'react-icons/im';
import ApiClient from '../../../methods/api/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import methodModel from '../../../methods/methods';
import { MdOutlinePending } from 'react-icons/md';
import { LiaWeightHangingSolid } from 'react-icons/lia';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import { TbHammer } from 'react-icons/tb';
import { FaBook, FaRegCheckCircle } from "react-icons/fa";
import { HiOutlineTruck } from 'react-icons/hi2';
import { GoChecklist } from 'react-icons/go';
import { AiOutlineFileText } from 'react-icons/ai';
import { BsFileText } from 'react-icons/bs';
import { RiUser6Line } from 'react-icons/ri';
import { GrUserPolice } from 'react-icons/gr';
import { RxDashboard } from 'react-icons/rx';
import { Link } from "react-router-dom";
import { GrDocumentNotes } from 'react-icons/gr';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { RiMenuFill } from "react-icons/ri";
import { LuLogOut } from "react-icons/lu";
import { logout,login_success } from '../../../actions/user';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect,useState } from 'react';
import { RxCrossCircled } from "react-icons/rx";
import WebsiteHeader from '../WebsiteHeader';
import { IoList } from "react-icons/io5";

export default function Layout2({ children, title = 'Title',title2='Title2' }) {
  const [board, setBoards] = useState([]);
  const [data, setdata] = useState([]);
  const [total, settotal] = useState(0)
  const [List, setList] = useState([])
  const [isopen,setIsopen]=useState(false)
  const toggle=()=>{setIsopen(!isopen)}
 const pathname=window.location.pathname
 const history=useHistory()
 const user = useSelector((state) => state?.user);
 const searchParams = new URLSearchParams(location.search);
  const Name=searchParams.get("Name")
 const dispatch=useDispatch()
 useEffect(() => {
  getBaords();
  if (user && user?.id) 
  {
      GetNotification();
  }
    
}, [user]);

useEffect(()=>{
  if(user?.id)
  {
    userData();
  }
  
},[user?.id])

 const userData=()=>
 {
  ApiClient.get(`user/detail`, { id: user?.id }).then((res) => {
    if (res.success) {
      const data = res.data;
      const newdata = { ...user, ...data };
      dispatch(login_success(newdata));
    }
   })
 }
 const Logout = () => {
  dispatch(logout());
  localStorage.removeItem("persist:admin-app");
  localStorage.removeItem("token");
  toast.success("You have log out successfuly");
  history.push("/");
};
const getBaords = () => {
  ApiClient.get("boards?status=active").then((res) => {
    if (res.success) {
      setdata(res?.data?.data);
      const filteredBoards = res?.data?.data.filter((item) =>
        user?.board_id?.includes(item.id)
      );

      setBoards(filteredBoards);
    }
  });
};
const GetNotification = () => {
  ApiClient.get("notification/all", {
    send_to: user?.id,
    status: "unread",
  }).then((res) => {
    if (res.success) {
      settotal(res?.data?.total_count)
      setList(res?.data?.data);
    }
  });
};

  return (
 
    <>
     {user?.loggedIn?
         <div className="main-wrappers hegiht100 d-flex overflow-hidden ">
         <div className="sidebar_new">
           <div className="inner_wraping d-flex  flex-row">
             <div className="small_sdies">
               <div className="top_data_showing">
                 <ul className="ulclasssides ">
                   <li>
                     <Link to="" class={`${user?.request_status=="rejected"?"disabled_icon":""}`}>
                       {' '}
                       <div className="logo-img-n">
                         <img src="/assets/img/white-fav.png" />
                       </div>
                     </Link>
                   </li>
                   <Tooltip placement="right" title="Dashboard">
                   <li>
                     <Link to="/dashboard" class={`${pathname=="/dashboard"?"active":""}  ${user?.request_status=="rejected"?"disabled_icon":""}`}>
                       <RxDashboard />
                     </Link>
                   </li>
                   </Tooltip>
                   
                   <Tooltip placement="right" title="Bids">
                   <li>
                     <Link to="/bids" class={`${pathname.includes("/bids")?"active":""} ${user?.request_status=="rejected"?"disabled_icon":""}`}>
                     
                       <LuFileCog/>
                     </Link>
                   </li>
                   </Tooltip>
                  {!user?.isInvited && user?.role=="user"
                  ?
                  <>
                 
                 </>
                :
                <> <Tooltip placement="right" title="Staff">
                <li>
                   <Link to="/Users" class={`${(pathname=="/Users"|| pathname.includes("/users")&&!Name)?"active":""} ${user?.request_status=="rejected"?"disabled_icon":""}`}>
                   
                   <RiUser6Line /> 
                   </Link>
                 </li>
                </Tooltip>
               
               {!user?.isInvited ?
                   <Tooltip placement="right" title="Carriers">
                   <li>
                      <Link to="/carriers" class={`${(pathname.includes("carriers")||Name)?"active":""} ${user?.request_status=="rejected"?"disabled_icon":""}`}>
                        {' '}
                        <IoCheckmarkDoneSharp />
                      </Link>
                    </li>
                   </Tooltip>
               :
               <></>
               }</>
                }
                 
                
               
                 <Tooltip placement="right" title="Trucks">
                 <li>
                     <Link to="/trucks" class={`${pathname.includes("/trucks")?"active":""} ${user?.request_status=="rejected"?"disabled_icon":""}`}>
                       {' '}
                       <HiOutlineTruck/>
                     </Link>
                   </li>
                 </Tooltip>
                  
                  <Tooltip  placement="right" title="Drivers">
                  <li>
                     <Link to="/drivers" class={`${pathname.includes("/drivers")?"active":""} ${user?.request_status=="rejected"?"disabled_icon":""}`}>
                       {' '}
                       <GrUserPolice/>
                     </Link>
                   </li>
                  </Tooltip>
                  <Tooltip  placement="right" title="Load Management">
                  <li>
                     <Link to={`${board?.length>0?"/jobs":"/loads/pendingPickup-loads"}`} class={`${(pathname.includes("/jobs"))||(pathname.includes("/loads"))||(pathname.includes("/loadDetail"))?"active":""} ${user?.request_status=="rejected"?"disabled_icon":""}`}>
                       {' '}
                       <MdManageHistory />
                     </Link>
                   </li>
                  </Tooltip>
                  
                 </ul>
               </div>
               <div className="bottom_data_showing">
                 <ul className="ulclasssides ">
                   <Tooltip placement="right" title="Shipment Notes">
                   <li>
                     <Link to="/notes" class={`${pathname=="/notes"?"active":""} ${user?.request_status=="rejected"?"disabled_icon":""}`}>
                       {' '}
                       <GrDocumentNotes />
                     </Link>
                   </li>
                   </Tooltip>
                 
                 <Tooltip  placement="right" title="Notification">
                 <li className='notification-m position-relative'>
                   {total>0?<span className='notcount'>{total}</span>:<></>}
                     <Link to="/notification" class={`${pathname=="/notification"?"active":""} ${user?.request_status=="rejected"?"disabled_icon":""}`}>
                       {' '}
                       <IoMdNotificationsOutline />
                     </Link>
                   </li>
                 </Tooltip>
                 
                   <li className="profile-img">
                     <div class="dropdown ps-0">
                       <div
                         class="dropdown-toggle d-flex align-items-center pulse_profile"
                         type="button"
                         id="dropdownMenuButton1"
                         data-bs-toggle="dropdown"
                         aria-expanded="false"
                       >
                         <img src={
                            methodModel.userImg(
                             user &&user?.image
                           ) || '--'
                          } className='rounded-circles'/>
                       </div>
                       <ul
                         class="dropdown-menu"
                         aria-labelledby="dropdownMenuButton1"
                       >
                         <li>
                           <Link class="dropdown-item" to="/profile">
                           <RiUser6Line className="me-1" />  Profile
                           </Link>
                         </li>
                         <li>
                           <span id="WantLogout" onClick={Logout} class="dropdown-item">
                           <LuLogOut className="me-1" />
                             Logout
                           </span>
                         </li>
                       </ul>
                     </div>
                   </li>
                 </ul>
               </div>
             </div>
           </div>
           {!((pathname=="/bids")||(pathname=="/loads")||(pathname=="/Users")||(pathname=="/notes")||(pathname=="/carriers/invited-users")||(pathname=="/carriers/rejected-users")||(pathname=="/carriers/user-request")||(pathname=="/drivers")||(pathname=="/trucks")||(pathname=="/bids/pending-bid")||(pathname=="/bids/rejected-bid")||(pathname=="/bids/accepted-bid")||(pathname=="/bids/awarded-bid")||(pathname=="/loads/delivered")||(pathname=="/carriers")||(pathname=="/loads/intransit")||(pathname=="/loads/pendingPickup-loads")||((pathname.includes("/jobs"))&&(!pathname.includes("/jobs/view"))))
             ?
             <></>
             :
             <div className={`${isopen?"toogle_hide":""} big_sideswrap sidebar_togle`}>
             <div className='headerlogo collpase_data'>
                 <div className='name_menus bars_right d-flex align-items-center '>
                     <h4 className='fs-16 side_title'>{title}</h4>
                     <div className='icons_bars pointer' onClick={()=>toggle()}>
                     <RiMenuFill />
                     </div>
                 </div>
             </div>
             
             <div className='itesm_list '>
               <ul className=' border-btm-grey '>
               
                {pathname.includes("/bids")?
                <>
                 <li>
                 <Link to="/bids" class={`${pathname=="/bids"?"sidebar_active":""}`}>
                 <TbHammer />
                 <span className="ms-2">
                 All Bids
                     </span>
                     </Link>
                 </li>
 
                 <li>
                 <Link to="/bids/pending-bid" class={`${pathname=="/bids/pending-bid"?"sidebar_active":""}`}>
                 <MdOutlinePendingActions />
                 <span className="ms-2">
                 Pending Bids
                     </span>
                     </Link>
                 </li>
                            
                 <li>
                 <Link to="/bids/rejected-bid" class={`${pathname=="/bids/rejected-bid"?"sidebar_active":""}`}>
                 <TbHandOff />
                 <span className="ms-2">
                   Rejected Bids
                     </span>
                     </Link>
                 </li>
                
                 
                 
                 <li>
                 <Link to="/bids/accepted-bid" class={`${pathname=="/bids/accepted-bid"?"sidebar_active":""}`}>
                 <FaRegCheckCircle />
                 <span className="ms-2">
                 Accepted Bids
                     </span>
                     </Link>
                   
                 </li>
                
                
                
                <li>
                <Link to="/bids/awarded-bid" class={`${pathname=="/bids/awarded-bid"?"sidebar_active":""}`}>
                 <BsAward />
                 <span className="ms-2">
                 Awarded Bids
                     </span>
                     </Link>
                 </li>
                </>               
                : 
                <></>
                }
              
                {(pathname.includes("/loads")||pathname.includes("/jobs"))?
                <>
                {board?.length>0? <li>
                <Link to="/jobs" class={`${pathname=="/jobs"?"sidebar_active":""}`}>
                     <LiaWeightHangingSolid />
                     <span className="ms-2">
                       All Loads
                     </span>
                   </Link>
                </li>:<></>}
              
                 {board?.map((itm) => {
                     return (
                       <li>
                        <Link to={`/jobs/${itm?.id}`} class={`${pathname==`/jobs/${itm?.id}`?"sidebar_active":""}`}>
                         {/* {itm?.name == "prebook" ? <AiOutlineFileText />: null}
                         {itm?.name == "usps" ? <BsFileText /> : null}
                         {itm?.name == "rfps" ? <CgNotes /> : null}
                         {itm?.name == "RFP" ? <CgNotes /> : null} */}
                         {/* <MdOutlinePendingActions /> */}
                         <AiOutlineFileText />
                         <span className="ms-2">
                           {methodModel.capitalizeFirstLetter(itm?.name)}
                         </span>
                       </Link>
                       </li>
                     
                     );
                   })}
                   
                   <li>                 
                   <Link to={`/loads/pendingPickup-loads`} class={`${pathname=="/loads/pendingPickup-loads"?"sidebar_active":""}`}>
                     <MdOutlinePending />
                     <span className="ms-2">
                       Pending Pickup
                     </span>
                   </Link>
                   </li>
                 
                 <li> <Link to={`/loads/intransit`} class={`${pathname=="/loads/intransit"?"sidebar_active":""}`}>
                     <TbArrowsRight />
 
                     <span className="ms-2" >
                       In Transit
                     </span>
                   </Link></li>
                  
                  <li> <Link to={`/loads/delivered`} class={`${pathname=="/loads/delivered"?"sidebar_active":""}`}>
                     <TbShoppingCartCheck />
                     <span className="ms-2">
                       Delivered
                     </span>
                   </Link></li>
                  
                </>            
                : 
                <></>
                }
                {pathname.includes("/Users")?
                <>
                 <li>
                 <Link to="/Users" class={`${pathname=="/Users"?"sidebar_active":""}`}>
                 <RiUser6Line />
                 <span className="ms-2">
                    Staff
                     </span>
                     </Link>
                 </li>
                 </>
                 :
                 <></>
                }
                 {pathname.includes("carriers")?
                <>
                <li>
                 <Link to="/carriers" class={`${pathname=="/carriers"?"sidebar_active":""}`}>
                 <IoList />
                 <span className="ms-2">
                 All Carriers
                     </span>
                     </Link>
                 </li>
                 <li>
                 <Link to="/carriers/invited-users" class={`${pathname=="/carriers/invited-users"?"sidebar_active":""}`}>
                 <IoCheckmarkDoneSharp />
                 <span className="ms-2">
                 Approved Carriers
                     </span>
                     </Link>
                 </li>
                 <li>
                 <Link to="/carriers/rejected-users" class={`${pathname=="/carriers/rejected-users"?"sidebar_active":""}`}>
                 <RxCrossCircled />
                 <span className="ms-2">
                 Rejected Carriers
                     </span>
                     </Link>
                 </li>
                 <li>
                 <Link to="/carriers/user-request" class={`${pathname=="/carriers/user-request"?"sidebar_active":""}`}>
                 <GoChecklist />
                 <span className="ms-2">
                   Pending Carriers 
                     </span>
                     </Link>
                 </li>
                 </>
                 :
                 <></>
                }
               
                
             
                 {pathname.includes("/trucks")?
                <>
                 <li>
                 <Link to="/trucks" class={`${pathname=="/trucks"?"sidebar_active":""}`}>
                 <HiOutlineTruck/>
                 <span className="ms-2">
                   Trucks
                     </span>
                     </Link>
                 </li>
                 </>
                 :
                 <></>
                }
                {pathname.includes("/drivers")?
                <>
                 <li>
                 <Link to="/drivers" class={`${pathname=="/drivers"?"sidebar_active":""}`}>
                 <GrUserPolice/>
                 <span className="ms-2">
                   Drivers
                     </span>
                     </Link>
                 </li>
                 </>
                 :
                 <></>
                }
                 {pathname.includes("/notes")?
                <>
                 <li>
                 <Link to="/notes" class={`${pathname=="/notes"?"sidebar_active":""}`}>
                 <GrDocumentNotes />
                 <span className="ms-2">
                 Shipment Notes
                     </span>
                     </Link>
                 </li>
                 </>
                 :
                 <></>
                }
                 
                 
               </ul>
             </div>
             </div>
           }
   
 
         </div>
 
         <div className={`mai_data_full ${isopen?"dashboard_full":""} ${!((pathname=="/bids")||(pathname=="/loads")||(pathname=="/Users")||(pathname=="/notes")||(pathname=="/carriers")||(pathname=="/carriers/invited-users")||(pathname=="/carriers/rejected-users")||(pathname=="/carriers/user-request")||(pathname=="/drivers")||(pathname=="/trucks")||(pathname=="/bids/accepted-bid")||(pathname=="/bids/pending-bid")||(pathname=="/bids/rejected-bid")||(pathname=="/bids/awarded-bid")||(pathname=="/loads/delivered")||(pathname=="/loads/intransit")||(pathname=="/loads/pendingPickup-loads")||((pathname.includes("/jobs"))&&(!pathname.includes("/jobs/view"))))?"dashboard_full":""}`}>
         <div className='header_sidebars'>
               <div className='d-flex justify-content-between align-items-center h-60  width-calc'>
                   <div className='name_s name-margin icons-sidebar'>
                  <div className={`${isopen?"toggle-show":""} toggle-icon-n me-2`} onClick={toggle}>
                  <RiMenuFill />
                  </div>
                       <p className='text-capitalize'>{title2}</p>
                   </div>
               </div>
           </div>
           {children}
           </div>
       </div>
     :
     <> <WebsiteHeader/>
     <div>{children}</div>
     </>
    
     }
      {/* <div className="d-flex">
        <h3>{title}</h3>

        <div className="ms-auto">
          <form class="d-flex searchForm">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button>
          </form>
        </div>
      </div> */}
   
    </>
  );
}
