import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import "./style.scss";
import { useSelector } from "react-redux";
import methodModel from "../../../methods/methods";
import moment from "moment";
import ReadMore from "../../../components/common/ReadMore";
import CountdownTimer from "../../JobSearch/countdoenTimer";
import datepipemodel from "../../../models/datepipemodel";
import "rsuite/Steps/styles/index.css";

import datepipeModel from "../../../models/datepipemodel";
import { Steps } from "rsuite";
const BidDetails = (p) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { id, userId } = useParams();
  const [Permission, SetPermission] = useState({});
  const [data, setData] = useState();
  const [timeRemaining, setTimeRemaining] = useState("");
  const [Hour, setHour] = useState(0);
  const [ExpiredDate, setExpiredDate] = useState(0);
  const [createdDate, setCreated] = useState(0);
  const [LeftDays, setLeftDays] = useState(0);
  const [ActiveStop, setActiveStop] = useState(0);

  const [Stops, setStops] = useState([
    // {
    //   city: "Delhi",
    //   date: "12/03/2024",
    //   checkedin: true,
    // },
    // {
    //   city: "Noida",
    //   date: "12/03/2024",
    //   checkedin: true,
    // },
    // {
    //   city: "Faridabad",
    //   date: "13/03/2024",
    //   checkedin: false,
    // },
    // {
    //   city: "Pune",
    //   date: "15/03/2024",
    //   checkedin: false,
    // },
  ]);
  let ActiveCOunt = Stops?.filter((itm) => itm)?.length;

  const getDetail = (did) => {
    loader(true);
    ApiClient.get(`bid`, { id: did }).then((res) => {
      if (res.success) {
        const newdata = res.data;
        setData(res.data);
      
        if (res?.data?.load_details?.shipment_status == "delivered") {
          setActiveStop(5);
        } else {
          setActiveStop(
            res?.data?.stops_details?.filter((itm) => itm?.checkin)?.length
          );
        }

        // if(res?.data?.)
        setExpiredDate(res?.data?.expiration_date);
        // Stops.push({
        //   label: res?.data?.load_info?.origin_address,
        //   subtitle: "Origin",
        //   name: "",
        //   content: "",
        // });
        let arry = [];

        Stops.push(
          {
            address: res?.data?.load_details?.origin_address,
          },
          {
            destination_address: res?.data?.load_info?.destination_address,
            date: res?.data?.load_details?.delivered_at,
          }
        );


        // if (res?.data?.role == "staff") {
        //   getPermission();
        // }
      }

      loader(false);
    });
  };
  const Timeleft = () => {
    let Created = new Date(ExpiredDate);

    let expired = new Date();

    let Total = Created.getTime() - expired.getTime();
    let LeftDay = Math.round(Total / (1000 * 3600 * 24));
    setLeftDays(LeftDay);
    // Timeleft()
  };

  const ReplaceUnderScoreWithSpace = (str) => {
    if (str?.includes("_")) {
      return methodModel.capitalizeFirstLetter(str.split("_").join(" "));
    } else {
      return methodModel.capitalizeFirstLetter(str);
    }
  };

  const StartTimer = () => {
    let Created = new Date(ExpiredDate);
    let expired = new Date();
    let Total = Created.getTime() - expired.getTime();

    const days = Math.floor((Total / (1000 * 60 * 60)) * 24);

    const hours = Math.floor(Total / (1000 * 60 * 60));
    setHour(hours);

    const minutes = Math.floor((Total % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((Total % (1000 * 60)) / 1000);
    setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
    setHour(hours);
  };
  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      StartTimer();
      Timeleft();
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining]);

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  const styles = {
    width: "200px",
    display: "inline-table",
    verticalAlign: "top",
  };
  return (
    <Layout>
      <div className="pprofile1 edit-page-common">
        <div className="row">
          <div className="col-lg-12">
            <div className=" title-head mb-0">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="viewUsers mb-0 user-back">
                  {" "}
                  <a to="/bids" onClick={back}>
                    {" "}
                    <i
                      className="fa fa-arrow-left me-2  "
                      title="Back"
                      aria-hidden="true"
                    ></i>
                  </a>
                  Bid Detail
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="common-padding">
              <div className="row">
                <div className="col-md-12">
                  <div className=" white-bg-main mb-4">
                    <div className=" white-head mb-3">
                      <h5 class="profilelist">Basic Information</h5>
                    </div>


                    
                    <div className="row">
                      
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Bid Status :
                        </label>
                        <div className={`${data?.status}bid`}>
                          {data &&
                            methodModel.capitalizeFirstLetter(data?.status)}
                        </div>
                      </div>
                      {/* {data?.status == "accepted" && (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Shipment Status :
                          </label>
                          <div className="profiledetailscls">
                            {data &&
                              methodModel.capitalizeFirstLetter(
                                data?.load_details?.shipment_status || "--"
                              )}
                          </div>
                        </div>
                      )} */}
                      {
                        data?.load_info?.total_distance?
                        <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Total Distance in Mi :
                        </label>
                        <div className="profiledetailscls">
                          {data?.load_info?.total_distance}
                        </div>
                      </div>
                        :
                        <></>
                      }
                      

                      {/* <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Total Weight in kg :
                        </label>
                        <div className="profiledetailscls">
                          {data?.load_info?.total_weight}
                        </div>
                      </div> */}
                      {/* <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Total Volume :
                        </label>
                        <div className="profiledetailscls">
                          {data?.load_info?.total_volume}
                        </div>
                      </div>
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Total Pieces :
                        </label>
                        <div className="profiledetailscls">
                          {data?.load_info?.total_pieces}
                        </div>
                      </div>
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Total Pallets :
                        </label>
                        <div className="profiledetailscls">
                          {data?.load_info?.total_pallets}
                        </div>
                      </div> */}
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">Bid ETA :</label>
                        <div className="profiledetailscls">
                          {datepipeModel.date(data?.bid_time)},{" "}
                          {datepipeModel.isotime(data?.bid_time)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" white-bg-main mb-4">
                    <div className=" white-head mb-3">
                      <h5 class="profilelist">Address</h5>
                    </div>

                  
                    <div className="row">
                      
                     <div className="col-md-6">
                     <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                        Origin City :
                        </label>
                        <div className="profiledetailscls">
                        {data && data?.load_info.origin_location_city}
                        </div>
                      </div>
                     
                     

                    
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">State :</label>
                        <div className="profiledetailscls">
                                {methodModel.capitalizeFirstLetter(
                                  data?.load_info?.origin_location_state
                                ) || '--'}
                              </div>
                      </div>
                      <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                Postal Code :
                              </label>
                              <div className="profiledetailscls">
                                {methodModel.capitalizeFirstLetter(
                                  data?.load_info?.origin_location_postal_code
                                ) || '--'}
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                Country :
                              </label>
                              <div className="profiledetailscls">
                                {methodModel.capitalizeFirstLetter(
                                  data?.load_info?.origin_location_country
                                ) || '--'}
                              </div>
                            </div>
                     </div>
                     <div className="col-md-6">
                     <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                               Destination City :
                              </label>
                              <div className="profiledetailscls">
                                {data &&
                                  data?.load_info?.destination_location_city}
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                State :
                              </label>
                              <div className="profiledetailscls">
                                {methodModel.capitalizeFirstLetter(
                                  data?.load_info?.destination_location_state
                                ) || '--'}
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                Postal Code :
                              </label>
                              <div className="profiledetailscls">
                                {methodModel.capitalizeFirstLetter(
                                  data?.load_info
                                    ?.destination_location_postal_code
                                ) || '--'}
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                Country :
                              </label>
                              <div className="profiledetailscls">
                                {methodModel.capitalizeFirstLetter(
                                  data?.load_info?.destination_location_country
                                ) || '--'}
                              </div>
                            </div>
                     </div>

                    </div>
                  </div>
                  <div className=" white-bg-main mb-4">
                    <div className=" white-head mb-3">
                      <h5 class="profilelist">Load Information</h5>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Dispatcher Name :
                        </label>
                        <div className="profiledetailscls">
                          {data &&
                            methodModel.capitalizeFirstLetter(
                              data?.carrier_id?.fullName
                            )}
                        </div>
                      </div>
                      {data?.driver_detail &&
                        data?.driver_detail?.map((itm) => {
                          return (
                            <div className="col-md-6 mb-3 view-flex">
                              <label className="profileheddingcls">
                                Driver Name :
                              </label>
                              <div className="profiledetailscls">
                                {data &&
                                  methodModel.capitalizeFirstLetter(
                                    itm?.fullName || itm?.firstName
                                  )}
                              </div>
                            </div>
                          );
                        })}
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Company Name :
                        </label>
                        <div className="profiledetailscls">
                          {data &&
                            methodModel.capitalizeFirstLetter(
                              data?.carrier_id?.company_name
                            )}
                        </div>
                      </div>
                      {Hour > -1 && (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            End Time :
                          </label>
                          <div className="profiledetailscls">
                            {data && timeRemaining}
                          </div>
                        </div>
                      )}

                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Truck Number
                        </label>
                        <div className="profiledetailscls">
                          {data && data?.truck_number}
                        </div>
                      </div>

                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Estimate Time :
                        </label>
                        <div className="profiledetailscls">
                          {data && data?.eta_value} {data && data?.eta_type}
                        </div>
                      </div>

                      {data?.load_info?.load_id ||data?.load_info?.lane_id 
                      ?
                      <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">{data?.load_info?.load_id?"Load ID :":"Lane ID :"}</label>
                      <div className="profiledetailscls">
                        {data?.load_info?.load_id?data?.load_info?.load_id:data?.load_info?.lane_id}
                      </div>
                      </div>
                      :
                      <></>
                      }
                     
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Submitted Rate :
                        </label>
                        <div className="profiledetailscls">
                          ${data?.carrier_info?.agreed_rate}
                        </div>
                      </div>

                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Pickup Date :
                        </label>
                        <div className="profiledetailscls">
                          {moment(data?.load_info?.load_start_date).format(
                            "DD-MM-YYYY"
                          )}
                        </div>
                      </div>

                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Load End Date :
                        </label>
                        <div className="profiledetailscls">
                          {moment(data?.load_info?.load_end_date).format(
                            "DD-MM-YYYY"
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Expiration Date :
                        </label>
                        <div className="profiledetailscls">
                          {moment(data?.load_info?.expiration_date).format(
                            "DD-MM-YYYY"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                
                  <div className=" white-bg-main mb-4">
                    <div className=" white-head mb-3">
                      <h5 class="profilelist">Track Details</h5>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Bid Status :
                        </label>
                        <div className={`${data?.status}bid`}>
                          {data &&
                            methodModel.capitalizeFirstLetter(data?.status)}
                        </div>
                      </div>
                      {data?.load_details?.pickedup_at != null ? (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Pickup Date :
                          </label>
                          <div className="profiledetailscls">
                            {moment(data?.load_details?.pickedup_at).format(
                              "DD-MM-YYYY h:m A"
                            )}
                          </div>
                        </div>
                      ) : null}
                      {data?.status == "accepted" && (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Shipment Status :
                          </label>
                          <div className="profiledetailscls">
                            {data &&
                              ReplaceUnderScoreWithSpace(
                                data?.load_details?.shipment_status || "--"
                              )}
                          </div>
                        </div>
                      )}
                      {data?.status == "awarded" && (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            {data?.load_details?.delivered_at
                              ? " Delivery At :"
                              : data?.load_info?.transit_at && "Transit At"}
                          </label>
                          <div className="profiledetailscls">
                            {data?.load_details?.shipment_status == "delivered"
                              ? moment(data?.load_details?.delivered_at).format(
                                  "DD-MMM-YYYY HH:MM A"
                                )
                              : data?.load_info?.transit_at &&
                                moment(data?.load_info?.transit_at).format(
                                  "DD-MMM-YYYY HH:MM A"
                                )}
                          </div>
                        </div>
                      )}
                    </div>
                    {data?.status == "awarded" && (
                      <div className="p-3">
                        {data?.stops_details?.length > 1 ? (
                          <Steps vertical style={styles} current={ActiveStop}>
                            <Steps.Item
                              title={
                                data?.stops_details[0]?.checkin
                                  ? "Picked Up"
                                  : "Pickup Pending"
                              }
                              description={data?.load_details?.origin_address}
                            />
                            {data?.stops_details?.map((itm, index) => {
                              return (
                                <>
                                  <Steps.Item
                                    title={`${
                                      index > 0 &&
                                      index < data?.stops_details?.length - 1
                                        ? "In Transit"
                                        : ""
                                    } ${itm?.address}`}
                                    description={
                                      itm.checkin &&
                                      index == data?.stops_details?.length - 1
                                        ? itm?.checkin &&
                                          `Delivered at ${moment(
                                            itm?.checkin
                                          ).format("DD-MM-YYYY- HH:MM A")} ${
                                            itm?.address
                                          } `
                                        : itm?.checkin &&
                                          `Checkedin ${moment(
                                            itm?.checkin
                                          ).fromNow()}  ${
                                            itm?.checkout == null
                                              ? ""
                                              : `|| checkedOut ${moment(
                                                  itm?.checkout
                                                ).fromNow()}`
                                          }`
                                    }
                                  />
                                </>
                              );
                            })}
                          </Steps>
                        ) : (
                          <>
                            <Steps
                              current={
                                Stops[1]?.date || Stops[1]?.delivered_at ? 2 : 0
                              }
                              vertical
                              style={styles}
                            >
                              {Stops?.map((itm, index) => {
                                return (
                                  <>
                                    <Steps.Item
                                      title={
                                        itm?.address || itm?.destination_address
                                      }
                                      description={
                                        Stops[1]?.date
                                          ? "Delivery"
                                          : "No status"
                                      }
                                    />
                                  </>
                                );
                              })}
                            </Steps>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BidDetails;
