import React, { useEffect, useState } from "react";

export default function CommonDeleteModal({ show, confirm, setShow }) {
  const [ShowModal, setShowModal] = useState("none");
  useEffect(() => {
    setShowModal(show);
  }, [show]);
  return (
    <div>
      <div
        class={`modal delete-modal  d-${show}`}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content p-0">
            <div class="modal-header">
              {/* <h5 class="modal-title" id="exampleModalLabel">Delete</h5> */}
              <button
                type="button"
                class="close"
                onClick={(e) => setShow("none")}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center p-2">
              <img src="assets/img/img/delete.png" className="delete_icon" />
              <h5 className="mt-3 mb-3">Are you sure want to delete?</h5>
            </div>
            <div class="text-center p-2 pb-4">
              <button
                type="button"
                onClick={(e) => setShow("none")}
                class="btn btn-secondary mr-2"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={(e) => confirm()}
                class="btn btn-primary"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
