import React, { useEffect, useState } from 'react';
import Layout2 from '../../components/global/Layout2';
import Pagination from 'react-pagination-js';
// import "./style.scss";
import rolesModel from '../../models/roles.model';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import { useHistory } from 'react-router-dom';
import { IoArrowBackCircleSharp, IoTrophyOutline } from 'react-icons/io5';
import { FaAward } from 'react-icons/fa6';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { PiSmileySad } from 'react-icons/pi';
import { TbArrowsExchange, TbBrandAppgallery } from 'react-icons/tb';
import ApiClient from '../../methods/api/apiClient';
import { toast } from 'react-toastify';
import loader from '../../methods/loader';
import { Tooltip } from 'antd';
import moment from 'moment';
import environment from '../../environment';
import { BsThreeDots } from 'react-icons/bs';
import ImageUpload from '../../components/common/ImageUpload';
import { Link } from 'react-router-dom';
import { IoIosArrowRoundForward } from 'react-icons/io';
import CountdownTimer2 from './countdoenTimer';
const Html = ({
  accepted,
  rejected,
  ChangeFilter,
  UpdateTransitStatus,
  sorting,
  setFilter,
  pageCompleteBidChange,
  pageRejectBidChange,
  Awarded,
  GetAwarded,
  rejectBid,
  pageChange,
  completeBid,
  filters,
  loaging,
  alldata,
  getData,
  TAB,
  settab,
  Loading,
  CheckParamsPath,
  url,
  setLoading,
  role,
  total = { total },
}) => {

  const history = useHistory();

  // const tabs = localStorage.getItem("BidStatus")
  const [Setting, setSetting] = useState({});
  const [form, setform] = useState({ agreed_rate: '', id: '' });
  // const [tab, settab] = useState('All')
  const [loadDetails, setLoadDetails] = useState({ document: '' });
  const [files, setFIles] = useState([]);
  const [Notes, SetNotes] = useState({ note: '' });


  const HandleSubmit = (e) => {
    loader(true);
    e.preventDefault();
    ApiClient.put('change-rate', form).then((res) => {
      if (res.success) {
        toast.success(res.message);
        document.getElementById('CloseBidUpdateModel').click();
        getData();
      }
    });
    loader(false);
  };


  const [Document, setdocuments] = useState([]);
  const [fileInputs, setFileInputs] = useState([{ id: 1, file: null }]);

  const addFileInput = () => {
    setFileInputs([...fileInputs, { id: fileInputs.length + 1, file: null }]);
  };
  const removeFileInput = (id, file) => {
    setFileInputs(fileInputs.filter((input) => input.id !== id));
    setdocuments(Document.filter((itm) => itm != file));
    if (file) {
      ApiClient.delete(`delete/document?fileName=${file}`).then((res) => { });
    }
    if (id == 1) {
      setFileInputs([{ id: 1, file: null }]);
    }
  };
  const ReplaceUnderScoreWithSpace = (str) => {
    if (str?.includes('_')) {
      return methodModel.capitalizeFirstLetter(str.split('_').join(' '));
    } else {
      return methodModel.capitalizeFirstLetter(str);
    }
  };

  const UpdatePickedUpStatus = (e) => {
    e.preventDefault();
    setLoading('d-flex');
    if (!form?.load_id) {
      return;
    }
    loader(true);
    ApiClient.put('load-status', {
      load_id: form?.load_id,
      bol_doc: Document,
      shipment_status: 'pickedup',
    }).then((res) => {
      if (res.success) {
        setLoading('d-none');

        loader(false);
        toast.success(res?.message);
        document.getElementById('CloseBOLDocumentsModal').click();
        getData();
        setLoading('d-none');
      }
      loader(false);
    });
  };

  const HandleUploadDocument = async (e, id) => {
    setLoading('d-flex');
    const token = localStorage.getItem('token');

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    let file = e.target.files[0];
    const formdata = new FormData();
    formdata.append('file', file);
    const UploadData = await fetch(
      `${environment.api}upload/image-document?modelName=users`,
      {
        method: 'post',
        body: formdata,
        headers: headers,
      }
    );
    let res = await UploadData.json();

    if (res.success) {
      files.push(e?.target?.files[0]?.name);
      setLoadDetails({ ...loadDetails, document: res?.data?.fullpath });
      const updatedFileInputs = fileInputs.map((input) =>
        input.id === id ? { ...input, file: res?.data?.fullpath } : input
      );
      Document.push(`${res?.data?.fullpath}`);

      setFileInputs(updatedFileInputs);
      setLoading('d-none');
    } else {
      toast.error(res?.error?.message);

      removeFileInput(id, null);

      Document.pop();
      setLoading('d-none');
    }
  };
  const GetSettings = () => {
    ApiClient.get('settings').then((res) => {
      if (res.success) {
        setSetting(res?.data);
      }
    });
  };
  const CheckIn = (stop_id, load_id, prevoiusStop, index, data) => {
    setLoading('d-flex');
    if (index == data?.length && Document?.length == 0) {
      // document.getElementById('OpenDocumentModal').click()

      return setLoadDetails({ stop_id, load_id, prevoiusStop, data });
    }
    // return;

    if (prevoiusStop) {
      ApiClient.put('load-status', {
        load_id,
        shipment_status: 'in_transit',
        stop_id: prevoiusStop,
        checkout: moment(),
      }).then((res) => {
        // if (res.success) {
        //   toast.success(res?.message)
        // }
      });
    }

    setLoading('d-flex');
    let payload = {
      load_id,
      shipment_status: index == data?.length ? 'delivered' : 'in_transit',
      stop_id,

      checkin: moment(),
    };
    if (index == 0) {
      payload = { ...payload, bol_doc: Document };
    }

    ApiClient.put('load-status', payload).then((res) => {
      if (res.success) {
        toast.success(res?.message);
        getData();
        completeBid();
        rejectBid();
        setdocuments([]);
        setFileInputs([{ id: 1, file: null }]);

        setLoading('d-none');
      }
      setLoading('d-none');
    });
    setTimeout(() => { }, 1000);
  };

  const HandleNoteSubmit = (e) => {
    e.preventDefault();
    setLoading('d-flex');
    ApiClient.post('carrier-note', {
      title: Notes?.title,
      description: Notes?.description,
      load_id: Notes?.load_id,
      stop_id: Notes?.stop_id,
      new_eta: moment(Notes?.new_eta).format('YYYY-MM-DDTHH:mm'),
    }).then((res) => {
      if (res.success) {
        toast.success(res?.message);
        document.getElementById('CloseNotesModal').click();
        setLoading('none');
      }
    });
  };

  const UpdateStopWithDocuments = () => {
    setLoading('d-flex');
    // e.preventDefault()
    if (
      !loadDetails?.load_id ||
      !loadDetails?.stop_id ||
      Document?.length == 0
    ) {
      return false;
    }
    if (loadDetails?.prevoiusStop) {
      ApiClient.put('load-status', {
        load_id: loadDetails?.load_id,
        shipment_status: 'in_transit',
        stop_id: loadDetails?.prevoiusStop,
        checkout: moment(),
      }).then((res) => { });
    }
    let payload = {
      load_id: loadDetails?.load_id,
      shipment_status: 'delivered',
      stop_id: loadDetails?.stop_id,
      checkin: moment(),
      pod_doc: Document,
    };
    if (loadDetails?.prevoiusStop) {
      setTimeout(() => {
        ApiClient.put('load-status', payload).then((res) => {
          if (res.success) {
            toast.success(res?.message);
            setLoading('d-none');

            document.getElementById('CloseDocumentModal').click();
            getData();
            completeBid();
            setdocuments([]);
            setFileInputs([{ id: 1, file: null }]);
            setLoadDetails({ document: '' });
            setFIles(['']);
            rejectBid();
          }
          setLoading('d-none');
        });
      }, 1000);
    } else {
      ApiClient.put('load-status', payload).then((res) => {
        if (res.success) {
          setLoading('d-none');

          toast.success(res?.message);
          document.getElementById('CloseDocumentModal').click();
          getData();
          setdocuments([]);
          setFileInputs([{ id: 1, file: null }]);
          setLoadDetails({ document: '' });
          setFIles([]);
          completeBid();
          rejectBid();
        }
        setLoading('d-none');
      });
    }
  };

  useEffect(() => {
    GetSettings();
  }, []);

  const ConvertMarginType = (data) => {
    switch (data?.margin_type) {
      case 'percentage':
        return `${data?.margin_value} %`;
        break;
      case 'amount':
        return `$ ${data?.margin_value}`;
      default:
        return `${data?.margin_value} ${methodModel.capitalizeFirstLetter(
          data?.margin_type
        )}`;
        break;
    }
  };
  const Handlefilter = (e) => {
    const value = e.target.value;
    setFilter({ ...filters, [e.target.name]: value });
    getData({ [e.target.name]: value });
  };

  const [TimerClass, setTimerClass] = useState(true);

  const calculateTimeRemaining = (expirationDate) => {
    const now = new Date();
    const expiration = new Date(expirationDate);
    const difference = expiration - now;

    if (difference <= 0) {
      return { expired: true };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  return (
    <Layout2 title='Loads' title2={TAB}>
      <div className="mainareas">
        <div className="d-flex justify-content-between align-items-center mb-4 wrapflex flex-wrap">
          <div className='bids-top'>
            <h4>
              Results<span> {total}</span>
            </h4>
          </div>
          {/* <h3 className="hedding">{CheckParamsPath()?.key}</h3> */}
          <div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex jobs_child-flex position-relative loadCross ">
                  <div className=" searchParent">
                    <input
                      className="form-control "
                      placeholder="Search By Load Id ,City ,State"
                      type="search"
                      value={filters.search}
                      name="search"
                      onChange={(e) => Handlefilter(e)}
                    />
                    <span class="material-icons">search</span>
                  </div>

                </div>
              </div>
            </div>
       
          </div>
        </div>

        {
          <>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                {!loaging &&
                  alldata.map((itm, i) => {
                    return (
                      <div className="bids-new">
                        <div className="bids-data mb-4">
                          <div className="bids-upp-m">
                            <div className="loc-flex">
                              <img src="/assets/img/origin.svg" />
                              <h5 className="text-capitalize">
                                {itm?.origin_location_city || '--'}
                                <span>
                                  {itm?.origin_location_state},{' '}
                                  {itm.origin_location_country}
                                </span>
                                <span className='mt-1'>
                                  Origin Address

                                </span>
                              </h5>
                            </div>
                            <p>
                              <TbArrowsExchange />
                            </p>
                            <div className="loc-flex">
                              <img src="/assets/img/destination.svg" />
                              <h5>
                                {itm?.destination_location_city || '--'}
                                <span>   {itm.destination_location_state},{' '}{itm.destination_location_country}</span>
                                <span className='mt-1'>
                                  Destination Address

                                </span>
                              </h5>
                            </div>
                          </div>
                          <div className="bids-upper">
                            <div className="bids-middle">
                              <h5>
                                <span>Load Id/Lane Id :</span>
                                <span className="pointer" onClick={() => {
                                  if (
                                    !localStorage.getItem("token")
                                  ) {
                                    history.push(`/login`);
                                  } else {
                                    history.push(
                                      `/jobs/view/${itm?.id}`
                                    );
                                  }
                                }}>{itm.load_id || itm.lane_id || '--'}</span>
                              </h5>
                              <h5 className=''>
                                <span>Time Left :</span>

                                <CountdownTimer2

                                  TimerClass={TimerClass}
                                  expirationDate={itm?.expiration_date}
                                />

                              </h5>
                              {itm?.shipment_status == "pending" ? "" : <h5>
                                <span>Pickup Date :</span>
                                {moment(
                                  itm?.pickedup_at
                                ).format(
                                  'DD-MMM-YYYY, h:mm A'
                                )}

                              </h5>}

                              <h5>
                                <span>Expiration Date :</span>{' '}
                                {moment(
                                  itm?.expiration_date
                                ).format(
                                  'DD-MMM-YYYY, h:mm A'
                                )}
                              
                              </h5>
                              {itm?.shipment_status == "pending" ? "" : <h5>
                                <span>Delivery Status :</span>{' '}
                                <p className={`${itm?.shipment_status}_badgeNew mb-0 badge`}>
                                  {itm?.shipment_status=="in_transit"?"In Transit":itm?.shipment_status}
                                </p>
                              </h5>}

                            </div>
                            <div className="bids-bottom">
                              <h3>
                                Stops <span>{itm?.stops?.length}</span>
                              </h3>
                              <ul>
                                {itm?.stops?.length != 0
                                  ? itm?.stops?.map((item, index) => {
                                    return (
                                      <Tooltip title={item?.address}>
                                        <>{index < 3 ? <li>
                                          <div className='stop-width'>
                                            <IoArrowBackCircleSharp />{' '}
                                          </div>
                                          <span className=' mb-1 destspan'> {item?.address}</span>

                                        </li> : <></>}</>
                                      </Tooltip>

                                    );
                                  })
                                  : ''}
                                {itm?.stops?.length > 3 ?

                                  <Tooltip
                                    title={
                                      <div className=''>
                                        <ul className=''>
                                          {itm?.stops?.map((item) => (
                                            <li>
                                              {item?.address}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    }
                                  >
                                    <div className="all-stops-list">
                                      <span className="">All Stops</span>
                                    </div>
                                  </Tooltip>


                                  : <></>}
                              </ul>
                            </div>
                            <div className="bids-right text-end">
                              <div className="bid-p">
                                Distance: {itm?.total_distance || '--'}
                              </div>

                              {/* <div className='d-flex justify-content-end mt-2'>
                                <button className='btn dark-btn btn-width'>Apply Bid</button>
                              </div> */}
                              <div className="view-detail">
                                <Link to={`/loadDetail/${itm?.id}`}>
                                  View Details
                                  <IoIosArrowRoundForward />
                                </Link>
                              </div>

                              {itm?.status == 'pending' ? (
                                <>
                                  <div className="view-detail">
                                    <span
                                      className="pointer text-primary"
                                      onClick={(e) => {
                                        document
                                          .getElementById('OpenBidEditModel')
                                          .click();
                                        setform({
                                          ...form,
                                          id: itm?.id,
                                          agreed_rate: itm?.agreed_rate || '',
                                        });
                                      }}
                                    >
                                      Update Bid Price
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {!loaging && total > 0 ? (
                  <div className="paginationWrapper main-pagination page-new">
                    <div className="d-flex align-items-center">
                      <div className="me-2 user-name-color">Show</div>
                      {total > 0 && (
                        <div className="dropdown addDropdown chnagesname ">
                          <select
                            value={filters?.count}
                            onChange={(e) => {
                              setFilter({ ...filters, count: e.target.value });
                              getData({ count: e.target.value });
                            }}
                            className="form-control"
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="30">30</option>
                          </select>
                          <div
                            className="dropdown-menu shadow bg_hover"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              class="dropdown-item"
                              onClick={() => {
                                ChangeFilter({ ...filters, count: 5 });
                              }}
                            >
                              5
                            </a>
                            <a
                              class="dropdown-item"
                              onClick={() => {
                                ChangeFilter({ ...filters, count: 10 });
                              }}
                            >
                              10
                            </a>
                            <a
                              class="dropdown-item"
                              onClick={() => {
                                ChangeFilter({ ...filters, count: 20 });
                              }}
                            >
                              20
                            </a>
                            <a
                              class="dropdown-item"
                              onClick={() => {
                                ChangeFilter({ ...filters, count: 30 });
                              }}
                            >
                              30
                            </a>
                            <a
                              class="dropdown-item"
                              onClick={() => {
                                ChangeFilter({ ...filters, count: 40 });
                              }}
                            >
                              40
                            </a>
                          </div>
                        </div>
                      )}{' '}
                      <div className="ms-2 user-name-color">from {total} Loads</div>
                    </div>

                    <div className="">
                      <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}


              </div>
            </div>
          </>
        }

        {(!loaging && total == 0) ? (
          <div className=" no-data">
            <img src="/assets/img/no-data.png" />
            No Data
          </div>
        ) : (
          <></>
        )}

        {loaging ? (
          <div className="text-center py-4">
            <img src="/assets/img/loader.gif" className="pageLoader" />
          </div>
        ) : (
          <></>
        )}
      </div>

      <button
        type="button "
        id="OpenBidEditModel"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Update Bid Price
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form onSubmit={HandleSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="">
                      Agreed Rate
                      <label className="text-danger">*</label>
                    </label>
                    <input
                      value={form?.agreed_rate}
                      required
                      min={0}
                      multiple
                      type="number"
                      onChange={(e) => {
                        setform({ ...form, agreed_rate: e.target.value });
                      }}
                      className="form-control mt-2"
                      placeholder="Enter Bid Rate"
                      name=""
                      id=""
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                id="CloseBidUpdateModel"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="DocumentModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog ">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Upload POD (Proof of Delivery)
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                onClick={() => {
                  setdocuments([]);
                  setFileInputs([{ id: 1, file: null }]);
                  setLoadDetails({ document: '' });
                  setFIles([]);
                }}
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={UpdateStopWithDocuments}>
              <div class="modal-body documentation_modal position-relative">
                <div className="row">
                  {fileInputs.map((input, index) => (
                    <div key={input.id} className="col-md-12 mb-1">
                      <ImageUpload
                        result={(e) => {
                          files.push(e?.value);
                          setLoadDetails({
                            ...loadDetails,
                            document: e?.value,
                          });
                          const updatedFileInputs = fileInputs.map((input) =>
                            input.id === e?.id
                              ? { ...input, file: e?.value }
                              : input
                          );
                          Document.push(`${e?.value}`);

                          setFileInputs(updatedFileInputs);
                          setLoading('d-none');
                        }}
                      />

                      {/* {fileInputs[index]?.file == null ? <button className="btn btn-primary " onClick={() => {
                        document.getElementById(`FileBOLInputID${index}`).click()
                      }}>Upload File</button> : null} */}
                      <div className="iframe_div mt-2 text-center ">
                        {fileInputs?.length > 0 &&
                          fileInputs[index]?.file != null && (
                            <>
                              {fileInputs[index]?.file ? (
                                <div className="pdffdiv">
                                  <img
                                    src="/assets/img/pdf.png"
                                    className="pdfimg"
                                  />{' '}
                                  <p className="m-0">Uploaded</p>{' '}
                                </div>
                              ) : (
                                <div className="pdffdiv">
                                  <img
                                    src="/assets/img/pdf.png"
                                    className="pdfimg"
                                  />{' '}
                                  <p className="m-0">Uploaded</p>{' '}
                                </div>
                              )}
                            </>
                          )}
                      </div>

                      {/* {fileInputs[index]?.file == null ? <button className="btn btn-primary" onClick={() => {
                        document.getElementById(`FileInputID${index}`).click()
                      }}>Upload File</button> : null}



                      
                      <div className="iframe_div mt-2">

                        {
                          fileInputs?.length != 0 && loadDetails?.document?.includes('pdf') && <>{fileInputs[index]?.file ? <iframe src={`${environment.api}documents/${fileInputs[index].file}`} width='200' height='200' /> : null}</>
                        }
                      </div> */}

                      {/* <div class={`uploadload  ${Loading}`}></div> */}

                      <div className="button_row">
                        {fileInputs?.length > 1 || fileInputs[index]?.file ? (
                          <span
                            onClick={() =>
                              removeFileInput(
                                input.id,
                                fileInputs?.length != 0
                                  ? fileInputs[index].file
                                  : ''
                              )
                            }
                            class="material-icons pointer delete_icon"
                          >
                            delete
                          </span>
                        ) : null}
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-md-12 text-end mb-3">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={addFileInput}
                      >
                        Add More
                      </button>
                    </div>
                  </div>
                </div>
                <div class={`uploadload  ${Loading}`}></div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="CloseDocumentModal"
                  onClick={() => {
                    setdocuments([]);
                    setFileInputs([{ id: 1, file: null }]);
                    setLoadDetails({ document: '' });
                    setFIles([]);
                  }}
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => {
                    if (Document?.length != 0) {
                      UpdateStopWithDocuments();
                    } else {
                      alert('Please upload document');
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="BOLDocumentModel"
        tabindex="-1"
        aria-labelledby="exampleBOLDocumentModel"
        aria-hidden="true"
      >
        <div class="modal-dialog ">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleBOLDocumentModel">
                Upload BOL
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                onClick={() => {
                  setdocuments([]);
                  setFileInputs([{ id: 1, file: null }]);
                  setLoadDetails({ document: '' });
                  setFIles([]);
                }}
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={UpdatePickedUpStatus}>
              <div class="modal-body documentation_modal position-relative">
                <div className="row">
                  {fileInputs.map((input, index) => (
                    <div key={input.id} className="col-md-12 mb-1">
                      <ImageUpload
                        result={(e) => {
                          files.push(e?.value);
                          setLoadDetails({
                            ...loadDetails,
                            document: e?.value,
                          });
                          const updatedFileInputs = fileInputs.map((input) =>
                            input.id === e?.id
                              ? { ...input, file: e?.value }
                              : input
                          );
                          Document.push(`${e?.value}`);

                          setFileInputs(updatedFileInputs);
                          setLoading('d-none');
                        }}
                      />
                      {/* {fileInputs[index]?.file == null ? <button className="btn btn-primary " onClick={() => {
                        document.getElementById(`FileBOLInputID${index}`).click()
                      }}>Upload File</button> : null} */}
                      <div className="iframe_div mt-2 text-center ">
                        {fileInputs?.length > 0 &&
                          fileInputs[index]?.file != null && (
                            <>
                              {fileInputs[index]?.file ? (
                                <div className="pdffdiv">
                                  <img
                                    src="/assets/img/pdf.png"
                                    className="pdfimg"
                                  />{' '}
                                  <p className="m-0">Uploaded</p>{' '}
                                </div>
                              ) : (
                                <div className="pdffdiv">
                                  <img
                                    src="/assets/img/pdf.png"
                                    className="pdfimg"
                                  />{' '}
                                  <p className="m-0">Uploaded</p>{' '}
                                </div>
                              )}
                            </>
                          )}
                        {/* {
                          fileInputs?.length != 0 && loadDetails?.document?.includes('png' || 'jpeg' || 'jpg') && <>{fileInputs[index]?.file ?
                            <div className="pdffdiv">
                              <img src="/assets/img/pdf.png" className="pdfimg" /> <p className="m-0">Uploaded</p> </div> : <div className="pdffdiv">
                              <img src="/assets/img/pdf.png" className="pdfimg" /> <p className="m-0">Uploaded</p> </div>}
                          </>

                        } */}
                      </div>

                      <div className="button_row">
                        {fileInputs?.length > 1 || fileInputs[index]?.file ? (
                          <span
                            onClick={() =>
                              removeFileInput(
                                input.id,
                                fileInputs?.length != 0
                                  ? fileInputs[index].file
                                  : ''
                              )
                            }
                            class="material-icons pointer delete_icon"
                          >
                            delete
                          </span>
                        ) : null}
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-md-12 text-end mb-3">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={addFileInput}
                      >
                        Add More
                      </button>
                    </div>
                  </div>
                </div>
                <div class={`uploadload ${Loading}`}></div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="CloseBOLDocumentsModal"
                  onClick={() => {
                    setdocuments([]);
                    setFileInputs([{ id: 1, file: null }]);
                    setLoadDetails({ document: '' });
                    setFIles([]);
                  }}
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <button
        type="button"
        id="OpenBOLDocumentsModal"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#BOLDocumentModel"
      >
        Launch demo modal
      </button>
      <button
        type="button"
        id="OpenNotesModal"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#NoteModal"
      >
        Launch demo modal
      </button>
      <div
        class="modal fade"
        id="NoteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Shipment Notes
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                HandleNoteSubmit(e);
              }}
            >
              <div class="modal-body documentation_modal position-relative">
                <div className="col-md-6 mb-3">
                  <label>
                    Title <label className="text-danger">*</label>
                  </label>
                  <input
                    placeholder="Enter Title"
                    value={Notes?.title}
                    type="text"
                    required
                    onChange={(e) => {
                      SetNotes({ ...Notes, title: e.target.value });
                    }}
                    className="form-control mb-2"
                  />
                </div>
                <div className="col-md-6 mb-3 mt-3 ">
                  <label htmlFor="">
                    Description <label className="text-danger">*</label>
                  </label>
                  <textarea
                    value={Notes?.description}
                    cols={10}
                    type="text"
                    placeholder="Enter Description"
                    required
                    onChange={(e) => {
                      SetNotes({ ...Notes, description: e.target.value });
                    }}
                    className="form-control mb-2"
                  />
                </div>
                <div className="col-md-6 mb-3 mt-3 pickdate ">
                  <span className="d-block">
                    {' '}
                    <label htmlFor="" className="d-flex">
                      New ETA <label className="text-danger d-block">*</label>
                    </label>
                  </span>
                  <DatePicker
                    selected={Notes?.new_eta}
                    className="form-control w-100"
                    minDate={Date.now()}
                    onChange={(e) => {
                      SetNotes({ ...Notes, new_eta: e });

                    }}
                    showTimeSelect
                    dateFormat="dd-MM-yyyy h:m a"
                  />
                  {/* <input id="new_eta_datePicker" type="datetime-local" className="form-control" min={Date.now()} onChange={(e) => {

                    SetNotes({ ...Notes, new_eta: e.target.value })
                  }} /> */}
                  {/* <select name="" className="form-control" value={Notes?.new_eta} onChange={(e) => {
                    SetNotes({ ...Notes, new_eta: e.target.value })
                  }} id="">
                    <option value="">Select ETA</option>

                    <option value="30 minutes">30 Minutes</option>

                    <option value="1 hours">1 Hour</option>

                    <option value="2 hours">2 Hours</option>
                    <option value="4 hours">4 Hours</option>

                    <option value="5 hours">5 Hours</option>
                    <option value="6 hours">6 Hours</option>
                    <option value="7 hours">7 Hours</option>
                    <option value="8 hours">8 Hours</option>

                  </select> */}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="CloseNotesModal"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default Html;
