import React from "react";
import methodModel from "../../../methods/methods";

const Html = ({
  idd,
  inputElement,
  uploadImage,
  img,
  type,
  remove,
  loader1,
  model,
  multiple,
  required,
  style,
  err,
}) => {


  const imgSrc=(img)=>{
    let v='/assets/img/pdf.png'
    if(type=='img')v= methodModel.noImage(img)

      return v
  }

  return (
    <>
      <label className={` ${img && !multiple ? "d-none" : ""}`}>
      <i class="fas fa-upload"></i>
        <input
          id={idd?idd:"Image"}
          type="file"
          className="d-none"
          ref={inputElement}
          accept="image/*"
          multiple={multiple ? true : false}
          disabled={loader1}
          onChange={(e) => {
            uploadImage(e);
          }}
        />
        Upload Document
      </label>
      <span className='text-sm text-danger text-end w-100 d-block notes-r'>(You can upload multiple documents)</span>
      {loader1 ? (
        <div className="text-success">
          Uploading... <i className="fa fa-spinner fa-spin"></i>
        </div>
      ) : (
        <></>
      )}

      {multiple ? (
        <>
        
          <div className="imagesRow">
            {img &&
              img?.map((itm, i) => {
                return (
                  <div className={`imagethumbWrapper product-images licenceimgTwoProd ${style}`}>
                    <img src={imgSrc(itm)} className="thumbnail licenceimgTwo" />
                    <i className="fa fa-times lis_times" title="Remove" onClick={e => remove(i)}></i>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <>
          {img ? (
            <div className="imagethumbWrapper product-images  licenceimgTwoProd">
              <img src={imgSrc(img)} className="thumbnail licenceimgTwo" />
              <i className="fa fa-times lis_times" title="Remove" onClick={e => remove()}></i>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      {required && !img ? (
        <div className="text-danger">{err ? err : "Image is Required"}</div>
      ) : (
        <></>
      )}
    </>
  );
};
export default Html;
