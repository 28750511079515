import React from "react";
import ReactDOM from "react-dom";
import Main from "./main";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./config";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";

export const history = createBrowserHistory();
const { persistor, store } = configureStore(history);
const loadGoogleMapsAPI = () => {
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAID0kSQQmScLcv5PornXUOEG0LPn8hfKQ&libraries=places&callback=initMap`;
  script.defer = true;
  document.head.appendChild(script);
};

// loadGoogleMapsAPI();
ReactDOM.render(
  <Provider store={store}>
    <Main />
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
